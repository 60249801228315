import rp from 'request-promise';
import {User} from "../vendor/obj/User";
const DEV = {
    baseUrl: "http://localhost:3000/"
};

const PROD = {
    baseUrl: "https://sso.kjdelectronics.com/"
};

const ROUTES = {
    validate: 'validate'
};


const config = process.env.NODE_ENV.toLowerCase() === 'production' ? PROD : DEV;

class SsoClient {
    constructor ({sessionKey, apiKey}) {
        if(apiKey == null && sessionKey == null)
            throw new Error('Cannot create product service without API Key or sessionKey provided');
        if(apiKey && sessionKey)
            throw new Error('Cannot provide session and api keys');

        if(apiKey)
            this.authHeader = {"x-api-key": apiKey};
        else
            this.authHeader = {"kjd_session": sessionKey};
    }

    getUser(){
        let options = {
            method: 'GET',
            uri: config.baseUrl + ROUTES.validate,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(user => {
                return new User(user);
            })
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }
}

export {SsoClient, config};
