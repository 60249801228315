import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import { StandaloneShipment } from '../../obj/StandaloneShipment';
import { PurchaseOrder } from '../../obj/PurchaseOrder';


class StandaloneShipments{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    getStandaloneShipments({limit=10000, dateSort=1, filter="all"}={}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.standaloneShipments.getAll.replace(":limit", limit).replace(":dateSort", dateSort).replace(':filter', filter),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                return results.map(standaloneShipment => new StandaloneShipment(standaloneShipment));
            })
    }

    checkinShipments({poRef, shipmentIds}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + routes.standaloneShipments.checkin,
            headers: this.headers,
            body: {poRef: poRef, shipmentIds: shipmentIds},
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }
}


export { StandaloneShipments };
