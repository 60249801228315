function doesTrackingExist({client, tracking}) {
    return client.trackings.getTracking(tracking)
        .then(tracking => {return !!tracking})
        .catch(err => {
            if(err.statusCode === 404)
                return false;
            return Promise.reject(err);
        });

}

module.exports.doesTrackingExist= doesTrackingExist;