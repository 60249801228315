class ShipmentCheckin{
    constructor({checkInDate=null, checkInUserId=null}={}){
        this.checkInDate = checkInDate ?  new Date(checkInDate) : null;
        this.checkInUserId = checkInUserId;
    }

    get isCheckedIn(){
        return this.checkInDate !== null;
    }

    toJSON(){
        let res = Object.assign({}, this);
        res.isCheckedIn = this.isCheckedIn;
        return res;
    }
}

export { ShipmentCheckin };