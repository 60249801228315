import React, {Component} from 'react';
import { Alert } from 'reactstrap';

class MvpSearchAlert extends Component {
        render() {
            return <Alert color="warning">
               "All POs" Limited to 300 most recent POs for performance reasons. Future update will make more POs available
            </Alert>
    }
}

export {MvpSearchAlert};
