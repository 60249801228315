import React from 'react';
import PropTypes from 'prop-types';
import {PurchaseOrdersTable} from '../../molecules/tables/PurchaseOrdersTable';
import { Row, Col } from 'reactstrap';
import {MvpSearchAlert} from "../../atoms/MvpSearchAlert";
import {Redirect} from "react-router-dom";


class SearchContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            purchaseOrders: [],
        };

    }

    componentDidMount() {
        this._searchPos();
    }

    componentDidUpdate(prevProps){
        if(prevProps.query !== this.props.query)
            this._searchPos();
    }

    _searchPos(){
        const {query} = this.props;
        if(query)
            this._search(query);
        else
            this._getAllPos();
    }

    _search(query){
        this.props.client.purchaseOrders.searchPurchaseOrders(query)
            .then(pos => {
                this.setState({
                    isLoaded: true,
                    purchaseOrders: pos
                });
            }).catch(error => {
            this.setState({
                isLoaded: true,
                error: error
            });
        });
    }

    _getAllPos(){
        //TODO limiting to 300 for performance at this time. Post MVP
        this.props.client.purchaseOrders.getPurchaseOrders({dateSort: -1, limit: 200})
            .then(pos => {
                this.setState({
                    isLoaded: true,
                    purchaseOrders: pos
                });
            }).catch(error => {
            this.setState({
                isLoaded: true,
                error: error
            });
        });
    }

    _mvpSearchAlert(){
        if(!this.props.query)
            return <MvpSearchAlert/>
    }

    render() {

        const { error, isLoaded, purchaseOrders } = this.state;
        const {receivingContext} = this.props;
        if(purchaseOrders.length === 1) {
            const link = receivingContext ? `/receiving/edit/${purchaseOrders[0].ref}` : `/po/view/${purchaseOrders[0].ref}`; //TODO Refactor domain logic (Duplicated)
            return <Redirect to={link}/>;
        }

        return (
            <Row>
                <Col>
                    {this._mvpSearchAlert()}
                    <PurchaseOrdersTable receivingContext={receivingContext} purchaseOrders={purchaseOrders} isLoaded={isLoaded} posError={error} pagination={true}/>
                </Col>
            </Row>
            );
    }
}

SearchContent.propTypes = {
    query: PropTypes.string,
    receivingContext: PropTypes.bool.isRequired
};

export {SearchContent};