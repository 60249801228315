import {config} from  './config/config';
import routes from './config/routes';
import rp from 'request-promise';

class LiteTransactionsClient {
    constructor ({sessionKey}) {
        this.authHeader = {"kjd_session": sessionKey};
    }

    getTransactionById(id){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.transaction + `/${id}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }

    markApprovedById(id){
        let options = {
            method: 'PUT',
            uri: config.baseUrl + routes.transaction + `/${id}`,
            headers: this.authHeader,
            body: {
                action: "approval",
                approved: true
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }

    markDeclineddById(id){
        let options = {
            method: 'PUT',
            uri: config.baseUrl + routes.transaction + `/${id}`,
            headers: this.authHeader,
            body: {
                action: "approval",
                approved: false
            },
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }
}

export default LiteTransactionsClient;