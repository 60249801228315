//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardTitle } from 'reactstrap';
import {StandaloneShipmentsContent} from "../functional/StandaloneShipmentsContent";
import {PoTableContent} from "../functional/PoTableContent";
import {ReceivingStatsCard} from "../../molecules/ReceivingStatsCard";

class ReceivingDahsboardContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inboundReport: null,
            isLoaded: false,
            error: null
        }
    }

    componentDidMount() {
        this._load();
    }

    _load(){
        this.props.client.reports.getInboundShipmentData()
            .then(data => {
                this.setState({
                    isLoaded: true,
                    inboundReport: data
                });
            }).catch(error => {
                console.log(error);
                this.setState({
                    isLoaded: true,
                    error: error
                });
        });
    }

    render() {
        const {isLoaded, error, inboundReport} = this.state;

        let props = {};
        if(inboundReport)
            props = {...inboundReport.totals};

        return (
            <div>
                <Row className="justify-content-md-center">
                    <Col md={10}>
                        <ReceivingStatsCard isLoaded={isLoaded} error={error} {...props}/>
                    </Col>
                </Row>
                <Row className="pt-2 pb-4">
                    <Col lg={6}>
                        <h4 className={"text-center"}>Inbound POs</h4>
                        <PoTableContent receivingContext={true} client={this.props.client} pagination={true} filterText="inbound"/>
                    </Col>
                    <Col>
                        <h4 className={"text-center"}>Inbound Shipments</h4>
                        <StandaloneShipmentsContent receivingContext={true} pagination={true} client={this.props.client} filterText="inbound"/>
                    </Col>
                </Row>
            </div>
            );
    }
}

ReceivingDahsboardContent.propTypes = {
  client: PropTypes.object.isRequired
};

export {ReceivingDahsboardContent};