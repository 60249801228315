import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import Vendor from '../../obj/Vendor';

class Vendors{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    getVendors(){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.vendors.get,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                return results.map(vendor => new Vendor(vendor));
            })
    }
}


export { Vendors };