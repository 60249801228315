import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {DashboardContent} from '../organisms/view/DashboardContent'
import {FilterDropdown} from '../molecules/FilterDropdown'
import SettingsContext from "../../context/SettingsContext";

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false
        }
    }


    dropdownItemClicked({text, updateUserSettings}){
        updateUserSettings({dashboardFilter: text});
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        const {client} = this.props;
        const {dropdownOpen} = this.state;
        return (
            <SettingsContext.Consumer>
                {({dashboardFilter, updateUserSettings}) => (
                    <div className="ml-3 mr-3">
                        <h1>Purchasing Dashboard</h1>
                        <ToolBar client={client}
                                 extraComponent={<FilterDropdown
                                        toggle={() => this.toggle()}
                                        itemClicked={(text) => this.dropdownItemClicked({text, updateUserSettings})}
                                        dropdownOpen={dropdownOpen}
                                        filterText={dashboardFilter}
                                 />

                                 }
                        />
                        <DashboardContent client={client} filterText={dashboardFilter}/>
                    </div>
                )}
            </SettingsContext.Consumer>);
    }
}

Dashboard.propTypes = {
  client: PropTypes.object.isRequired
};

export {Dashboard};