//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {ReceivingCard} from "../../atoms/ReceivingCard";
import {ReceivingShipmentsTable} from "../../molecules/tables/ReceivingShipmentsTable";
import {Alert} from "reactstrap";

class EditReceivingComments extends React.Component {
    render() {
        let {comments} = this.props;
        comments = comments.filter(c => c.showOnReceiving)

        if(comments.length > 0)
            setTimeout(this.playAlertSound, 500);

        return (
            <div>
                {
                    comments.map(comment => {
                        return <Alert color="warning">
                            <b>Receiving Comment</b>: {comment.comment}
                        </Alert>
                    })
                }
            </div>
            );
    }

    playAlertSound(){
        let audio = new Audio('https://soundbible.com/grab.php?id=2158&type=mp3');
        audio.type = 'audio/mp3';
        return audio.play(); //Promise
    }
}

EditReceivingComments.propTypes = {
    comments: PropTypes.array.isRequired
};

export {EditReceivingComments};