import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {poRefFormatter, genericFormatter, dateFormatter, checkinFormatter} from "../../../helpers/dataFormatters";
import {ErrorAlert} from "../../atoms/ErrorAlert";


class StandaloneShipmentsTable extends React.Component {
    render() {
        const { error, isLoaded, shipments, receivingContext } = this.props;
        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;

        let additionalProps = {};
        if(this.props.pagination && shipments.length > 10)
            additionalProps.pagination = true;

        return (
            <BootstrapTable data={shipments} striped hover version='4' {...additionalProps}>
                <TableHeaderColumn dataField='poRef' isKey={true} dataFormat={cell => poRefFormatter(cell, receivingContext)} width={"8%"}>PO</TableHeaderColumn>
                <TableHeaderColumn dataField='id'  width={"25%"} dataFormat={(cell, obj) => genericFormatter(obj, "shipment.trackingNumber")}>Tracking</TableHeaderColumn>
                <TableHeaderColumn dataField='' dataFormat={(cell, obj) => genericFormatter(obj, "shipment.tracking.location")}>Location</TableHeaderColumn>
                <TableHeaderColumn dataField='' width={"20%"} dataFormat={(cell, obj) => genericFormatter(obj, "shipment.tracking.status")}>Status</TableHeaderColumn>
                <TableHeaderColumn dataField='shippedDate'
                                  dataFormat={(cell, obj) => genericFormatter(obj, "shipment.tracking.shippedDate", dateFormatter)}>Shipped</TableHeaderColumn>
                <TableHeaderColumn dataField='' thStyle={ { whiteSpace: 'normal', fontSize: '75%' } }
                                   dataFormat={(cell, obj) => genericFormatter(obj, "shipment.tracking.estimatedDelivery", dateFormatter)}>Est Delivery</TableHeaderColumn>
                <TableHeaderColumn dataField='checkIn' thStyle={ { whiteSpace: 'normal', fontSize: '75%' }}
                                   dataFormat={(cell, obj) => checkinFormatter(obj)}>Checked In</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

StandaloneShipmentsTable.propTypes = {
    shipments: PropTypes.array.isRequired,
    error: PropTypes.object,
    isLoaded: PropTypes.bool.isRequired,
    receivingContext: PropTypes.bool,
    pagination: PropTypes.bool
};

export {StandaloneShipmentsTable};