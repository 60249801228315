//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {PurchaseOrdersTable} from '../../molecules/tables/PurchaseOrdersTable';

class PoTableContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            posError: null,
            posIsLoaded: false,
            purchaseOrders: []
        };

    }

    componentDidMount() {
        this._loadPos();
    }

    componentDidUpdate(prevProps){
        if(prevProps.filterText !== this.props.filterText)
            this._loadPos();
    }

    _loadPos(){
        this.props.client.purchaseOrders.getPurchaseOrders({limit: 30, dateSort: -1, filter: this.props.filterText})
            .then(pos => {
                this.setState({
                    posIsLoaded: true,
                    purchaseOrders: pos
                });
            }).catch(error => {
            console.log(error);
            this.setState({
                isLoaded: true,
                posError: error
            });
        });
    }

    render() {
        const { posError, posIsLoaded, purchaseOrders } = this.state;
        const {receivingContext} = this.props;
        return (
            <PurchaseOrdersTable pagination={this.props.pagination} receivingContext={receivingContext} purchaseOrders={purchaseOrders} isLoaded={posIsLoaded} error={posError}/>
        );
    }
}

PoTableContent.propTypes = {
    client: PropTypes.object.isRequired,
    filterText: PropTypes.string,
    receivingContext: PropTypes.bool,
    pagination: PropTypes.bool
};

export {PoTableContent};