export default class Vendor{
    constructor({name, id, _id, isManualCountRequired=false}){
        this.id = id || (_id ? _id.toString() : null);
        this.name = name;
        this.isManualCountRequired = isManualCountRequired;
    }

    getForDb(){
        let {["id"]:omit, ...res} = this;
        return res;
    }
};