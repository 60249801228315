import React, {Component} from 'react';
import PropTypes from "prop-types";
import {checkinFormatter} from "../../helpers/dataFormatters";
import {CustomInput} from "reactstrap";
import {VisualConfirmationEditControl} from "../molecules/VisualConfirmationEditControl";
import {ManualCountEditControl} from "../molecules/ManualCountEditControl";
import ReceivingContext from "../../context/ReceivingContext";

class ReceivingItemRow extends Component {
    state = {
        product: null,
        error: null
    };

    _renderReceivingTd({receivingActions}){
        const {receivingItem, poItem} = this.props;

        const receivingAction = receivingActions.find(action => action.productId === poItem.product.id && action.shipmentId === receivingItem.shipmentId);

        if(receivingItem.manualCount || (receivingAction && receivingAction.manualCount))
            return <ManualCountEditControl productId={poItem.product.id} receivingItem={receivingItem}/>;
        return <VisualConfirmationEditControl productId={poItem.product.id} receivingItem={receivingItem}/>;
    }

    getProduct(productsClient){
        productsClient.getProductById(this.props.poItem.product.id)
            .then(product => this.setState({product}))
            .catch(error => this.setState({error}));
    }

    render() {
        const {receivingItem, poItem} = this.props;
        const {error, product} = this.state;
       return <ReceivingContext.Consumer>
        {({receivingActions, productsClient}) => {
            if(!product && !error)
                this.getProduct(productsClient);
               return <tr>
                    <th scope="row">K{poItem.product.id}</th>
                    <td>{product && product.key.modelNumber}</td>
                    <td>{poItem.product.name}</td>
                    <td className="text-center">{receivingItem.expectedQuantity}</td>
                    <td>{this._renderReceivingTd({receivingActions})}</td>
                </tr>}}
       </ReceivingContext.Consumer>
    }
}

ReceivingItemRow.propTypes = {
    receivingItem: PropTypes.object.isRequired,
    poItem: PropTypes.object.isRequired,
};

export {ReceivingItemRow};
