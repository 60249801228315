import uuidv4 from 'uuid/v4';
import { PoProductRef } from './ref/PoProductRef';
import { ErrorWithStatusCode } from './errors/ErrorWithStatusCode';
import { ReceivingItem } from './receiving/ReceivingItem';
import { ReceivingAction } from './receiving/actions/ReceivingAction';
import { roundGenerator } from '../domain/round';
import { getReceivingManualCountRules } from '../domain/receiving.items.logic';
import { doesPoItemTransactionNeedToBeUpdated } from '../domain/po-item.update.logic';

//Values for PO Item used in intermediate calculations are require further precision
const round = roundGenerator({defaultDecimalPlaces: 7});

class PurchaseOrderItem{
    constructor({id, inboundTransactionId=null, inboundTransactionIdHistory=[], product, quantity, unitPrice, manualCountRules=null,
                    baseReceivingItem=null, receivingItems=[], shippingCostAllocationPercentage=0},
                {poRef,}){
        if(!Array.isArray(inboundTransactionIdHistory))
            throw  new ErrorWithStatusCode({code: 400, message: `transactionIdHistory must be array`});
        if(!Number.isInteger(quantity) || quantity < 0)
            throw  new ErrorWithStatusCode({code: 400, message: `quantity must be a positive integer`});
        if(typeof unitPrice !== "number" || unitPrice < 0)
            throw  new ErrorWithStatusCode({code: 400, message: `unitPrice must be a positive number`});
        if(typeof shippingCostAllocationPercentage !== "number" || shippingCostAllocationPercentage < 0 || shippingCostAllocationPercentage > 1)
            throw  new ErrorWithStatusCode({code: 400, message: `shippingCostAllocationPercent must be a number between 0 and 1 (inclusive)`});

        //TODO need to validate provided ID is valid uuidv4.
        this.id = id || uuidv4(); //Use provided id or generate a new GUID
        this._poRef = poRef;

        this.inboundTransactionId = inboundTransactionId;
        this.inboundTransactionIdHistory = inboundTransactionIdHistory; //Voided transactions for inbound

        this.product = new PoProductRef(product);
        this._quantity = quantity;
        this.unitPrice = unitPrice;

        this.manualCountRules = manualCountRules ? manualCountRules : null;
        this.shippingCostAllocationPercentage = shippingCostAllocationPercentage;

        if(baseReceivingItem == null)
            baseReceivingItem = {expectedQuantity: this.quantity};
        this.baseReceivingItem = new ReceivingItem(baseReceivingItem, this);

        let builtReceivingItems = [];
        for (let i = 0; i < receivingItems.length; i++) {
            builtReceivingItems.push(new ReceivingItem(receivingItems[i], this));
        }
        this.receivingItems = builtReceivingItems;
    }

    //Maintaining quantity is critical and it must be protected with a getter and setter
    set quantity(value){
        let originalQty = this._quantity;
        try {
            this._quantity = value;
            this._recalculateBaseReceivingItemExpectedQuantity();
        }
        catch (e) {
            this._quantity = originalQty;
            throw(e);
        }
    }

    sanitizeFromUser(user){
        if(user.isMemberOfGroup("IM_FINANCES"))
            return this.toJSON(); //No need to do anything
        //Remove finances data
        const copy = new PurchaseOrderItem(this, {poRef: this._poRef});
        copy.unitPrice = 0;
        return copy.toJSON();
    }

    get quantity(){
        return this._quantity ? this._quantity : 0;
    }

    get costs(){
        let distributedPoCostsPerUnit, allocatedShippingCostPerUnit;
        if(!this._poRef) {
            distributedPoCostsPerUnit = 0;
            allocatedShippingCostPerUnit = 0;
        }
        else{
            const otherCostsToSubRatio = this._poRef.costs.totalExcludingShipping / this._poRef.totals.itemSubtotal;
            distributedPoCostsPerUnit = round(this._poRef.totals.unitCount !== 0 ? otherCostsToSubRatio * this.unitPrice : 0);
            allocatedShippingCostPerUnit = round(this.quantity !== 0 ? (this._poRef.costs.shipping / this.quantity) * this.shippingCostAllocationPercentage : 0);
        }

        let costs = {
            //The costs from the PO level (Other Costs (Excluding shipping)) divided into each unit
            distributedPoCostsPerUnit: distributedPoCostsPerUnit,
            //Given the shippingCostAllocationPercentage and the Shipping Cost at the PO Level the shipping cost per unit
            allocatedShippingCostPerUnit: allocatedShippingCostPerUnit,
            totalAdditionalPerUnitCost: 0
        };
        costs.totalAdditionalPerUnitCost = round(costs.distributedPoCostsPerUnit + costs.allocatedShippingCostPerUnit);
        costs.unit = round(this.unitPrice + costs.totalAdditionalPerUnitCost);
        return costs;
    }

    get totals(){
        const costs = this.costs;
        return {
            sub: round(this.unitPrice * this.quantity), //Sub total not including cots
            costs: round(costs.totalAdditionalPerUnitCost * this.quantity),
            grand: round(costs.unit * this.quantity) //Grand total for item including costs
        }
    }

    setShippingCostAllocationPercentage(value){
        if(typeof value !== "number" || isNaN(value) || value < 0 || value > 1)
            return new Error("Invalid Shipping Cost Allocation Percentage");
        this.shippingCostAllocationPercentage = value;
    }

    initManualCountRules(product){
        if(this.manualCountRules)
            throw new Error("Cannot init manualCountRules when it has already be set");

        this.manualCountRules = getReceivingManualCountRules({vendor: this._poRef.vendor, product: product});
        this._applyManualCountRules();
    }

    /**
     * This method assumes as a pre-condition that this is a brand new purchase order item. Nothing can be marked
     * as received yet as data could be lost. This is only called from initManualCountRules which enforces there
     * are no manual count rules.
     * @private
     */
    _applyManualCountRules() {
        if (!this.manualCountRules)
            throw new Error("Cannot init Receiving Items before manual count rules set");

        if (this.manualCountRules.isRequired){
            // noinspection JSUnresolvedFunction - Call this function from here is explicitly allowed
            this.baseReceivingItem._setManualCountAsRequired();
            this.receivingItems.forEach(item => item._setManualCountAsRequired());
        }
    }

    applyReceivingAction(action) {
        action = new ReceivingAction(action);
        if (action.shipmentId == null) {
            this.baseReceivingItem.applyAction(action);
            return `Receiving K${this.product.id} - ${action.toString().replace("{QUANTITY}", this.baseReceivingItem.expectedQuantity)} for the base receiving item`;
        }
        let receivingItem = this._getReceivingItemForShipmentId(action.shipmentId);
        if (!receivingItem)
            throw new ErrorWithStatusCode({code: 404, message: "Cannot apply receiving item. ShipmentId not found"});
        receivingItem.applyAction(action);
        return `Receiving K${this.product.id} - ${action.toString().replace("{QUANTITY}", receivingItem.expectedQuantity)}`;
    }

    get receivingTotals(){
        let totals = {
            allocatedExpectedQuantity: null,    //Total of all receiving items expected qty. Should equal this.quantity to be fully allocated
            unitsReceived: null,                //Total of units that have been marked as received
            additionalExpectedUnits: null,      //Of the allocatedExpectedQuantity, how many are still expected (inbound)
            openReceivingItems: null,           //Count of receiving items that are considered open
            extraUnits: null,                   //Total of extra units (discrepancies opened)
            missingUnits: null,                 //Total count of items in under-received items that are confirmed missing (discrepancies opened)
            grossUnitDelta: null,               //extraUnits - missingUnits
            expectedQuantityAllocatedToShipments: null
        };
        if(!this.baseReceivingItem)
            return totals;

        //Start the totals with data from the base item
        totals.allocatedExpectedQuantity = this.baseReceivingItem.expectedQuantity;
        totals.unitsReceived = this.baseReceivingItem.detail.unitsReceived;
        totals.additionalExpectedUnits = this.baseReceivingItem.detail.additionalExpectedUnits;
        totals.openReceivingItems = this.baseReceivingItem.isOpen ? 1 : 0;
        totals.missingUnits = this.baseReceivingItem.detail.missingUnits;
        totals.extraUnits = this.baseReceivingItem.detail.extraUnits;
        totals.expectedQuantityAllocatedToShipments = 0;

        for (let i = 0; i < this.receivingItems.length; i++) {
            const item = this.receivingItems[i];
            totals.allocatedExpectedQuantity += item.expectedQuantity;
            totals.unitsReceived += item.detail.unitsReceived;
            totals.additionalExpectedUnits += item.detail.additionalExpectedUnits;
            totals.openReceivingItems += item.isOpen ? 1 : 0;
            totals.missingUnits += item.detail.missingUnits;
            totals.extraUnits += item.detail.extraUnits;
            totals.expectedQuantityAllocatedToShipments += item.expectedQuantity;
        }

        totals.grossUnitDelta = totals.extraUnits - totals.missingUnits;
        for (const property in totals) {
            if (totals.hasOwnProperty(property)) {
                totals[property] = round(totals[property]);
            }
        }
        return totals;
    }

    get isOpen(){
        if(!this.baseReceivingItem)
            return true;
        return this.receivingTotals.openReceivingItems > 0;
    }

    get isUnsaved(){
        return this.inboundTransactionId == null && this.inboundTransactionIdHistory.length === 0;
    }

    get isValid(){
        try {
            this._recalculateBaseReceivingItemExpectedQuantity();
        }catch (e) {
            console.log(`PurchaseOrder.isValid Is false due to receiving item recalculation error: ${e.message}`);
            return false;
        }

        for (let i = 0; i < this.receivingItems.length; i++) {
            const receivingItem = this.receivingItems[i];
            if(this._poRef.getShipment({shipmentId: receivingItem.shipmentId}) == null)
                return false;
        }

        let qtyValid = Number.isInteger(this.quantity) && this.quantity >= 0;
        let unitPriceValid = typeof this.unitPrice === "number" || this.unitPrice < 0;
        let productValid = this.product.id && typeof this.product.id === "number";
        let allocationValid = this.baseReceivingItem.expectedQuantity >= 0; //Occurs when sum of expected qty in shipments is greater than PO Item Quantity

        return qtyValid && unitPriceValid && productValid && allocationValid;
    }

    addReceivingItem({shipmentId, expectedQuantity}){
        if(!this.baseReceivingItem.isOpen)
            throw new ErrorWithStatusCode({code: 400,
                message: "Items not allocated to a shipment have already been fully received. " +
                    "Therefore, items cannot be added or removed from a shipment."});

        if(this._poRef.getShipment({shipmentId: shipmentId}) == null)
            throw new ErrorWithStatusCode({code: 404,
                message: `Shipment with id ${shipmentId} was not found. It must be added to the PO First`});

        let args = {expectedQuantity: expectedQuantity, shipmentId: shipmentId};
        if(this.manualCountRules.isRequired)
            args.manualCount = {};
        else
            args.visualInspection = {};
        this.receivingItems.push(new ReceivingItem(args, this));
        this._recalculateBaseReceivingItemExpectedQuantity();
        this._applyManualCountRules(); //Make sure manual count rules applied
    }

    //Private as modifying the items directly will not automatically trigger recalculation
    _getReceivingItemForShipmentId(shipmentId){
        let item = this.receivingItems.find(item => item.shipmentId === shipmentId);
        return item;
    }

    setExpectedQuantityForShipmentId({shipmentId, expectedQuantity}){
        if(!this.baseReceivingItem.isOpen)
            throw new ErrorWithStatusCode({code: 400,
                message: "Items not allocated to a shipment have already been fully received. " +
                    "Therefore, items cannot be added or removed from a shipment."});
        let item = this._getReceivingItemForShipmentId(shipmentId);
        item.setExpectedQuantity(expectedQuantity);
        this._recalculateBaseReceivingItemExpectedQuantity();
    }

    _validateAllReceivingItemsAreNew(){
        this.baseReceivingItem._validateReceivingItemIsNew();
        for (let i = 0; i < this.receivingItems.length; i++) {
            const item = this.receivingItems[i];
            item._validateReceivingItemIsNew();
        }
    }

    setVisualInspectionForShipmentId({shipmentId, confirmedByUserId}){
        let item = this._getReceivingItemForShipmentId(shipmentId);
        item.setVisualInspection({confirmedByUserId: confirmedByUserId});
        this._recalculateBaseReceivingItemExpectedQuantity();
    }

    setManualCountForShipmentId({shipmentId, countedQuantity, countedByUserId, additionalUnitsExpectedInd}){
        let item = this._getReceivingItemForShipmentId(shipmentId);
        item.setManualCount({countedQuantity: countedQuantity, countedByUserId: countedByUserId, additionalUnitsExpectedInd: additionalUnitsExpectedInd});
        this._recalculateBaseReceivingItemExpectedQuantity();
    }

    /**
     * Returns an array of transaction ids to void. Also marks all receiving items as void by removing their
     * transaction ids and appending a history entry string.
     */
    voidAllReceivingItems({userId}){
        let voidDate = new Date();
        let transactionsToVoid = this.baseReceivingItem.voidItem({userId: userId, date: voidDate});
        for (let i = 0; i < this.receivingItems.length; i++) {
            const item = this.receivingItems[i];
            transactionsToVoid = transactionsToVoid.concat(item.voidItem({userId: userId, date: voidDate}));
        }
        return transactionsToVoid;
    }

    /**
     * Voids a receiving item. If no shipmentId is provided the base receiving item is voided
     * @param shipmentId
     * @param userId
     * @param voidDate
     * @return {Array|*} of transaction ids to void
     */
    voidReceivingItem({userId, shipmentId=null, date=new Date()}){
        let item = shipmentId ? this._getReceivingItemForShipmentId(shipmentId) : this.baseReceivingItem;
        return item.voidItem({userId: userId, date: date});
    }

    _recalculateBaseReceivingItemExpectedQuantity(){
        const allocatedToShipments = this.receivingTotals.expectedQuantityAllocatedToShipments;
        this.baseReceivingItem.setExpectedQuantity(this.quantity - allocatedToShipments);
    }

    getInboundTransactionParams({userId}){
        if(!this._poRef.ref)
            throw new Error("Parent PO must have a ref set");

        //Do not create transaction when quantity is zero
        if(this.quantity === 0)
            return null;

        return {
            typeName: "INBOUND-ADD",
            productId: this.product.id,
            itemCount: this.quantity,
            userId: userId,
            meta: {
                poItemId: this.id,
                poRef: this._poRef.ref
            }
        }
    }

    getNewTransactionsForReceivedItems({reserveTargetCount, currentReserveCount}){
        let transactions = this.baseReceivingItem.getTransactions({reserveTargetCount, currentReserveCount});
        let quantityAllocatedToReserve = this.baseReceivingItem.getQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount});
        for (let i = 0; i < this.receivingItems.length; i++) {
            const receivingItem = this.receivingItems[i];
            transactions = transactions.concat(receivingItem.getTransactions({reserveTargetCount, currentReserveCount: currentReserveCount + quantityAllocatedToReserve}));
            //Get the quantity added to reserve by the transactions for this receiving item. Allows tracking towards overall reserveTargetCount from multiple items
            quantityAllocatedToReserve += receivingItem.getQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount});
        }
        return transactions;
    }pu

    //TODO move
    /**
     * This method is used after a PO item has already been saved once. Checks to see if transaction needs to be updated and does so via
     * dependency injection if needed
     */
    async updateInboundTransaction({transactionClient, userId, log}){
        if(!this.inboundTransactionId)
            return {status: "noAction", reason: "There is no current Inbound Transaction Id", productId: this.product.id};
        if(this.quantity < 0 )
            throw new Error("Cannot create/update transaction when PO Item has negative quantity");

        let transaction;
        //Reject if transaction not found
        try{
            transaction = await transactionClient.getTransactionByIdOrRef(this.inboundTransactionId);
        }
        catch (e) {
            if(e.code === 404)
                return Promise.reject({message: `Transaction with ID ${this.inboundTransactionId} cannot be found`, code: 404});
            return Promise.reject(e);
        }

        let transactionUpdatedNeeded = doesPoItemTransactionNeedToBeUpdated({transaction: transaction, poItem: this, log: log});
        if(!transactionUpdatedNeeded)
            return {status: "noAction", reason: `Existing transaction (${transaction.ref}) does not require update`, productId: this.product.id};

        //Transaction needs to be updated
        let voidPromise = transactionClient.voidTransaction({idOrRef: this.inboundTransactionId, comment: "Transaction automatically voided during PO Item update"});
        this.inboundTransactionIdHistory.push(this.inboundTransactionId); //Push to history as soon as voided is issued

        let newTransactionPromise = null;
        let status, reason;
        if(this.quantity === 0) { //Do not create a new transaction with a quantity of zero. It's not needed
            status = "voidOnly";
            reason = "PO Item is for a quantity of 0. This PO item is now considered removed and has no valid corresponding transaction";
        }
        else{
            newTransactionPromise = transactionClient.createTransactionFromParams(this.getInboundTransactionParams({userId: userId}));
            status = "voidAndReplace";
            reason = `Update Required. Transaction (${this.inboundTransactionId}) voided and replaced with new new transaction `;
        }

        return Promise.all([voidPromise, newTransactionPromise])
            .then(values => {
                let newTransaction = values[1];
                if(newTransaction) {
                    this.inboundTransactionId = newTransaction.ref;
                    reason = reason + newTransaction.ref;
                }
                else
                    this.inboundTransactionId = null; //This is the quantity === 0 case
                return {status: status, reason: reason, newTransaction: newTransaction, productId: this.product.id};
            })
    }

    //Only allow changes to quantity, unitPrice, shippingCostAllocationPercentage
    assertNoDisallowedChanges(sourceItem){
        if(this.product.id !== sourceItem.product.id)
            throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot have their products changed. PO Item with ID ${this.id} must have product id ${this.product.id}`});
        if(this.inboundTransactionId !== sourceItem.inboundTransactionId)
            throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot have inboundTransactionId changed manually.`});
        if(!this.inboundTransactionIdHistory.every(x => sourceItem.inboundTransactionIdHistory.includes(x)))
            throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot have inboundTransactionIdHistory changed manually.`});
        //Can only "Upgrade" to manual count
        if(sourceItem.manualCountRules && sourceItem.manualCountRules.requiredInd && (!this.manualCountRules || !this.manualCountRules.requiredInd))
            throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot have manual count removed after it is set.`});
        if(sourceItem.manualCountRules && sourceItem.manualCountRules.requiredSource && (sourceItem.manualCountRules.requiredSource !== this.manualCountRules.requiredSource))
            throw new ErrorWithStatusCode({code: 400, message: `PO Items cannot have manual count required source changed after it is set.`});
    }

    updateProductRefFromProduct(product){
        this.product.updateProductRefFromProduct(product);
    }

    getForDb(){
        let res = this.toJSON();
        res.product = this.product.getForDb();
        return res;
    }

    getForDbReceivingUpdate(){
        let res = {
            baseReceivingItem: this.baseReceivingItem.getForDb(),
            receivingItems: [],
            receivingTotals: this.receivingTotals,
            isOpen: this.isOpen
        };
        this.receivingItems.forEach(item => {
            res.receivingItems.push(item.getForDb())
        });
        return res;
    }

    toJSON(){
        let {["_poRef"]:omit, ...res} = this;
        res.baseReceivingItem = this.baseReceivingItem.getForDb();
        res.receivingItems = this.receivingItems.map(item => item.getForDb());
        res.quantity = this.quantity;
        delete res._quantity;
        res.receivingTotals = this.receivingTotals;
        res.isOpen = this.isOpen;
        res.costs = this.costs;
        res.totals = this.totals;
        return res;
    }
}

export { PurchaseOrderItem };