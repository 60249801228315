//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Card, CardBody, CardHeader } from 'reactstrap';
import {LoadingSpinner} from "../atoms/LoadingSpinner";
import {ErrorAlert} from "../atoms/ErrorAlert";

class ReceivingStatsCard extends React.Component {
    render() {
        const {isLoaded, error, notShippedPoCount, notShippedUnitCount, inboundShipmentCount, inboundPoCount, inboundUnitCount} = this.props;

        if(!isLoaded)
            return <LoadingSpinner/>;

        if(error)
            return <ErrorAlert error={error}/>;

        return (
            <Card>
                <CardHeader className="pt-2 pb-2"><h4>Inbound Stats</h4></CardHeader>
                <CardBody>
                    <Row>
                        <Col md={4}>
                            <h5 className="text-center">Inbound POs</h5>
                            <h6 className="text-center">{inboundPoCount || 0}</h6>
                        </Col>
                        <Col md={4}>
                            <h5 className="text-center">Not Shipped POs</h5>
                            <h6 className="text-center">{notShippedPoCount || 0}</h6>
                        </Col>
                        <Col md={4}>
                            <h5 className="text-center">Inbound Shipments</h5>
                            <h6 className="text-center">{inboundShipmentCount || 0}</h6>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6}>
                            <h5 className="text-center">Inbound Units</h5>
                            <h6 className="text-center">{inboundUnitCount || 0}</h6>
                        </Col>
                        <Col md={6}>
                            <h5 className="text-center">Not Shipped Units</h5>
                            <h6 className="text-center">{notShippedUnitCount || 0}</h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            );
    }
}

ReceivingStatsCard.propTypes = {
    notShippedPoCount: PropTypes.number,
    notShippedUnitCount: PropTypes.number,
    inboundShipmentCount: PropTypes.number,
    inboundPoCount: PropTypes.number,
    inboundUnitCount: PropTypes.number,
    isLoaded: PropTypes.bool.isRequired,
    error: PropTypes.object
};

export {ReceivingStatsCard};