//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import {PoTableContent} from "../functional/PoTableContent";
import {StandaloneShipmentsContent} from "../functional/StandaloneShipmentsContent";

class DashboardContent extends React.Component {
    render() {
        console.log(this.props);
        return (
            <Row>
                <Col md={6}>
                    <h4 className={"text-center"}>Recent POs</h4>
                    <PoTableContent {...this.props} pagination={true}/>
                </Col>
                <Col md={6}>
                    <h4 className={"text-center"}>Recent Inbound Shipments</h4>
                    <StandaloneShipmentsContent {...this.props} pagination={true}/>
                </Col>
            </Row>
            );
    }
}

DashboardContent.propTypes = {
  client: PropTypes.object.isRequired,
  filterText: PropTypes.string
};

export {DashboardContent};