import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {PurchaseOrder} from '../../vendor/obj/PurchaseOrder'
import PoEditContent from "../organisms/edit/PoEditContent";
import PoPatch from "../../domain/PoPatch"
import SettingsContext from "../../context/SettingsContext";

class NewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            po: null
        };
    }

    _patchPo(keys, data, updateUserSettings, defaultVendor){
        const po = PoPatch.patchPo({po: this.state.po, keys: keys, data: data});
        this.setState({po: po});
        if(!defaultVendor && po.vendor){
            if(!po.vendor) return;
            if(!defaultVendor)
                updateUserSettings({defaultVendor: po.vendor});
            else if(defaultVendor.id !== po.vendor.id)
                updateUserSettings({defaultVendor: po.vendor});
        }

    }

    _defaultPo({defaultVendor}){
        const po = PurchaseOrder.defaultNew({userId: 'a'});
        if(defaultVendor)
            po.vendor = defaultVendor;
        this.setState({po});
        return po;
    }

    render() {
        const {client, productsClient} = this.props;
        let {po} = this.state;

        return (<SettingsContext.Consumer>
            {({defaultVendor, updateUserSettings}) => {
                if(!this.state.po)
                    po = this._defaultPo({defaultVendor});

                return (<div className="ml-3 mr-3">
                    <h1>New Purchase Order</h1>
                    <ToolBar client={client}/>
                    <PoEditContent po={po} client={client} productsClient={productsClient} patchPo={(keys, data) => this._patchPo(keys, data, updateUserSettings, defaultVendor)}/>
                </div>)
            }}
        </SettingsContext.Consumer>)
    }
}

NewPage.propTypes = {
    client: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired
};

export {NewPage};