import React, {Component} from 'react';
import PropTypes from "prop-types";

class PoDetailRow extends Component {
    render() {
        const {label, data, className} = this.props;
        return (<tr>
            <th scope="row" width="40%">{label}</th>
            <td className={className}>{data}</td>
        </tr>)
    }
}

PoDetailRow.propTypes = {
    label: PropTypes.string.isRequired,
    className: PropTypes.string
};

export {PoDetailRow};
