//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {StandaloneShipmentsTable} from '../../molecules/tables/StandaloneShipmentsTable';

class StandaloneShipmentsContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shipmentsError: null,
            shipmentsIsLoaded: false,
            shipments: []
        };

    }

    componentDidMount() {
        this._loadTrackings();
    }

    componentDidUpdate(prevProps){
        if(prevProps.filterText !== this.props.filterText)
            this._loadTrackings();
    }


    _loadTrackings(){
        this.props.client.standaloneShipments.getStandaloneShipments({limit: 30, dateSort: -1, filter: this.props.filterText})
            .then(results => {
                if(this.props.filterText.toUpperCase() === "INBOUND")
                    results = results.filter(standaloneShipment => standaloneShipment.shipment.checkIn && standaloneShipment.shipment.checkIn.isCheckedIn === false);

                this.setState({
                    shipmentsIsLoaded: true,
                    shipments: results
                });
            }).catch(error => {
            console.log(error);

            this.setState({
                shipmentsIsLoaded: true,
                shipmentsError: error
            });
        });
    }

    render() {
        const { shipmentsError, shipmentsIsLoaded, shipments } = this.state;
        const {receivingContext} = this.props;
        return <StandaloneShipmentsTable receivingContext={receivingContext} shipments={shipments}
                                         isLoaded={shipmentsIsLoaded} pagination={this.props.pagination} error={shipmentsError}/>;
    }
}

StandaloneShipmentsContent.propTypes = {
  client: PropTypes.object.isRequired,
  filterText: PropTypes.string,
  receivingContext: PropTypes.string,
  pagination: PropTypes.bool
};

export {StandaloneShipmentsContent};