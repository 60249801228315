import React, {Component} from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from "prop-types";

class FilterDropdown extends Component {
    _itemClicked(text){
        if(this.props.itemClicked)
            this.props.itemClicked(text);
    }

    render() {
        const {dropdownOpen, toggle, filterText} = this.props;
        return (
            <Dropdown isOpen={dropdownOpen} toggle={toggle} className="d-inline-block mr-2" size="lg">
                <DropdownToggle caret>
                    Filters ({filterText})
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem onClick={() => this._itemClicked("All")}>All</DropdownItem>
                    <DropdownItem onClick={() => this._itemClicked("Unshipped")}>Unshipped</DropdownItem>
                    <DropdownItem onClick={() => this._itemClicked("Inbound")}>Inbound</DropdownItem>
                    <DropdownItem onClick={() => this._itemClicked("Unpaid")}>Unpaid</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

FilterDropdown.propTypes = {
    itemClicked: PropTypes.func.isRequired,
    filterText: PropTypes.string.isRequired
};

export {FilterDropdown};
