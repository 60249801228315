import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {PoViewContent} from '../organisms/view/PoViewContent'
import {LoadingSpinner} from '../atoms/LoadingSpinner'

class PoView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            po: null,
            isLoaded: false,
            error: null
        }
    }

    componentDidMount() {
        this._loadPo();
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.poRef !== this.poRef)
            this._loadPo();
    }

    get poRef(){
        return this.props.match.params.poRef;
    }

    _loadPo(){
        const {client} = this.props;
        client.purchaseOrders.getPurchaseOrderByRef(this.poRef)
            .then(po => {
                this.setState({
                    isLoaded: true,
                    po: po
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                });
            });
    }

    _renderBody(){
        const {po, error, isLoaded} = this.state;
        const {client} = this.props;
        if (error)
            return <div>Error: {this.props.error ? this.props.error.message : ""}</div>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return <PoViewContent client={client} po={po}/>

    }

    render() {
        const {client} = this.props;

        return (
            <div className="ml-3 mr-3">
                <h1>Purchase Order {this.poRef}</h1>
                <div>

                </div>
                <ToolBar client={client}/>
                {this._renderBody()}
            </div>
        )
    }
}

PoView.propTypes = {
    client: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export {PoView};