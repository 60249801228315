import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalFooter, FormGroup, ModalBody, Label, Input } from 'reactstrap';
import PropTypes from "prop-types";
import {parseTrackings} from "../../../domain/parseTrackings"
import {doesTrackingExist} from "../../../domain/checkIfTrackingExists";
import {LoadingSpinner} from "../../atoms/LoadingSpinner";

class AddTrackingsModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shipments: [],
            warnings: [],
            loading: false,
            disableSave: true,
            loadingTimestamp: null
        };
    }

    async _buildShipmentFromTrackingNumber(trackingNumber){
        const {client} = this.props;

        const getSlugPromise =  client.trackings.getSlugForTrackingNumber(trackingNumber);
        const checkExistingPromise = doesTrackingExist({client, trackingNumber});

        const warnings = [];

        return Promise.all([getSlugPromise, checkExistingPromise])
            .then(results => {
                const [slugs, trackingExistsWarning] = results;
                if(slugs.length > 1)
                    warnings.push(`Tracking ${trackingNumber} has multiple slugs. Using ${slugs[0]}`);
                if(trackingExistsWarning)
                    warnings.push(`Tracking ${trackingNumber} already exists`);
                return {shipment: {trackingNumber, slug: slugs[0]}, warnings};
            })
            .catch(err => {
                console.log(err);
                warnings.push(`An error occurred for tracking ${trackingNumber}`);
                return {err, warnings};
            })

    }

    async trackingsChange(e) {
        const trackingNumbers = parseTrackings(e.target.value);
        const shipmentPromises = trackingNumbers.map(trackingNumber => this._buildShipmentFromTrackingNumber(trackingNumber));

        const LOADING_TIMESTAMP = new Date().getTime();
        const setStateAsync = updater => new Promise(resolve => this.setState(updater, resolve));
        await setStateAsync({disableSave: true, loading: true, loadingTimestamp: LOADING_TIMESTAMP});

        Promise.all(shipmentPromises)
            .then(results => {
                const shipments = results.filter(r => r.shipment).map(r => r.shipment);
                const warnings = results.map(r => r.warnings).flat();
                this.setState((prevState) => {
                    if(LOADING_TIMESTAMP !== prevState.loadingTimestamp)
                        return {}; //Do not perform state update
                    warnings.push(`Total Accepted Tracking Numbers: ${shipments.length}`);
                    return {shipments, disableSave: shipments.length === 0, warnings: warnings, loading: false};
                });
            })
    }

    addAllTrackings(){
        this.props.addShipments(this.state.shipments);
        this.props.closeModal();
    }

    _renderFooter(){
        if(this.state.loading)
            return <LoadingSpinner/>;
        return <ul>{this.state.warnings.map(w => <li>{w}</li>)}</ul>;
    }

    render() {
        const {closeModal} = this.props;
        return (
            <div>
                <Modal isOpen={true} toggle={e => closeModal()} className={this.props.className}>
                    <ModalHeader toggle={e => closeModal()}>Add Trackings</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="exampleText">Trackings</Label>
                            <Input type="textarea" rows="15" onChange={e => this.trackingsChange(e)}/>
                        </FormGroup>
                        {this._renderFooter()}
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" disabled={this.state.disableSave} onClick={e => this.addAllTrackings()}>Add Tracking Numbers</Button>{' '}
                        <Button color="secondary" onClick={e => closeModal()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

AddTrackingsModal.propTypes = {
    addShipments: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    client: PropTypes.object.isRequired
};

export {AddTrackingsModal};
