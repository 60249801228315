/*When creating a PO in non USD currency an object will be created on the UI where the invoiceCode is the
source (original) currency. In this case currencyCode and invoiceCode will be the same (for example GBP or USD).
When this PO is saved the currencyCode will needed to be converted to the defaultCurrency using invoiceToDefaultExchangeRate. The exchange rate will be applied
to all saved values (costs and unit prices). The PO will then have a defaultCurrency and currencyCode that are the same.
Invoice code will remain the same so it can be converted back.*/

/*By default POs will always be fetched and viewed in base (USD) currency. When a user wants to view it in
original currency a new PO object will be instantiated with the conversion applied*/

//TODO Implement this feature and write unit tests
//For now all POs will have a default currency of USD and they will always be stored in USD. There will be not mechanism to change
//the default currency.
const DEFAULT_CURRENCY_CODE = "USD";

export default class Currency{
    constructor({defaultCurrency=DEFAULT_CURRENCY_CODE, code=DEFAULT_CURRENCY_CODE, invoiceCode=DEFAULT_CURRENCY_CODE, invoiceToDefaultExchangeRate=1}){

        //Currency code is the currency that the PO is currently converted to. All military values have this code.
        this.code = code || DEFAULT_CURRENCY_CODE;

        //The original currency code for the PO. This is the currency the invoice is in.
        this.invoiceCode = invoiceCode;

        //The default currency
        this.defaultCode = DEFAULT_CURRENCY_CODE;

        this.invoiceToDefaultExchangeRate = invoiceToDefaultExchangeRate;
    }

    //Conversion methods do not mutate purchase order. They return a close with differences in values as .code only
    //Returns a NEW purchase order in the default currency
    static convertPurchaseOrderToDefaultCurrency(purchaseOrder){

    }

    //Returns a NEW purchase order in the invoice currency
    static convertPurchaseOrderToInvoiceCurrency(purchaseOrder){

    }
};