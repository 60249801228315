class VisualInspection{
    constructor({confirmedDate, confirmedByUserId=null}={},
                receivingItem){
        if(!receivingItem)
            throw new Error("receivingItem reference required for VisualInspection");

        this.confirmedDate = confirmedDate ? new Date(confirmedDate) : null;
        this.confirmedByUserId = confirmedByUserId;

        //Reference only
        this._receivingItem = receivingItem;
    }

    get isVisuallyConfirmed(){
        return this.confirmedByUserId !== null || this.confirmedDate !== null;
    }

    get countedQuantity(){
        if(!this.isVisuallyConfirmed)
            return 0;
        return this._receivingItem.expectedQuantity;
    }

    get additionalExpectedUnits(){
        if(!this.isVisuallyConfirmed)
            return this._receivingItem.expectedQuantity;
        return 0;
    }

    get missingUnits(){
        return 0; //Visual Inspection can never have missing units
    }

    get extraUnits(){
        return 0; //Visual Inspection can never have extra units
    }

    //Open when not confirmed
    get isOpen(){
        return !this.isVisuallyConfirmed;
    }

    voidCount(){
        this.confirmedDate = null;
        this.confirmedByUserId = null;
    }

    getTransactionsForReceivedItems({reserveTargetCount, currentReserveCount}){
        if(this.isOpen)
            return [];
        let reserveAllocationCount = this.calculateQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount});

        let transactions = [];
        let inventoryAddTransaction = this._getInventoryAddTransaction(reserveAllocationCount);
        let reserveAddTransaction = this._getReserveAddTransaction(reserveAllocationCount);

        if(reserveAddTransaction) transactions.push(reserveAddTransaction);
        if(inventoryAddTransaction) transactions.push(inventoryAddTransaction);

        return transactions;
    }

    calculateQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount}){
        if(reserveTargetCount < currentReserveCount)
            return 0;
        let target = reserveTargetCount - currentReserveCount;
        if(target > this._receivingItem.expectedQuantity)
            return this._receivingItem.expectedQuantity;
        return target;
    }

    _getInventoryAddTransaction(quantityAllocatedToReserve){
        return {
            typeName: "INVENTORY-ADD-FRM-INBOUND",
            productId: this._receivingItem._poItemRef.product.id,
            userId: this.confirmedByUserId,
            itemCount: this._receivingItem.expectedQuantity,
            meta: this._receivingItem._getTransactionMeta(),
        }
    }
    _getReserveAddTransaction(quantityAllocatedToReserve){
        if(quantityAllocatedToReserve <= 0)
            return null;

        return {
            typeName: "STORE-RESV-ADD-FRM-INVENTORY-INBOUND",
            productId: this._receivingItem._poItemRef.product.id,
            userId: this.confirmedByUserId,
            itemCount: quantityAllocatedToReserve,
            meta: this._receivingItem._getTransactionMeta(),
        }
    }

    isEqual(other){
        if(this.confirmedDate == null && other.confirmedDate != null)
            return false;
        else if(this.confirmedDate && (this.confirmedDate.getTime() !== other.confirmedDate.getTime()))
            return false;

        if(this.confirmedByUserId !== other.confirmedByUserId)
            return false;

        return true;
    }

    getForDb(){
        let {["_receivingItem"]:omit, ...res} = this;
        return res;
    }

    toString(){
        let reserveAddString = this._receivingItem.reserveAddTransactionId ? `. Reserve add transaction: ${this._receivingItem.reserveAddTransactionId}` : '';
        if(this.isVisuallyConfirmed)
            return `User ${this.confirmedByUserId} visually confirmed ${this._receivingItem.expectedQuantity} units of `+
             `K${this._receivingItem._poItemRef.product.id} at ${this.confirmedDate}. Inventory add transaction: ${this._receivingItem.inventoryAddTransactionId}${reserveAddString}`
              ;
        return `This item has not yet been visually confirmed`;
    }

    toJSON(){
        return this.getForDb(); //Remove reference fields
    }
}

export { VisualInspection };