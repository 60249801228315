import { ErrorWithStatusCode } from '../errors/ErrorWithStatusCode';

class ManualCount{
    constructor({countedQuantity=null, countedByUserId=null, countDate=null,
                    additionalUnitsExpectedInd=null, discrepancyTransactionId=null, discrepancyTransactionIdHistory=null}={},
               receivingItem){
        if(!receivingItem)
            throw new Error("receivingItem reference required for ManualCount");

        this.countedQuantity = countedQuantity;
        this.countedByUserId = countedByUserId;
        this.countDate = countDate ? new Date(countDate) : null;

        //When this indicator is set a discrepancy will not be created for an under received count.
        //This indicates that any units that have not been received are still inbound and expected.
        if(additionalUnitsExpectedInd !== null && !this.isManuallyCounted) {
            if(additionalUnitsExpectedInd)
                throw new Error("Cannot have additionalUnitsExpectedInd when there is no count");
            else //Clean the input to not set if the item has not been manually counted
                additionalUnitsExpectedInd = null;
        }
        this.additionalUnitsExpectedInd = additionalUnitsExpectedInd;


        //null unless a discrepancy transaction was created as a result of the manual count.
        this.discrepancyTransactionId = discrepancyTransactionId;
        this.discrepancyTransactionIdHistory = discrepancyTransactionIdHistory ? discrepancyTransactionIdHistory : [];

        //Reference only
        this._receivingItem = receivingItem;

        if(this.countedQuantity)
            this._validateQty(this.countedQuantity);

        this._autoClearLogicForAdditionalUnitsExpectedInd();
    }

    get isManuallyCounted(){
        //True when countedQuantity has a value set
        return this.countedQuantity !== null;
    }

    get additionalExpectedUnits(){
        if(!this.isManuallyCounted)
            return this._receivingItem.expectedQuantity;
        if(!this.additionalUnitsExpectedInd)
            return 0; //No additional units are expected
        let delta = this.getReceivingDeltaFromExpected();
        if(delta >= 0) return 0;
        return delta * -1; //Are are missing (remaining) when delta is negative
    }

    get missingUnits(){
        if(!this.isManuallyCounted)
            return 0; //No units have been confirmed missing
        if(this.additionalUnitsExpectedInd)
            return 0; //No units can be confirmed as missing if there are still additional units expected
        let delta = this.getReceivingDeltaFromExpected();
        if(delta >= 0) return 0;
        return delta * -1; //Are are missing (remaining) when delta is negative
    }

    get extraUnits(){
        if(!this.isManuallyCounted)
            return 0; //No units have been confirmed missing
        if(this.additionalUnitsExpectedInd)
            return 0; //No units can be confirmed as missing if there are still additional units expected
        let delta = this.getReceivingDeltaFromExpected();
        if(delta <= 0) return 0;
        return delta; //Are are extra when delta is positive
    }

    get isOpen(){
        if(!this.isManuallyCounted)
            return true;
        if(this.additionalExpectedUnits !== 0)
            return true; //Not fully received
        return false; //Closed
    }

    get reason(){
        if(this._receivingItem._poItemRef.manualCountRules.isRequired)
            return this._receivingItem._poItemRef.manualCountRules.requiredSource;
        return "Manual";
    }

    setDiscrepancyTransactionId(transactionId){
        if(this.discrepancyTransactionId)
            throw new ErrorWithStatusCode({code: 400, message: "Cannot set discrepancyTransactionId when it is already set"});
        this.discrepancyTransactionId = transactionId;
    }

    voidCount(){
        if(this.discrepancyTransactionId) //Only save history when there is something to save
            this.discrepancyTransactionIdHistory.push(this.discrepancyTransactionId);
        this.discrepancyTransactionId = null;
        this.countedQuantity = null;
        this.countedByUserId = null;
        this.countDate = null;
        this.additionalUnitsExpectedInd = null;
    }

    _validateQty(qtyToValidate){
        if(this.additionalUnitsExpectedInd && qtyToValidate >= this._receivingItem.expectedQuantity)
            throw new ErrorWithStatusCode({code: 400, message: "Additional units are expected but counted quantity is greater than or equal to expected quantity."});
    }

    _autoClearLogicForAdditionalUnitsExpectedInd(){
        if(this.additionalExpectedUnits === 0)
            this.additionalUnitsExpectedInd = false;
    }

    setCountedQuantity({countedQuantity, countedByUserId, additionalUnitsExpectedInd, date=new Date()}) {
        if(this.isManuallyCounted)
            throw new ErrorWithStatusCode({code: 400, message: "Manual count cannot be changed after it has been set without voiding the receiving item to reset it"});
        if (typeof countedQuantity !== "number" || isNaN(countedQuantity))
            throw new ErrorWithStatusCode({code: 400, message: "Valid numeric countedQuantity required"});
        if (countedQuantity < 0)
            throw new ErrorWithStatusCode({code: 400, message: "Valid numeric countedQuantity required. Must be greater than or equal to zero"});
        if(countedQuantity === this.countedQuantity)
            throw new ErrorWithStatusCode({code: 400, message: "No change to countedQuantity. Must provide new (different) countedQuantity"});
        if(additionalUnitsExpectedInd !== true && additionalUnitsExpectedInd !== false)
            throw new ErrorWithStatusCode({code: 400, message: "Must set additionalUnitsExpectedInd to either true or false"});

        this._validateQty(countedQuantity);

        //Perform quantity and user update
        this.countedQuantity = countedQuantity;
        this.countedByUserId = countedByUserId;
        this.countDate = date;
        this.additionalUnitsExpectedInd = additionalUnitsExpectedInd;
    }

    getTransactionsForReceivedItems({reserveTargetCount, currentReserveCount}){
        if(!this.isManuallyCounted)
            return []; //Nothing to do

        let reserveAllocationCount = this.calculateQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount});

        let transactions = [];
        let inventoryAddTransaction = this._getInventoryAddTransaction(reserveAllocationCount);
        let reserveAddTransaction = this._getReserveAddTransaction(reserveAllocationCount);
        let discrepancyTransaction = this._getNewDiscrepancyTransaction();

        if(reserveAddTransaction) transactions.push(reserveAddTransaction);
        if(inventoryAddTransaction) transactions.push(inventoryAddTransaction);
        if(discrepancyTransaction) transactions.push(discrepancyTransaction);

        return transactions;
    }

    isNewDiscrepancyTransactionRequired(){
        return this.discrepancyTransactionId == null //If set a discrepancy already exists
            && !this.additionalUnitsExpectedInd
            && this.getReceivingDeltaFromExpected() !== 0; //A discrepancy is only needed when there is a delta (actual vs expected mismatch)
    }

    calculateQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount}){
        if(reserveTargetCount < currentReserveCount)
            return 0;
        let target = reserveTargetCount - currentReserveCount;

        //Extra units are always allocated to inventory and not reserve
        let quantityAvailableForReserve = this.countedQuantity;
        if(quantityAvailableForReserve > this._receivingItem.expectedQuantity)
            quantityAvailableForReserve = this._receivingItem.expectedQuantity;

        if(target > quantityAvailableForReserve)
            return quantityAvailableForReserve;
        return target;
    }

    _getInventoryAddTransaction(quantityAllocatedToReserve){
        //When there are extra units the inventory add transaction should be the same as if the PO was fully received w/o discrepancy
        let itemCount = this._receivingItem.expectedQuantity < this.countedQuantity  ? this._receivingItem.expectedQuantity : this.countedQuantity;

        if(itemCount > this._receivingItem.expectedQuantity) //Item count cannot be more than what is expected
            itemCount = this._receivingItem.expectedQuantity;
        if(itemCount <= 0)
            return null;

        return {
            typeName: "INVENTORY-ADD-FRM-INBOUND",
            productId: this._receivingItem._poItemRef.product.id,
            userId: this.countedByUserId,
            itemCount: itemCount,
            meta: this._receivingItem._getTransactionMeta(),
        }
    }

    _getReserveAddTransaction(quantityAllocatedToReserve){
        if(quantityAllocatedToReserve <= 0)
            return null;

        return {
            typeName: "STORE-RESV-ADD-FRM-INVENTORY-INBOUND",
            productId: this._receivingItem._poItemRef.product.id,
            userId: this.countedByUserId,
            itemCount: quantityAllocatedToReserve,
            meta: this._receivingItem._getTransactionMeta(),
        }
    }

    _getNewDiscrepancyTransaction() {
        if (!this.isNewDiscrepancyTransactionRequired())
            return null;

        let delta = this.getReceivingDeltaFromExpected();
        let type = delta < 0 ? "INBOUND-RM-ADJ" : "INVENTORY-ADD-ADJ";
        let meta =  this._receivingItem._getTransactionMeta();
        meta.inboundDiscrepancyInd = true; //Flag as an inbound discrepancy transaction. Used when setting the id after transaction saved

        return {
            typeName: type,
            productId:  this._receivingItem._poItemRef.product.id,
            userId: this.countedByUserId,
            itemCount: Math.abs(delta),
            meta: meta,
            comment: `Inbound Receiving Adjustment for PO ${this._receivingItem._poItemRef._poRef.ref}`
        }
    }

    //0 indicates all units received, less than 0 indicates missing units, greater than 0 indicates extra units
    getReceivingDeltaFromExpected(){
        if(!this.isManuallyCounted)
            return null;
        return this.countedQuantity - this._receivingItem.expectedQuantity;
    }

    isEqual(other){
        if(this.countedQuantity !== other.countedQuantity)
            return false;
        if(this.countedByUserId !== other.countedByUserId)
            return false;

        if(this.countDate == null && other.countDate != null)
            return false;
        else if(this.countDate && (this.countDate.getTime() !== other.countDate.getTime()))
            return false;

        if(!!this.additionalUnitsExpectedInd !== !!other.additionalUnitsExpectedInd)
            return false;
        if(this.discrepancyTransactionId !== other.discrepancyTransactionId)
            return false;

        for (let i = 0; i < this.discrepancyTransactionIdHistory.length; i++) {
            if(this.discrepancyTransactionIdHistory[i] !== other.discrepancyTransactionIdHistory[i])
                return false;
        }

        return true;
    }

    toString(){
        if(this.isManuallyCounted) {
            let discrepancyString = "No discrepancy needed (Fully Received)";
            let reserveAddString = this._receivingItem.reserveAddTransactionId ? `. Reserve add transaction: ${this._receivingItem.reserveAddTransactionId}` : '';
            if(this.discrepancyTransactionId) {
                let delta = this.getReceivingDeltaFromExpected();
                let adjective = delta < 0 ? "missing" : "extra";
                discrepancyString = `A Discrepancy for ${Math.abs(delta)} ${adjective} unit${Math.abs(delta) !== 1 ? 's' : ''} was created with Transaction ${this.discrepancyTransactionId}`;
            }
            else if(this.additionalUnitsExpectedInd){
                let delta = Math.abs(this.getReceivingDeltaFromExpected());
                discrepancyString = `Partially received. ${delta} additional unit${Math.abs(delta) !== 1 ? 's' : ''} are expected. No discrepancy created at this time.`;
            }

            return `User ${this.countedByUserId} manually counted ${this.countedQuantity} units of K${this._receivingItem._poItemRef.product.id} at ${this.countDate}. ` +
                `Inventory Add Transaction: ${this._receivingItem.inventoryAddTransactionId}${reserveAddString}. ${discrepancyString}`;
        }
        return `This item has not yet been manually counted`;
    }

    getForDb(){
        let {["_receivingItem"]:omit, ...res} = this;
        res.reason = this.reason;
        return res;
    }

    toJSON(){
        return this.getForDb(); //Remove reference fields
    }
}

export { ManualCount };