class PoProductRef{
    constructor({id, name, shortName, partNumber}){
        this.id = id;
        this.name = name;
        this.shortName = shortName;
        this.partNumber = partNumber;
    }

    updateProductRefFromProduct(product){
        this.name = product.detail.name;
        this.shortName = product.detail.shortName;
        this.partNumber = product.key.modelNumber;
    }

    toString(){
        return `K${this.id} - ${this.shortName}`;
    }

    toJSON(){
        let res = Object.assign({}, this);
        res.string = this.toString();
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        delete res.string; //No need to duplicate in db
        return res;
    }
}

export { PoProductRef };