//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardHeader, Badge } from 'reactstrap';

class ReceivingCard extends React.Component {
    render() {
        const {badgeText, headerText, children} = this.props;
        return (
            <Card className="mb-3">
                <CardHeader><h4><Badge color="secondary" pill>{badgeText}</Badge> {headerText}</h4></CardHeader>
                <CardBody>
                    {children}
                </CardBody>
            </Card>
        );
    }
}

ReceivingCard.propTypes = {
    badgeText: PropTypes.string.isRequired,
    headerText: PropTypes.string.isRequired
};

export {ReceivingCard};