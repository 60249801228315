class PoUserRef {
    constructor({id, username, displayName}) {
        this.id = id;
        this.username = username;
        this.displayName = displayName;
    }

    equals(source){
        return this.id === source.id
            && this.username === source.username
            && this.displayName === source.displayName
    }
}

export { PoUserRef };