import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Button, Label, Input, Alert, Badge, Row, Col } from 'reactstrap';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {PurchaseOrderItem} from "../../../vendor/obj/PurchaseOrderItem";
import Shipment from "../../../vendor/obj/Shipment";
import {AddTrackingsModal} from "./AddTrackingsModal";
import {doesTrackingExist} from "../../../domain/checkIfTrackingExists";

class AddShipmentBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            trackingNumber: "",
            isLoading: false,
            error: null,
            slug: null,
            slugOptions: null,
            trackingExistsWarning: false,
            showTrackingsModal: false
        };
    }

    _handleTrackingChange(e){
        const {client} = this.props;
        const tracking = e.target.value;

        this.setState({error: null, isLoading: true, trackingNumber: tracking});
        if(tracking.length <= 3)
            return; //Don't search

        const getSlugPromise =  client.trackings.getSlugForTrackingNumber(tracking);
        const checkExistingPromise = doesTrackingExist({client, tracking});
        Promise.all([getSlugPromise, checkExistingPromise])
            .then(results => {
                const [slugs, trackingExistsWarning] = results;
                if(slugs.length > 1)
                    this.setState({slugOptions: slugs, isLoading: false, slug: null, trackingExistsWarning});
                else
                    this.setState({error: null, isLoading: false, slug: slugs[0], trackingExistsWarning, slugOptions: null})
            })
            .catch(err => {
                if(err.statusCode === 404)
                    this.setState({error: null, isLoading: false, slug: null, trackingExistsWarning: false, slugOptions: null});
                else
                    this.setState({error: err, isLoading: false, slug: null, trackingExistsWarning: false, slugOptions: null});
            })
    }

    _addShipment({trackingNumber, slug}){
        const {patchPo} = this.props;
        const shipment = new Shipment({trackingNumber: trackingNumber, slug: slug});
        patchPo("addShipment", shipment);
        this.setState({slug: null, trackingNumber: ""});
    }

    _addShipments(shipments){
        const {patchPo} = this.props;
        const builtShipments = shipments.map(s => new Shipment(s));
        patchPo("addShipments", builtShipments);
    }

    _renderSlug(){
        const {slug, isLoading, slugOptions} = this.state;
        if(isLoading)
            return <LoadingSpinner/>;
        if(slugOptions)
            return  <Badge color="warning">Select Carrier - Multiple Found</Badge>;
        return slug ? <Badge color="info">{slug.toUpperCase()}</Badge> : <Badge color="light">Carrier Not Found</Badge>;
    }

    _renderError(){
        const { error } = this.state;
        if(!error) return;
        return <Alert color="danger" className="ml-2 mt-2 small">{error.message}</Alert>
    }

    _renderDuplicateWarning(){
        const { trackingExistsWarning } = this.state;
        if(!trackingExistsWarning) return;
        return <Alert color="warning" className="ml-2 mt-2 small">Warning: Tracking Already Exists</Alert>
    }

    _renderSlugOptions(){
        const { slugOptions } = this.state;
        if(!slugOptions) return "";
        return <div className="">
            {slugOptions.map(slug => <Button color="secondary" outline size="sm" className="mx-1" onClick={e => this.setState({slug: slug, slugOptions: null})}>{slug.toUpperCase()}</Button> )}
        </div>;
    }

    _renderModal(){
        if(!this.state.showTrackingsModal) return "";
        return <AddTrackingsModal client={this.props.client} addShipments={shipments => this._addShipments(shipments)} closeModal={() => this.setState({showTrackingsModal: false})}/>
    }

    render() {
        const { trackingNumber, slug } = this.state;
        const {className, po, patchPo} = this.props;
        return (
                <Form inline className={className}>
                    {this._renderModal()}
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label for="search" className="mr-sm-2">Tracking Number</Label>
                            <Input name="trackingNumber" id="trackingNumber" placeholder="1ZABC1234567890" value={trackingNumber} onChange={e => this._handleTrackingChange(e)} />
                        </FormGroup>
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                            <Label className="mr-sm-2">{this._renderSlug()}</Label>
                            <Label className="mr-sm-2">{this._renderDuplicateWarning()}</Label>
                        </FormGroup>
                        {this._renderSlugOptions()}
                        <Button color="primary" disabled={!slug} onClick={e => this._addShipment({trackingNumber: this.state.trackingNumber, slug: this.state.slug})}>Add</Button>
                        <Button color="primary" outline  className="ml-2" onClick={e => this.setState({showTrackingsModal: true})}>Add Multiple</Button>
                        {this._renderError()}
                        <FormGroup className="mb-2 mr-sm-2 mb-sm-0 ml-5 pl-5">
                            <Label check for="shipToOffice" >
                                <Input type="checkbox" name="shipToOffice" id="shipToOffice" value={!!po.meta.shipToOffice} onChange={e => patchPo("shipToOffice", e.target.checked)} />
                                Ship To Office
                            </Label>
                        </FormGroup>
                </Form>
            );
    }
}

AddShipmentBar.propTypes = {
    po: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    patchPo: PropTypes.func.isRequired
};

export {AddShipmentBar}