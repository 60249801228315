import React, { Component } from 'react';
import '../node_modules/react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
import '../node_modules/bootstrap/dist/css/bootstrap.css';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize'
import './App.css';
import { BrowserRouter as Router, Route } from "react-router-dom";


import {PurchasingClient} from './vendor/client/PurchasingClient'
import Cookies from 'universal-cookie';
import {Dashboard} from './components/pages/Dashboard'
import {Search} from './components/pages/Search'
import {PoView} from './components/pages/PoView'
import NavBar from './components/organisms/functional/NavBar'
import {ProductHistorySearch} from "./components/pages/ProductHistorySearch";
import LiteProductsClient from "./custom_vendor/products/client"
import LiteTransactionsClient from "./custom_vendor/transactions/client";
import {ProductHistoryView} from "./components/pages/ProductHistoryView";
import {NewPage} from "./components/pages/NewPage";
import {SsoClient, config as ssoConfig} from "./custom_vendor/sso";
import {PoEdit} from "./components/pages/PoEdit";
import {ReceivingDashboard} from "./components/pages/ReceivingDashboard";
import AppProvider from "./context/AppProvider";
import SettingsProvider from "./context/SettingsProvider";
import {LoadingSpinner} from "./components/atoms/LoadingSpinner";
import {ReceivingEdit} from "./components/pages/ReceivingEdit";

const cookies = new Cookies();

class App extends Component {
    constructor(props){
        super(props);

        this.state = {
            user: null,
            marketplaces: null,
            //Settings
            dashboardFilter: "All",
            showAdditionalFinanceData: false,
            defaultVendor: null
        }
    }

    updateUserSettings({dashboardFilter, showAdditionalFinanceData, defaultVendor}){
        const args = {};
        if(dashboardFilter !== undefined)
            args.dashboardFilter = dashboardFilter;
        if(showAdditionalFinanceData !== undefined)
            args.showAdditionalFinanceData = showAdditionalFinanceData;
        if(defaultVendor !== undefined)
            args.defaultVendor = defaultVendor;
        this.setState(args);
    }

    componentDidMount(){
        const sessionKey = cookies.get('kjd_session');
        const ssoClient = new SsoClient ({sessionKey: sessionKey});

        ssoClient.getUser()
            .then(user => {
                this.setState({user: user});
            })
            .catch(err => {
                if(err.statusCode === 401) {
                    window.location.href = ssoConfig.baseUrl;
                }
            });

        LiteProductsClient.getMarketplaces()
            .then(marketplaces => {
                this.setState({marketplaces: marketplaces});
            })
    }

    render() {
        const sessionKey = cookies.get('kjd_session');
        const {user, marketplaces, userSettings} = this.state;

        if(!sessionKey)
            window.location.href = ssoConfig.baseUrl;

        if(!user)
            return <LoadingSpinner/>;

        const client = new PurchasingClient({sessionKey: cookies.get('kjd_session')});
        const liteProductsClient = new LiteProductsClient({sessionKey: sessionKey});
        const liteTransactionsClient = new LiteTransactionsClient({sessionKey: sessionKey});

        return(
            <AppProvider user={user} marketplaces={marketplaces} userSettings={userSettings} updateUserSettings={args => this.updateUserSettings(args)}>
                <SettingsProvider {...this.state} updateUserSettings={args => this.updateUserSettings(args)}>
                    <Router>
                        <div>
                            <Route path="/" component={NavBar}/>
                            <Route exact path="/" component={() => <Dashboard client={client}/>}/>

                            <Route exact path="/history" component={props => <ProductHistorySearch {...props} client={liteProductsClient}/>}/>
                            <Route exact path="/history/:productId" component={props => <ProductHistoryView {...props} productId={props.match.params.productId}
                                                                                                            client={client} productsClient={liteProductsClient}/>}/>
                            <Route exact path="/history/search/:query" component={props => <ProductHistorySearch {...props} client={liteProductsClient}/>}/>

                            <Route exact path="/search" component={props => <Search {...props} client={client}/>}/>
                            <Route path="/search/:query" component={props => <Search {...props} client={client}/>}/>

                            <Route exact path="/po/view/:poRef" component={props => <PoView {...props} client={client}/>}/>
                            <Route exact path="/po/new" component={props => <NewPage {...props} client={client} productsClient={liteProductsClient}/>}/>
                            <Route exact path="/po/edit/:poRef" component={props => <PoEdit {...props} client={client} productsClient={liteProductsClient}/>}/>

                            <Route exact path="/receiving" component={() => <ReceivingDashboard client={client}/>}/>
                            <Route exact path="/receiving/edit/:poRef" component={props => <ReceivingEdit {...props} client={client}
                                                                                                          transactionsClient={liteTransactionsClient}
                                                                                                          productsClient={liteProductsClient}/>}/>


                        </div>
                    </Router>
                </SettingsProvider>
            </AppProvider>
        )
    }
}

export default App;
