import React, {Component} from 'react';
import { InputGroup, InputGroupAddon, InputGroupText, Input} from 'reactstrap';
import PropTypes from "prop-types";

class CurrencyInput extends Component {
        _renderAddon(){
            if(!this.props.addOn) return;
            return  <InputGroupAddon addonType="append"  style={{ zIndex: 0 }}>
                {this.props.addOn}
            </InputGroupAddon>
        }

        render() {
            const {className, placeholder, id, value, onChange, invalid, style} = this.props;
            return <InputGroup style={style} className={className}>
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input id={id} invalid={invalid} placeholder={placeholder} value={value} onChange={e => onChange(e)} />
                {this._renderAddon()}
            </InputGroup>
    }
}

CurrencyInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
    invalid: PropTypes.bool.isRequired,
    style: PropTypes.object,
    addOn: PropTypes.object
};

export {CurrencyInput};
