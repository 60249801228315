import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {poRefFormatter, genericFormatter, dateFormatter, currencyFormatter, numberFormatter, receivingTotalsFormatter} from "../../../helpers/dataFormatters";
import {ErrorAlert} from "../../atoms/ErrorAlert";

const DEFAULT_OPTIONS = {
    defaultSortName: 'ref',
    defaultSortOrder: 'desc',
};

class PurchaseOrdersTable extends React.Component {
    render() {
        const { error, isLoaded, purchaseOrders, receivingContext } = this.props;

        let additionalProps = {};
        if(this.props.pagination && purchaseOrders.length > 10)
            additionalProps.pagination = true;

        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return (
            <BootstrapTable data={purchaseOrders} options={DEFAULT_OPTIONS} striped hover {...additionalProps}  version='4'>
                <TableHeaderColumn isKey={true} dataField='ref' dataSort={ true } width={"10%"} dataFormat={(cell) => poRefFormatter(cell, receivingContext)}>ID</TableHeaderColumn>
                <TableHeaderColumn dataField='' dataFormat={(cell, obj) => genericFormatter(obj, "vendor.name")}>Vendor</TableHeaderColumn>
                <TableHeaderColumn dataField='orderNumber' width={"23%"}>Order Number</TableHeaderColumn>
                <TableHeaderColumn dataField='purchaseDate' dataFormat={dateFormatter}>Date</TableHeaderColumn>
                <TableHeaderColumn dataField='' dataFormat={(cell, obj) => genericFormatter(obj, "totals.unitCount", numberFormatter)}>Units</TableHeaderColumn>
                <TableHeaderColumn dataField='' dataFormat={(cell, obj) => genericFormatter(obj, "totals.grand", currencyFormatter)}>Total</TableHeaderColumn>
                <TableHeaderColumn dataField='' width={"18%"} dataFormat={(cell, obj) => receivingTotalsFormatter(obj)}>Received</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

PurchaseOrdersTable.propTypes = {
    purchaseOrders: PropTypes.array.isRequired,
    error: PropTypes.object,
    isLoaded: PropTypes.bool.isRequired,
    pagination: PropTypes.bool,
    receivingContext: PropTypes.bool
};

export {PurchaseOrdersTable};