import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import { dateFormatter} from "../../../helpers/dataFormatters";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class PoCommentsTable extends React.Component {
    render() {
        const { comments } = this.props;
        return (
            <BootstrapTable data={comments} striped hover version='4'>
                <TableHeaderColumn isKey={true} dataField='id' width={"0%"}>ID</TableHeaderColumn>
                <TableHeaderColumn dataField='createdDate' dataFormat={dateFormatter}>Date</TableHeaderColumn>
                <TableHeaderColumn dataField='comment' width={"70%"}>Comment</TableHeaderColumn>
                <TableHeaderColumn dataField='showOnReceiving'
                                   dataFormat={(cell, row) => cell ? <FontAwesomeIcon className="mr-2" icon={faCheck} color="green" /> : ""
                                   }
                                   width={"10%"}>Receiving</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

PoCommentsTable.propTypes = {
    comments: PropTypes.array.isRequired,
};

export {PoCommentsTable};