import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Badge, Button, CustomInput, Form} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBackspace, faCheck } from '@fortawesome/free-solid-svg-icons'
import ReceivingContext from "../../context/ReceivingContext";

class VisualConfirmationEditControl extends Component {
    _renderConfirmedDate(){
        const {receivingItem } = this.props;
        const confirmedDate = receivingItem.visualInspection.confirmedDate;
        if(!confirmedDate || confirmedDate.getTime() === 0)
            return "";
       return ` - ${confirmedDate.toLocaleDateString()}`;
    }

    _renderConfirmed({addVoidReceivingAction}){
        const {productId, receivingItem} = this.props;
       return <div className="form-inline">
            <FontAwesomeIcon className="mr-2" icon={faCheck} color="green" />
            <Badge className="mr-2" color="primary">Visually Confirmed{this._renderConfirmedDate()}</Badge>
            <Button outline color="danger" size="sm" className="ml-auto" onClick={() => addVoidReceivingAction({productId: productId, shipmentId: receivingItem.shipmentId})}>Void</Button>
       </div>
    }

    _renderEdit({isVoided, setVisualConfirmation, receivingActions, setManualCount}){
        const {productId, receivingItem} = this.props;
        const receivingAction = this.findReceivingAction({receivingActions});

        return <Form inline>
            {isVoided ?  <FontAwesomeIcon className="mr-2" icon={faBackspace} color="grey" /> : ""}

            <CustomInput checked={!!receivingAction} className="mr-2" type="checkbox" id={`received-cb-${(Math.random()*1000).toFixed(0)}`} label="Visually Confirm"
                         onClick={e => setVisualConfirmation({productId: productId, shipmentId: receivingItem.shipmentId, isConfirmed: e.target.checked})}/>

            <Button outline color="info" size="sm" className="ml-auto" onClick={e => setManualCount({productId: productId, shipmentId: receivingItem.shipmentId})}>Manual Count</Button>
        </Form>
    }

    findReceivingAction({receivingActions}){
        const {productId, receivingItem} = this.props;
        return receivingActions.find(voidAction => voidAction.productId === productId
            && voidAction.shipmentId === receivingItem.shipmentId);
    }

    findVoidAction({voidReceivingActions}){
        const {productId, receivingItem} = this.props;
        return voidReceivingActions.find(voidAction => voidAction.productId === productId
            && voidAction.shipmentId === receivingItem.shipmentId);
    }

    isVoided({voidReceivingActions}){
        const voidAction = this.findVoidAction({voidReceivingActions});
        return !!voidAction;
    }

    render() {
        return <ReceivingContext.Consumer>
            {({receivingActions, voidReceivingActions, setManualCount,
                  addVoidReceivingAction, setVisualConfirmation}) => {
                const {receivingItem} = this.props;

                let isConfirmed = !receivingItem.isOpen;
                const isVoided = this.isVoided({voidReceivingActions});

                if (isConfirmed && !isVoided) return this._renderConfirmed({addVoidReceivingAction});
                return this._renderEdit({isVoided, receivingActions, setVisualConfirmation, setManualCount});
            }}
        </ReceivingContext.Consumer>
    }
}

VisualConfirmationEditControl.propTypes = {
    receivingItem: PropTypes.object.isRequired,
    productId: PropTypes.number.isRequired
};

export {VisualConfirmationEditControl};
