import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {genericFormatter, dateFormatter, checkinFormatter} from "../../../helpers/dataFormatters"
import {ErrorAlert} from "../../atoms/ErrorAlert";

const options = {
    sizePerPage: 5,
    hideSizePerPage: false,
    sizePerPageList: [ 5, 10, 20 ],
};

class ShipmentsTable extends React.Component {
    render() {
        const { error, isLoaded, shipments } = this.props;
        const additionalProps = this.props.additionalProps || {};

        if(shipments.length > 5)
            additionalProps.pagination = true;

        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return (
            <BootstrapTable data={shipments} options={options} {...additionalProps} striped hover version='4'>
                <TableHeaderColumn dataField='id' isKey={true} width={"25%"} dataFormat={(cell, obj) => genericFormatter(obj, "trackingNumber")}>Tracking</TableHeaderColumn>
                <TableHeaderColumn dataField='' dataFormat={(cell, obj) => genericFormatter(obj, "tracking.location")}>Location</TableHeaderColumn>
                <TableHeaderColumn dataField='' width={"20%"} dataFormat={(cell, obj) => genericFormatter(obj, "tracking.status")}>Status</TableHeaderColumn>
                <TableHeaderColumn dataField='shippedDate'
                                  dataFormat={(cell, obj) => genericFormatter(obj, "tracking.shippedDate", dateFormatter)}>Shipped</TableHeaderColumn>
                <TableHeaderColumn dataField=''
                                   dataFormat={(cell, obj) => genericFormatter(obj, "tracking.estimatedDelivery", dateFormatter)}>Est Delivery</TableHeaderColumn>
                <TableHeaderColumn dataField='checkIn'
                                   dataFormat={(cell, obj) => checkinFormatter(obj, true)}>Checked In</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

ShipmentsTable.propTypes = {
    shipments: PropTypes.array.isRequired,
    error: PropTypes.object,
    isLoaded: PropTypes.bool.isRequired
};

export {ShipmentsTable};