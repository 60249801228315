import React, {Component} from 'react';
import PropTypes from "prop-types";
import {checkinFormatter} from "../../helpers/dataFormatters";
import {Badge, Button, CustomInput} from "reactstrap";
import {VisualConfirmationEditControl} from "../molecules/VisualConfirmationEditControl";
import {ManualCountEditControl} from "../molecules/ManualCountEditControl";
import ReceivingContext from "../../context/ReceivingContext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faMinus} from "@fortawesome/free-solid-svg-icons";

class ReserveTransactionRow extends Component {
    state = {
        product: null,
        productError: null,
        transaction: null,
        transactionError: null,
    };

    _renderApprovalAction({transactionsClient, transaction}){
        if(!transaction)
            return;
        //A transaction without an approval object is to be considered approved (legacy)
        if(!transaction.hasOwnProperty("approval") || transaction.approval.approved)
            return this._renderApproval({transaction, approved: true});
        if(transaction.hasOwnProperty("approval") && !transaction.approval.userId)
            return this._renderPending({transaction, transactionsClient});
        return this._renderApproval({transaction, approved: false});
    }

    _renderApproval({transaction, approved}){
        const approvalDate = transaction.approval ? transaction.approval.date : transaction.dateTime;
        return <div className="form-inline">
            {approved ? <FontAwesomeIcon className="mr-2" icon={faCheck} color="green" /> : <FontAwesomeIcon className="mr-2" icon={faMinus} color="grey" />}
            {approved && <Badge className="mr-2" color="primary">Reserve Processed{` - ${ new Date(approvalDate).toLocaleDateString()}`}</Badge>}
            {!approved && <Badge className="mr-2" color="secondary">Reserve Declined{` - ${ new Date(approvalDate).toLocaleDateString()}`}</Badge>}
        </div>
    }

    _renderPending({transaction, transactionsClient}){
        return <div className="form-inline">
            <Badge className="mr-2" color="warning">Pending</Badge>
            <Button outline color="info" size="sm" className="ml-auto" onClick={e => this._markApproved({transactionsClient, transaction})}>Moved to Reserve</Button>
            <Button outline color="danger" size="sm" className="ml-auto" onClick={e => this._markDeclined({transactionsClient, transaction})}>Decline</Button>
        </div>
    }

    _markApproved({transactionsClient, transaction}){
        transactionsClient.markApprovedById(transaction.ref)
            .then(transaction => this.setState({transaction}))
            .catch(transactionError => this.setState({transactionError}));
    }

    _markDeclined({transactionsClient, transaction}){
        transactionsClient.markDeclineddById(transaction.ref)
            .then(transaction => this.setState({transaction}))
            .catch(transactionError => this.setState({transactionError}));
    }

    getProduct(productsClient){
        productsClient.getProductById(this.props.poItem.product.id)
            .then(product => this.setState({product}))
            .catch(productError => this.setState({productError}));
    }

    getTransaction(transactionsClient){
        transactionsClient.getTransactionById(this.props.receivingItem.reserveAddTransactionId)
            .then(transaction => this.setState({transaction}))
            .catch(transactionError => this.setState({transactionError}));
    }

    render() {
        const {receivingItem, poItem} = this.props;
        const {productError, product, transaction, transactionError} = this.state;
       return <ReceivingContext.Consumer>
        {({receivingActions, productsClient, transactionsClient}) => {
            if(!product && !productError)
                this.getProduct(productsClient);
            if(!transaction && !transactionError)
                this.getTransaction(transactionsClient);
               return <tr>
                    <th scope="row">K{poItem.product.id}</th>
                    <td>{product && product.key.modelNumber}</td>
                    <td>{poItem.product.name}</td>
                    <td className="text-center">{receivingItem.reserveAddTransactionId}</td>
                    <td className="text-center">{transaction && transaction.itemCount}</td>
                    <td>{this._renderApprovalAction({transaction, transactionsClient })}</td>
                </tr>}}
       </ReceivingContext.Consumer>
    }
}

ReserveTransactionRow.propTypes = {
    receivingItem: PropTypes.object.isRequired,
    poItem: PropTypes.object.isRequired,
};

export {ReserveTransactionRow};
