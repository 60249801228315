//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {ReceivingCard} from "../../atoms/ReceivingCard";
import {Badge, Button, Col, Row} from "reactstrap";
import {ReceivingItemsTable} from "../../molecules/tables/ReceivingItemsTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import ReceivingContext from "../../../context/ReceivingContext";
import {ReserveTransactionsTable} from "../../molecules/tables/ReserveTransactionsTable";

class EditReserveTransactionsCard extends React.Component {

    _getReserveTransactionCount(){
        const {po} = this.props;
        return this._getPoItemsWithReserveTransactionsOnBaseReceivingItem().length
           + po.shipments.map(shipment => this._getPoItemsWithReserveTransactionsForShipmentId(shipment.id)).flat().length
    }

    _getPoItemsWithReserveTransactionsOnBaseReceivingItem(){
        const {po} = this.props;
        return po.items.filter(item => item.baseReceivingItem.reserveAddTransactionId);
    }

    _getPoItemsWithReserveTransactionsForShipmentId(shipmentId){
        const {po} = this.props;

        return po.items.filter(item => item.receivingItems.find(receivingItem => receivingItem.shipmentId === shipmentId
            && receivingItem.reserveAddTransactionId));
    }

    _renderBaseReceivingItemsTable(){
        const poItemsWithReserveTransactionsOnBaseReceivingItem = this._getPoItemsWithReserveTransactionsOnBaseReceivingItem();
        return <ReserveTransactionsTable poItems={poItemsWithReserveTransactionsOnBaseReceivingItem} title="Not Allocated to Shipment"/>;
    }

    _renderReceivingItemsForShipment(shipmentId){
        const {po} = this.props;
        const shipment = po.getShipment({shipmentId: shipmentId});

        const poItemsReceivingItemsForShipment = this._getPoItemsWithReserveTransactionsForShipmentId();
        return <ReserveTransactionsTable poItems={poItemsReceivingItemsForShipment} shipmentId={shipmentId} title={`Allocated to Shipment - ${shipment.trackingNumber}`}/>;
    }

    playAlertSound(){
        if(!this.props.enableReserveAlertSound || this._getReserveTransactionCount() === 0)
            return;
        let audio = new Audio('https://soundbible.com/grab.php?id=2157&type=mp3');
        audio.type = 'audio/mp3';
        this.props.disableReserveAlertSound();
        return audio.play(); //Promise
    }

    render() {
        const {po} = this.props;
        return <ReceivingContext.Consumer>
            {({markEligibleItemsVisuallyConfirmed, saveReceiving, voidReceivingActions}) => {
                this.playAlertSound();
                return (
                    <ReceivingCard badgeText="Step 3" headerText="Reserve Transactions">
                        <h5 className="text-center pb-1">Reserve Transaction Count: {this._getReserveTransactionCount()}</h5>
                        {this._renderBaseReceivingItemsTable()}
                        {po.shipments.map(shipment => this._renderReceivingItemsForShipment(shipment.id))}
                    </ReceivingCard>
                    );
            }}
        </ReceivingContext.Consumer>
    }
}

EditReserveTransactionsCard.propTypes = {
    po: PropTypes.object.isRequired,
    enableReserveAlertSound: PropTypes.bool,
    disableReserveAlertSound: PropTypes.func.isRequired
};

export {EditReserveTransactionsCard};