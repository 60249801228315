import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {LoadingSpinner} from '../atoms/LoadingSpinner'
import ReceivingEditContent from "../organisms/receiving/ReceivingEditContent";
import {NavLink} from "reactstrap";
import {config} from "../../vendor/client/config/config";

class ReceivingEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            po: null,
            isLoaded: false,
            error: null
        }
    }

    componentDidMount() {
        this._loadPo();
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.poRef !== this.poRef)
            this._loadPo();
    }

    get poRef(){
        return this.props.match.params.poRef;
    }

    //TODO This code is duplicated as well as the state management. Consider refactoring
    _loadPo(){
        const {client} = this.props;
        client.purchaseOrders.getPurchaseOrderByRef(this.poRef)
            .then(po => {
                this.setState({
                    isLoaded: true,
                    po: po
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                });
            });
    }

    setPo(newPo){
        this.setState({
            po: newPo,
        });
    }

    _renderBody(){
        const {po, error, isLoaded} = this.state;
        const {client, productsClient, transactionsClient} = this.props;
        if (error)
            return <div>Error: {this.props.error ? this.props.error.message : ""}</div>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return <ReceivingEditContent client={client}
                                     setPo={(newPo) => this.setPo(newPo)}
                                     po={po} productsClient={productsClient}
                                     transactionsClient={transactionsClient}
        />

    }

    render() {
        const {client} = this.props;

        return (
            <div className="ml-3 mr-3">
                <h1>Purchase Order {this.poRef} Receiving</h1>
                <ToolBar client={client}
                         receivingContext={true}
                         extraComponent={<NavLink
                            className="d-inline"
                            target="_blank"
                            href={`${config.baseUrl}reports/inbound.pdf`}>Inbound Report</NavLink>}/>
                {this._renderBody()}
            </div>
        )
    }
}

ReceivingEdit.propTypes = {
    client: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired
};

export {ReceivingEdit};