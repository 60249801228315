import React from 'react';
import PropTypes from 'prop-types';
import { Table, Row, Col, Button, Badge } from 'reactstrap';
import {ShipmentReceivingRow} from "../../atoms/ShipmentReceivingRow";
import ReceivingContext from "../../../context/ReceivingContext";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons'

class ReceivingShipmentsTable extends React.Component {
      markAllShipmentsReceived(setCheckinForShipmentId){
          const {shipments} = this.props;
          shipments.forEach(shipment => {
              if(shipment.isCheckedIn) return;
              setCheckinForShipmentId({shipmentId: shipment.id, isCheckedIn: true});
          })
      }

      renderButtonsRow({setCheckinForShipmentId, saveShipmentsOnly}){
          const {shipments} = this.props;

          const toCheckinCount = shipments.filter(shipment => !shipment.isCheckedIn);
          if(toCheckinCount.length === 0)
              return <h4 className="text-center"><Badge color="light"><FontAwesomeIcon className="mr-2" icon={faCheck} color="green" /> All Shipments Checked In</Badge></h4>;

          return <Row className="pt-3">
              <Col className="text-center">
                  <Button outline className="mr-2" onClick={() => this.markAllShipmentsReceived(setCheckinForShipmentId)}>Mark All Received</Button>
                  <Button color="primary" onClick={e => saveShipmentsOnly()}>Save Shipments Only</Button>
              </Col>
          </Row>
      }

      render() {
        const {shipments} = this.props;

        if(shipments.length === 0)
            return <h5 className="text-center"><Badge color="warning">No Shipments for this PO</Badge></h5>;

        return <ReceivingContext.Consumer>
            {({shipmentIdsToCheckin, setCheckinForShipmentId, saveShipmentsOnly}) => (
                <div>
                    <Table striped className="narrow-table">
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Tracking Number</th>
                            <th>Location</th>
                            <th>Status</th>
                            <th>Est Delivery</th>
                            <th>Checked In</th>
                        </tr>
                        </thead>
                        <tbody>
                        {shipments.map((shipment, index) => <ShipmentReceivingRow isChecked={shipmentIdsToCheckin.includes(shipment.id)} setCheckinForShipmentId={setCheckinForShipmentId}
                                                                                  key={shipment.id} shipment={shipment} rowNum={index + 1}/> )}
                        </tbody>
                    </Table>
                    {this.renderButtonsRow({setCheckinForShipmentId, saveShipmentsOnly})}
                </div>
            )}
        </ReceivingContext.Consumer>
    }
}

ReceivingShipmentsTable.propTypes = {
    shipments: PropTypes.array.isRequired,
};

export {ReceivingShipmentsTable};