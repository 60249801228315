import React from 'react';
import PropTypes from 'prop-types';
import {Card, CardBody, CardHeader, Col, Row, Table, CustomInput} from 'reactstrap';
import {LoadingSpinner} from "../atoms/LoadingSpinner";
import SettingsContext from "../../context/SettingsContext";
import AppContext from "../../context/AppContext";
import {percentFormatter, preciseCurrencyFormatter} from "../../helpers/dataFormatters"

class ProductFinanceDetail extends React.Component {
    _renderMarketplaceCols({marketplaces, showAdditionalFinanceData}) {
        const {product} = this.props;
        if (!product) return;

        let colsWithSortOrder = Object.keys(product.marketplaces).map(key => {
            const marketplaceNameAndDetails = marketplaces && marketplaces.hasOwnProperty(key) ? marketplaces[key] : null;
            const marketplace = product.marketplaces[key];
            if (!marketplace.finance) return {jsx: "", displayOrder: -1};
            return {
                displayOrder: marketplaceNameAndDetails ? marketplaceNameAndDetails.displayOrder : 0,
                jsx: <Col md={2}>
                    <Table bordered className="finance-table">
                        <thead>
                        <tr>
                            <th colSpan={2} className="text-center py-1 borderless">
                                {marketplaceNameAndDetails ? marketplaceNameAndDetails.name : key}
                            </th>
                        </tr>
                        </thead>
                        {this._renderMarketplaceRows({marketplace, showAdditionalFinanceData})}
                    </Table>
                </Col>
            }
        });
        colsWithSortOrder = colsWithSortOrder.sort((a, b) => a.displayOrder - b.displayOrder);
        return colsWithSortOrder.map(item => item.jsx);
    }

    _renderSalesMarketplaceCols({marketplaces, showAdditionalFinanceData}) {
        const {product} = this.props;
        if (!product) return;

        let colsWithSortOrder = Object.keys(product.marketplaces).map(key => {
            const marketplaceNameAndDetails = marketplaces && marketplaces.hasOwnProperty(key) ? marketplaces[key] : null;
            const marketplace = product.marketplaces[key];
            if (!marketplace.finance) return {jsx: "", displayOrder: -1};
            return {
                displayOrder: marketplaceNameAndDetails ? marketplaceNameAndDetails.displayOrder : 0,
                jsx: <Col md={2}>
                    {this._renderSalesTable({marketplace, showAdditionalFinanceData})}
                </Col>
            }
        });
        colsWithSortOrder = colsWithSortOrder.sort((a, b) => a.displayOrder - b.displayOrder);
        return colsWithSortOrder.map(item => item.jsx);
    }

    _renderMarketplaceRows({marketplace, showAdditionalFinanceData}) {
        const price = marketplace.finance.price ? marketplace.finance.price.market : 0;
        const profit = marketplace.finance.profit ? marketplace.finance.profit.base : 0;
        const roi = marketplace.finance.profit ? marketplace.finance.profit.roi : 0;

        return (
            <tbody>
            <tr>
                <td>Price</td>
                <td className="font-weight-bold small">{price} {marketplace.finance.marketCurrencyCode}</td>
            </tr>
            <tr>
                <td>Profit</td>
                <td className="profit-format">{preciseCurrencyFormatter(profit)}</td>
            </tr>
            <tr>
                <td>ROI</td>
                <td className="font-weight-bold">{percentFormatter(roi)}</td>
            </tr>
            {this._renderInventoryRows({marketplace, showAdditionalFinanceData})}
            </tbody>
        )
    }

    _renderInventoryRows({marketplace, showAdditionalFinanceData}) {
        const showAll = showAdditionalFinanceData;
        if (!showAll) return;

        let fufillable, avaliable, stockLevels;

        if (marketplace.inventory) {
            fufillable = marketplace.inventory.fufillable;
            avaliable = marketplace.inventory.detail.total.inboundAndAvailable;
            stockLevels = marketplace.stockLevels.inStockPercentages;
        }


        let rows = [];
        rows.push(
            <tr className="border-top-thick">
                <td className="small">Fufillable</td>
                <td className="font-weight-bold">{fufillable ? fufillable : 0}</td>
            </tr>);
        rows.push(
            <tr>
                <td className="small" width="20%">Available</td>
                <td className="font-weight-bold">{avaliable ? avaliable : 0}</td>
            </tr>);
        rows.push(
            <tr className="border-top-thick text-center">
                <td className="small" colSpan={2}>
                    15 Day Stock: <span
                    className="font-weight-bold"> {stockLevels ? percentFormatter(stockLevels[15]) : "N/A"} </span>
                    <br/>
                    30 Day Stock: <span
                    className="font-weight-bold">{stockLevels ? percentFormatter(stockLevels[30]) : "N/A"}</span>
                </td>
            </tr>);
        return rows;
    }

    _renderSalesTable({marketplace, showAdditionalFinanceData}) {
        const showAll = showAdditionalFinanceData;
        if (!showAll) return;
        return (
            <div className="">
                <Table bordered className="finance-table to-bottom">
                    <thead>
                    <tr>
                        <th colSpan={2} className="text-center py-1 borderless">
                            Sales
                        </th>
                    </tr>
                    </thead>
                    {this._renderSalesRows({marketplace, showAdditionalFinanceData})}
                </Table>
            </div>
        )
    }

    _renderSalesRows({marketplace, showAdditionalFinanceData}) {
        return (
            <tbody>
            <tr>
                <td>Today</td>
                <td className="small">{ProductFinanceDetail.renderSalesString({days: 0, marketplace: marketplace})}</td>
            </tr>
            <tr>
                <td>15 Day</td>
                <td className="small">{ProductFinanceDetail.renderSalesString({
                    days: 15,
                    marketplace: marketplace
                })}</td>
            </tr>
            <tr>
                <td>30 Day</td>
                <td className="small">{ProductFinanceDetail.renderSalesString({
                    days: 30,
                    marketplace: marketplace
                })}</td>
            </tr>
            </tbody>
        )
    }

    //TODO Must move somewhere else
    static renderSalesString({days, marketplace}) {
        if (!marketplace.sales || !marketplace.sales[days])
            return `0 for $0.00 ($0.00 Profit) 0 Orders`;
        const sales = marketplace.sales[days];
        return `${sales.units} for $${sales.sales} ($${sales.profit} Profit) ${sales.orders} Orders`;
    }

    render() {
        const {product} = this.props;

        if (!product) return "";

        const finance = product.finance;
        //TODO Tooltip on Total Unit Costs with the other costs
        return <AppContext.Consumer>
            {({marketplaces, user}) => {
                if (!user.isMemberOfGroup("IM_FINANCES")) return "";

                return <SettingsContext.Consumer>
                    {({showAdditionalFinanceData, updateUserSettings}) => {

                        return <Card>
                            <CardHeader className="pt-2 pb-2 form-inline">
                                <h4>Finance and Inventory</h4>
                                <CustomInput className="ml-auto" type="checkbox" id="showAll" label="Show All Data"
                                             checked={showAdditionalFinanceData}
                                             onClick={e => updateUserSettings({showAdditionalFinanceData: e.target.checked})}/>
                            </CardHeader>
                            <CardBody>
                                <Row className="justify-content-md-center pb-2">
                                    <Col lg={2}>
                                        <h5 className="text-center">Unit Price</h5>
                                        <h6 className="text-center">{preciseCurrencyFormatter(finance.purchasing.mostRecentUnitPrice)}</h6>
                                    </Col>
                                    <Col md="auto">
                                        <h5 className="text-center">Unit Cost</h5>
                                        <h6 className="text-center">{preciseCurrencyFormatter(finance.purchasing.mostRecentUnitCost)}</h6>
                                    </Col>
                                    <Col lg={2}>
                                        <h5 className="text-center">Total Unit Cost</h5>
                                        <h6 className="text-center">{preciseCurrencyFormatter(finance.combinedTotalCost)}</h6>
                                    </Col>
                                </Row>
                                <Row className="justify-content-md-center pb-2">
                                    {this._renderMarketplaceCols({marketplaces, showAdditionalFinanceData})}
                                </Row>
                                <Row className="justify-content-md-center pb-2">
                                    {this._renderSalesMarketplaceCols({marketplaces, showAdditionalFinanceData})}
                                </Row>
                            </CardBody>
                        </Card>
                    }}
                </SettingsContext.Consumer>
            }}
        </AppContext.Consumer>
    }
}

ProductFinanceDetail.propTypes = {
    product: PropTypes.object.isRequired,
};

export {ProductFinanceDetail};