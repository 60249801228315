import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import {PoDetailRow} from '../atoms/PoDetailRow';
import moment from 'moment';
import {SingleDatePicker} from "react-dates";
import {OPEN_UP} from "react-dates/esm/constants";
import {Input} from "reactstrap";

//TODO Use Template Pattern
class PaymentDetails extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            dueDateFocused: false,
        };
    }

    _handleDueDateChanged(date){
        this.props.patchPo(['payment', 'dueDate'], date ? date.toDate() : null);
    }

    _handleTermsChange(e){
        this.props.patchPo(['payment', 'terms'], e.target.value);
    }

    _handlePaidInFullChange(e){
        this.props.patchPo(['payment', 'paidInFull'], e.target.checked);
    }

    _renderEditMode(){
        const { po } = this.props;
        return (
            <Table bordered>
                <tbody>
                <PoDetailRow label="Paid in Full" data={ <Input type="checkbox" id="terms"
                                                         className="ml-2"
                                                         onChange={e => this._handlePaidInFullChange(e)}
                                                         checked={po.payment.paidInFull} />} />
                <PoDetailRow label="Due Date" data={<SingleDatePicker
                    id="paymentDueDate"
                    date={po.payment.dueDate ? moment(po.payment.dueDate) : null}
                    onDateChange={date => this._handleDueDateChanged(date)}
                    focused={this.state.dueDateFocused}
                    onFocusChange={({ focused }) => this.setState({ dueDateFocused: focused })}
                    isOutsideRange={() => false}
                    openDirection={OPEN_UP}
                />} />
                <PoDetailRow label="Terms" data={ <Input type="textarea"
                                                         onChange={e => this._handleTermsChange(e)}
                                                         value={po.payment.terms ? po.payment.terms  : undefined} />} />
                </tbody>
            </Table>)
    }

    _renderViewMode(){
        const { po } = this.props;
        return (
            <Table bordered>
                <tbody>
                <PoDetailRow label="Due Date" data={po.payment.dueDate ? new Date(po.payment.dueDate).toLocaleDateString() : "N/A"} />
                <PoDetailRow label="Terms" data={po.payment.terms} />
                </tbody>
            </Table>)
    }

    render() {
        if(typeof this.props.patchPo !== "function")
            return this._renderViewMode();
        return this._renderEditMode();
    }
}

PaymentDetails.propTypes = {
    po: PropTypes.object.isRequired,
    patchPo: PropTypes.func //If this is provided then Payment Details in "Edit Mode"
};

export {PaymentDetails};