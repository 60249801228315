import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import { PurchaseOrderItem } from '../../obj/PurchaseOrderItem';
import { PurchaseOrder } from '../../obj/PurchaseOrder';

class PurchaseOrders{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    getPurchaseOrders({limit=10000, dateSort=1, filter="all"}={}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.purchaseorders.getAll.replace(":limit", limit).replace(":dateSort", dateSort).replace(':filter', filter),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                return results.map(po => new PurchaseOrder(po));
            })
    }

    searchPurchaseOrders(query){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.purchaseorders.search.replace(':query', query),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                return results.map(po => new PurchaseOrder(po));
            })
    }

    getPurchaseOrderByRef(ref){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.purchaseorders.get.replace(':poRef', ref),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }

    getPurchaseOrderItem({poRef, productId}){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.purchaseorders.getPoItem.replace(':poRef', poRef).replace(':productId', productId),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(poItem => {
                return new PurchaseOrderItem(poItem, {});
            })
    }

    createPurchaseOrder(po){
        let options = {
            method: 'POST',
            uri: config.baseUrl + routes.purchaseorders.post,
            body: po,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }

    updatePurchaseOrder(po){
        let options = {
            method: 'PUT',
            uri: config.baseUrl + routes.purchaseorders.put.replace(':poRef', po.ref),
            body: po,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }
}


export { PurchaseOrders };
