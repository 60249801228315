//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import { Form, FormGroup, Button, Label, Input } from 'reactstrap';
import {CurrencyInput} from "../../atoms/CurrencyInput";
import {currencyFormatter} from "../../../helpers/dataFormatters";
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {PurchaseOrderItem} from "../../../vendor/obj/PurchaseOrderItem";
import {ProductSearchModal} from "../functional/ProductSearchModal";


class AddPoItemBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            error: null,
            isLoading: false,
            product: null,
            quantity: 0,
            unitPrice: 0,
            unitPriceInvalid: false,
            searchModalOpen: false
        };
    }

    _handleSearchChange(e){
        const text = e.target.value;
        this._searchForSingleProduct(text);
    }

    _handleSearchKeyPress(e){
        if (e.key === "Enter")
            this.setState({searchModalOpen: true});
    }

    //TODO Refactor (See PoDetailsEdit)
    _handleUnitPriceChange(e){
        const value = e.target.value;

        const parsed = Number(value);

        if(isNaN(parsed) || parsed < 0 || (value.length !== 0 && value[value.length-1] === ".") || value === "") {
            this.setState({unitPriceInvalid: true, unitPrice: value, unitPriceString: value});
            return;
        }
        this.setState({unitPriceInvalid: false, unitPrice: parsed, unitPriceString: value});
    }

    _searchForSingleProduct(searchQuery){
        const {productsClient} = this.props;
        this.setState({searchText: searchQuery, isLoading: true, error: null});

        productsClient.searchForProduct(searchQuery)
            .then(product => {
                const unitPrice = product.finance.purchasing.mostRecentUnitPrice;
                const unitPriceValid = !!unitPrice;
                this.setState({isLoading: false, product: product, unitPrice: unitPrice, unitPriceString: unitPrice,
                    unitPriceValid: unitPriceValid});
            })
            .catch(e => {
                if(e.statusCode === 404){
                    this.setState({isLoading: false, product: null});
                    return;
                }
                this.setState({error: e, isLoading: false});
            })
    }

    _renderProduct(){
        const {isLoading, product} = this.state;
        if(isLoading)
            return <LoadingSpinner/>;
        return product ? product.detail.name : "No Product Selected";
    }

    _addItem(){
        const { product, quantity, unitPrice, unitPriceInvalid } = this.state;
        const {patchPo, po} = this.props;
        if(!product || quantity <= 0 || unitPriceInvalid ) //TODO refactor to isValid
            return; //TODO Play error sound and show message

        //Items with quantity 0 will not show. Update these items with a set
        const currentItem = po.getPoItemForProductId(product.key.id);
        const action =  currentItem ? "setItem" : "addItem";

        let itemArgs ;
        if(currentItem) {
            itemArgs = {...currentItem};
            itemArgs.quantity = quantity;
            itemArgs.unitPrice = unitPrice;
        }
        else
            itemArgs = {product: {id: product.key.id, name: product.detail.name}, quantity: quantity, unitPrice: unitPrice};
        const item = new PurchaseOrderItem(itemArgs, {poRef: po});

        patchPo(action, item);
        this._resetState();
        document.getElementById('search').focus();
    }

    _resetState(){
        this.setState({
            searchText: "",
            error: null,
            isLoading: false,
            product: null,
            quantity: 0,
            unitPrice: 0,
            unitPriceString: "0",
            unitPriceInvalid: false,
            searchModalOpen: false
        })
    }

    _setProductId(productId){
        this.setState({searchModalOpen: false});
        this._searchForSingleProduct(`K${productId}`);
    }

    _renderSearchModal(){
        const { searchText, searchModalOpen } = this.state;
        const {productsClient} = this.props;
        const cancel = () => this.setState({searchModalOpen: false});

        return searchModalOpen ? <ProductSearchModal onProductSelected={(productId) => this._setProductId(productId)}
                                                     onCancel={() => cancel()} productsClient={productsClient} query={searchText}/> : ""
    }

    render() {
        const { searchText, quantity, unitPrice, unitPriceString, unitPriceInvalid } = this.state;
        const {className} = this.props;
        const total = currencyFormatter(quantity * unitPrice);

        return (
                <Form inline className={className}>
                    {this._renderSearchModal()}
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="search" className="mr-sm-2">Product Search</Label>
                        <Input name="search" ref={(input) => { this.searchInput = input; }}
                               id="search" placeholder="K36, 3333, etc" value={searchText}
                               onKeyPress={e => this._handleSearchKeyPress(e)}
                               onChange={e => this._handleSearchChange(e)} />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <span className="mr-sm-2 small text-center" style={{'minWidth': '10em', 'maxWidth': '15em'}}>{this._renderProduct()}</span>
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label for="quantity" className="mr-sm-2">Quantity</Label>
                        <Input type="number" min={0} name="quantity" id="quantity" style={{'maxWidth': '6em'}} value={quantity} onChange={e => this.setState({quantity: Number(e.target.value)})} />
                    </FormGroup>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0">
                        <Label className="mr-sm-2">Unit Price</Label>
                        <CurrencyInput invalid={unitPriceInvalid} style={{'maxWidth': '7em'}} value={unitPriceString} onChange={e => this._handleUnitPriceChange(e)}/>
                    </FormGroup>
                    <Button color="primary" onClick={e => this._addItem()}>Add</Button>
                    <FormGroup className="mb-2 mr-sm-2 mb-sm-0 ml-2">
                        <Label for="quantity" className="mr-sm-2 font-weight-bold">Total <span className="font-weight-bold ml-1">{total}</span></Label>
                    </FormGroup>
                </Form>
            );
    }
}

AddPoItemBar.propTypes = {
    productsClient: PropTypes.object.isRequired,
    className: PropTypes.string,
    patchPo: PropTypes.func.isRequired,
    po: PropTypes.object.isRequired
};

export {AddPoItemBar}