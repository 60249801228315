import { ErrorWithStatusCode } from '../errors/ErrorWithStatusCode';
import { VisualInspection } from './VisualInspection';
import { ManualCount } from './ManualCount';
import { ReceivingAction } from './actions/ReceivingAction';

class ReceivingItem{
    constructor({inventoryAddTransactionId=null, inventoryAddTransactionIdHistory=[], shipmentId=null,
                    reserveAddTransactionId=null, reserveAddTransactionIdHistory=[],
                    expectedQuantity,
                    manualCount=null,
                    visualInspection=null, history=[]},
                poItemRef){

        if(manualCount && visualInspection)
            throw new Error("manualCount and visualInspection cannot both be provided");
        if(expectedQuantity == null || typeof expectedQuantity !== "number" || isNaN(expectedQuantity) || expectedQuantity < 0)
            throw new Error("expectedQty must be a valid number");

        //There can only be one valid (non-void) INVENTORY-ADD-FRM-INBOUND transaction for a Receiving Item at a given time.
        //This transaction reflects the current state of the count of units received up to the quantity ordered.
        //If more items were received than ordered it is adjusted through a discrepancy transaction tracked in manualCount
        this.inventoryAddTransactionId = inventoryAddTransactionId;
        this.inventoryAddTransactionIdHistory = inventoryAddTransactionIdHistory;

        this.reserveAddTransactionId = reserveAddTransactionId;
        this.reserveAddTransactionIdHistory = reserveAddTransactionIdHistory;

        this.shipmentId = shipmentId;

        this.expectedQuantity = expectedQuantity;

        //Reference only
        this._poItemRef = poItemRef;

        if(manualCount)
            this.manualCount = new ManualCount(manualCount, this);
        else {
            //Always default to visual inspection. If nothing is provided in the argument then use a blank visual inspection.
            //This default is overridden if needed by calling _setManualCountAsRequired
            this.visualInspection = visualInspection ? new VisualInspection(visualInspection, this) : new VisualInspection({}, this);
        }

        this.history = history;
    }

    applyAction(action) {
        if(!(action instanceof ReceivingAction))
            action = new ReceivingAction(action);

        if (action.isVisuallyConfirmed)
            this.setVisualInspection({confirmedByUserId: action.userId, date: action.date});
        else if(action.isManualCounted)
            this.setManualCount({countedByUserId: action.userId, additionalUnitsExpectedInd: action.manualCount.additionalUnitsExpectedInd,
                countedQuantity: action.manualCount.countedQuantity, date: action.date});
        else
            throw new Error("Invalid action");
    }


    setVisualInspection({confirmedByUserId, date=new Date()}){
        let errorRefString = `Product: K${this._poItemRef.product.id}, PO Item: ${this._poItemRef.id}, Shipment Id: ${this.shipmentId}`;
        if(this._poItemRef.manualCountRules.isRequired)
            throw new ErrorWithStatusCode({code: 400, message: `Visual inspection cannot be set. Manual Count required by ${this._poItemRef.manualCountRules.requiredSource}. ${errorRefString}`});
        if(this.isManuallyCounted())
            throw new ErrorWithStatusCode({code: 400, message: `Visual inspection cannot be modified. This item has already been manually counted.  ${errorRefString} `});
        if(this.visualInspection && this.visualInspection.isVisuallyConfirmed)
            throw new ErrorWithStatusCode({code: 400, message: "Visual inspection cannot be modified. This item has already been visually confirmed. " +
                `If modification is needed a manual count is required.  ${errorRefString}`});
        this.visualInspection = new VisualInspection({confirmedDate: date, confirmedByUserId: confirmedByUserId}, this);
    }

    setManualCount({countedQuantity, countedByUserId, additionalUnitsExpectedInd, date=new Date()}){
        if(this.isVisuallyConfirmed())
            throw new ErrorWithStatusCode({code: 400, message: "Manual count cannot be set because this item is already visually confirmed."});
        this.visualInspection = null; //Clear a placeholder visual inspection
        if(!this.manualCount)
            this.manualCount = new ManualCount({}, this);
        this.manualCount.setCountedQuantity({countedQuantity: countedQuantity, countedByUserId: countedByUserId,
            additionalUnitsExpectedInd: additionalUnitsExpectedInd, date: date});
    }

    _validateReceivingItemIsNew(){
        if(this.inventoryAddTransactionId || this.inventoryAddTransactionIdHistory.length || this.reserveAddTransactionId || (this.reserveAddTransactionIdHistory &&
                this.reserveAddTransactionIdHistory.length))
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Has Inventory Add Transaction Id/History"});

        if(this.visualInspection && this.visualInspection.isVisuallyConfirmed)
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Visually confirmed"});

        if(this.manualCount && this.manualCount.isManuallyCounted)
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Manually counted"});
        if(this.manualCount && (this.manualCount.discrepancyTransactionId || this.manualCount.discrepancyTransactionIdHistory.length > 0))
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Manually count has data"});
        if(this.manualCount && (this.manualCount.countedByUserId || this.manualCount.countDate))
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Manually count has data"});

        if(this.history.length !== 0)
            throw new ErrorWithStatusCode({code: 400, message: "Receiving Item Not New: Has history"});
        return true;
    }

    isEqualWithoutExpectedQuantity(other){
        if(this.inventoryAddTransactionId !== other.inventoryAddTransactionId)
            return false;

        if(this.reserveAddTransactionId !== other.reserveAddTransactionId)
            return false;

        for (let i = 0; i < this.inventoryAddTransactionIdHistory.length; i++) {
            if(this.inventoryAddTransactionIdHistory[i] !== other.inventoryAddTransactionIdHistory[i])
                return false;
        }


        for (let i = 0; i < this.reserveAddTransactionIdHistory.length; i++) {
            if(this.reserveAddTransactionIdHistory[i] !== other.reserveAddTransactionIdHistory[i])
                return false;
        }

        if(this.shipmentId !== other.shipmentId)
            return false;

        if(this.manualCount){
            if(!other.manualCount) return false;
            if(!this.manualCount.isEqual(other.manualCount))
                return false;
        }

        if(this.visualInspection){
            if(!other.visualInspection) return false;
            if(!this.visualInspection.isEqual(other.visualInspection))
                return false;
        }

        for (let i = 0; i < this.history.length; i++) {
            if(this.history[i] !== other.history[i])
                return false;
        }

        return true;
    }

    /**
     * This is not a true private method but it should only be called from the Purchase Order Item when enforcing manual count rules
     * @private
     */
    _setManualCountAsRequired(){
        delete this.visualInspection;
        this.manualCount = new ManualCount({}, this);
    }

    get detail(){
        let detail = {
            unitsReceived: null,
            additionalExpectedUnits: null,
            extraUnits: null,
            missingUnits: null
        };
        if(!this.manualCount && !this.visualInspection)
            return detail;
        let count = this.manualCount || this.visualInspection;

        detail.unitsReceived = count.countedQuantity === null ? 0 : count.countedQuantity; //null is not counted for manual, default to 0 here
        detail.additionalExpectedUnits = count.additionalExpectedUnits;
        detail.extraUnits = count.extraUnits;
        detail.missingUnits = count.missingUnits;
        return detail;
    }

    get isOpen(){
        if(this.expectedQuantity === 0)
            return false;
        let count = this.manualCount || this.visualInspection;
        return count.isOpen;
    }

    isManuallyCounted(){
        return this.manualCount && this.manualCount.isManuallyCounted;
    }

    isVisuallyConfirmed(){
        //When manual count is present then the state of visual inspection is irrelevant
        if(this.isManuallyCounted())
            return false;
        return this.visualInspection && this.visualInspection.isVisuallyConfirmed;
    }

    //Returns an array in case a inventory add transaction is accompanied by an inbound discrepancy from a manual count
    getTransactions({reserveTargetCount, currentReserveCount}){
        if((this.detail.unitsReceived === 0 && this.detail.missingUnits === 0 )|| this.inventoryAddTransactionId || this.reserveAddTransactionId)
            return []; //Nothing to do or transaction already processed
        let count = this.manualCount || this.visualInspection;
        return count.getTransactionsForReceivedItems({reserveTargetCount, currentReserveCount});
    }

    getQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount}){
        if(this.detail.unitsReceived === 0 || this.inventoryAddTransactionId || this.reserveAddTransactionId)
            return 0; //Nothing to do or transaction already processed
        let count = this.manualCount || this.visualInspection;
        return count.calculateQuantityAllocatedToReserve({reserveTargetCount, currentReserveCount});
    }

    setReceivingTransactionIdFromTransaction(transaction){
        if(transaction.meta.inboundDiscrepancyInd)
            this._setManualCountDiscrepancyTransactionId(transaction.ref);
        else if(transaction.type.name === "INVENTORY-ADD-FRM-INBOUND") {
            if(this.inventoryAddTransactionId != null)
                throw new ErrorWithStatusCode({code: 400, message:"Cannot set inventoryAddTransactionId when it is already set. Void must be called first"});
            this.inventoryAddTransactionId = transaction.ref;
        }
        else if(transaction.type.name === "STORE-RESV-ADD-FRM-INBOUND" || transaction.type.name === "STORE-RESV-ADD-FRM-INVENTORY-INBOUND") {
            if(this.reserveAddTransactionId != null)
                throw new ErrorWithStatusCode({code: 400, message:"Cannot set reserveAddTransactionId when it is already set. Void must be called first"});
            this.reserveAddTransactionId = transaction.ref;
            if(this.reserveAddTransactionIdHistory == null) //Init "hidden" field
                this.reserveAddTransactionIdHistory = [];
        }
        else
            throw new ErrorWithStatusCode({code: 409, message: `Cannot process transaction type ${transaction.type.name} in receiving item`});
    }

    _setManualCountDiscrepancyTransactionId(transactionId){
        if(!this.manualCount)
            throw new ErrorWithStatusCode({code: 400, message: "Cannot set transaction ID for manual count. There is no manual count"});
        this.manualCount.setDiscrepancyTransactionId(transactionId);
    }

    _getTransactionMeta(){
        if(!this._poItemRef._poRef.ref)
            throw new Error("Parent PO must have a ref set");

        let meta = {poRef: this._poItemRef._poRef.ref, poItemId: this._poItemRef.id};
        if(this.shipmentId)
            meta.shipmentId = this.shipmentId;
        return meta;
    }

    setExpectedQuantity(expectedQuantity){
        if(this.expectedQuantity === expectedQuantity)
            return; //Nothing to do
        if(this.detail.unitsReceived !== 0)
            throw new ErrorWithStatusCode({message: "Cannot change expected quantity of a receiving item after units have been received", code: 400});
        this.expectedQuantity = expectedQuantity;
    }

    /**
        Voids this receiving item, maintains history, and returns array of transaction ids to void
     */
    voidItem({userId, date=new Date()}){
        let count = this.manualCount || this.visualInspection;
        if(!this.inventoryAddTransactionId && !this.reserveAddTransactionId && !count.discrepancyTransactionId && !count.isManuallyCounted)
            return []; //Nothing to do
        this.history.push(`Receiving item reset by user ${userId} at ${date}. ${count.toString()}`);

        let transactionsToVoid = [];
        if(this.inventoryAddTransactionId) {
            transactionsToVoid.push(this.inventoryAddTransactionId);
            this.inventoryAddTransactionIdHistory.push(this.inventoryAddTransactionId);
            this.inventoryAddTransactionId = null;
        }
        if(this.reserveAddTransactionId) {
            transactionsToVoid.push(this.reserveAddTransactionId);
            this.reserveAddTransactionIdHistory.push(this.reserveAddTransactionId);
            this.reserveAddTransactionId = null;
        }

        if(count.discrepancyTransactionId)
            transactionsToVoid.push(count.discrepancyTransactionId);

        count.voidCount();
        return transactionsToVoid;
    }

    get receivingTypeString(){
        if(this.manualCount){
            if(this.isManuallyCounted())
                return "Manually Counted";
            return "Manual Count"
        }
        if(this.visualInspection) {
            if(this.isVisuallyConfirmed())
                return "Visually Confirmed";
            return "Visual Inspection"
        }
        return "N/A";
    }

    toJSON(){
        let {["_poItemRef"]:omit, ...res} = this;
        res.receivingTypeString = this.receivingTypeString;
        res.detail = this.detail;
        res.isOpen = this.isOpen;
        if(!res.reserveAddTransactionId && res.reserveAddTransactionIdHistory.length === 0){
            delete res.reserveAddTransactionId;
            delete res.reserveAddTransactionIdHistory;
        }
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        res.manualCount = this.manualCount ? this.manualCount.getForDb() : null;
        res.visualInspection = this.visualInspection ? this.visualInspection.getForDb() : null;
        return res;
    }
}

export { ReceivingItem };