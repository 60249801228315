import React from 'react';
import SettingsContext from './SettingsContext';
import PropTypes from 'prop-types';

class AppProvider extends React.Component {
    render() {
        return (
            <SettingsContext.Provider
                value={{...this.props}}
            >
                {this.props.children}
            </SettingsContext.Provider>
        );
    }
}

AppProvider.propTypes = {
    dashboardFilter: PropTypes.string.isRequired,
    showAdditionalFinanceData: PropTypes.bool,
    defaultVendor: PropTypes.object
};

export default AppProvider;