import React from 'react';
import Context from './ReceivingContext';
import PropTypes from 'prop-types';

class ReceivingProvider extends React.Component {
    render() {
        return (
            <Context.Provider
                value={{...this.props}}
            >
                {this.props.children}
            </Context.Provider>
        );
    }
}


ReceivingProvider.propTypes = {
    productsClient: PropTypes.object.isRequired,

    shipmentIdsToCheckin: PropTypes.array.isRequired,
    setCheckinForShipmentId: PropTypes.func.isRequired,

    voidReceivingActions: PropTypes.array.isRequired,
    receivingActions: PropTypes.array.isRequired,
    addVoidReceivingAction: PropTypes.func.isRequired,

    setVisualConfirmation: PropTypes.func.isRequired,
    setManualCount: PropTypes.func.isRequired,

    markEligibleItemsVisuallyConfirmed: PropTypes.func.isRequired,
    saveReceiving: PropTypes.func.isRequired,
    saveShipmentsOnly: PropTypes.func.isRequired
};

export default ReceivingProvider;