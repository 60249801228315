import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {ProductsHistoryViewContent} from "../organisms/view/ProductsHistoryViewContent";
import {ProductFinanceDetail} from "../molecules/ProductFinanceDetail";
import {LoadingSpinner} from "../atoms/LoadingSpinner";
import {Badge} from "reactstrap";

class ProductHistoryView extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            product: null,
        };
    }

    componentDidMount() {
        this._getProduct();
    }

    componentDidUpdate(prevProps){
        if(prevProps.productId !== this.productId)
            this._getProduct();
    }

    _getProduct(){
        const productId = this.productId;
        if(!productId) return;
        this.props.productsClient.searchForProduct('K'+productId)
            .then(product => {
                this.setState({
                    isLoaded: true,
                    product: product
                });
            }).catch(error => {
            this.setState({
                product: null,
                isLoaded: true,
                error: error
            });
        });
    }

    get productId(){
        return this.props.productId;
    }

    render() {
        const {client} = this.props;
        const { error, isLoaded, product } = this.state;

        if(error) return "Error";

        if(!this.productId) return;

        if(!isLoaded) return <LoadingSpinner/>;
        return (
            <div className="ml-3 mr-3">
                <h1>Product Purchasing History</h1>
                <ToolBar placeholder="K1, 1036, etc" searchUrlPath='history/search'/>
                <h2 className="text-center">{product.detail.shortName}</h2>
                <h6 className="text-center">
                    <Badge color="primary" className="mr-2">{product.key.kid}</Badge>
                    {product.key.modelNumber ? <Badge color="primary" className="mr-2">{product.key.modelNumber}</Badge> : ""}
                </h6>
                <ProductFinanceDetail product={product}/>
                <ProductsHistoryViewContent client={client}  productId={this.productId} />
            </div>
        )
    }
}

ProductHistoryView.propTypes = {
    client: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired,
    productId: PropTypes.string.isRequired
};

export {ProductHistoryView};