import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {LoadingSpinner} from '../atoms/LoadingSpinner'
import PoEditContent from "../organisms/edit/PoEditContent";
import PoPatch from "../../domain/PoPatch";

class PoEdit extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            po: null,
            isLoaded: false,
            error: null
        }
    }

    componentDidMount() {
        this._loadPo();
    }

    componentDidUpdate(prevProps){
        if(prevProps.match.params.poRef !== this.poRef)
            this._loadPo();
    }

    get poRef(){
        return this.props.match.params.poRef;
    }

    _loadPo(){
        const {client} = this.props;
        client.purchaseOrders.getPurchaseOrderByRef(this.poRef)
            .then(po => {
                this.setState({
                    isLoaded: true,
                    po: po
                });
            }).catch(error => {
                this.setState({
                    isLoaded: true,
                    error: error
                });
            });
    }

    _patchPo(keys, data){
        const po = PoPatch.patchPo({po: this.state.po, keys: keys, data: data});
        this.setState({po: po});
        console.log(po);
    }

    _renderBody(){
        const {po, error, isLoaded} = this.state;
        const {client, productsClient} = this.props;
        if (error)
            return <div>Error: {this.props.error ? this.props.error.message : ""}</div>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return <PoEditContent client={client} po={po} productsClient={productsClient} patchPo={(keys, data) => this._patchPo(keys, data)}/>

    }

    render() {
        const {client} = this.props;

        return (
            <div className="ml-3 mr-3">
                <h1>Purchase Order {this.poRef}</h1>
                <div>

                </div>
                <ToolBar client={client}/>
                {this._renderBody()}
            </div>
        )
    }
}

PoEdit.propTypes = {
    client: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired
};

export {PoEdit};