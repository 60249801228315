import React, {Component} from 'react';
import PropTypes from "prop-types";
import {checkinFormatter} from "../../helpers/dataFormatters";
import {CustomInput} from "reactstrap";


class ShipmentReceivingRow extends Component {
    render() {
        const {shipment, rowNum, setCheckinForShipmentId, isChecked} = this.props;
        return (<tr>
            <th scope="row">{rowNum}</th>
            <td>{shipment.trackingNumber}</td>
            <td>{shipment.location}</td>
            <td>{shipment.status}</td>
            <td>{shipment.estimatedDelivery ? new Date(shipment.estimatedDelivery).toLocaleDateString() : "Unknown"}</td>
            <td>
                {shipment.isCheckedIn ? checkinFormatter(shipment, true) : <CustomInput checked={isChecked} type="checkbox" id={`received-cb-${rowNum}`} label="Received"
                                                                                        onChange={e => setCheckinForShipmentId({shipmentId: shipment.id, isCheckedIn: e.target.checked})} />}
            </td>
        </tr>)
    }
}

ShipmentReceivingRow.propTypes = {
    shipment: PropTypes.object.isRequired,
    rowNum: PropTypes.number.isRequired,
    isChecked: PropTypes.bool.isRequired,
    setCheckinForShipmentId: PropTypes.func.isRequired
};

export {ShipmentReceivingRow};
