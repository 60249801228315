import React, {Component} from 'react';
import { Input} from 'reactstrap';
import PropTypes from "prop-types";

class SearchInput extends Component {
        render() {
            return <Input placeholder={this.props.placeholder} bsSize="lg" className={`${this.props.className}`}
                          id={this.props.id}
                          value={this.props.value}
                          onChange={e => this.props.onChange ? this.props.onChange(e) : null}
                          onKeyPress={e => this.props.onKeyPress ? this.props.onKeyPress(e) : null}
        />
    }
}

SearchInput.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    id: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onKeyPress: PropTypes.func
};

export {SearchInput};
