import React from 'react';
import PropTypes from 'prop-types';
import {SearchInput} from '../../atoms/SearchInput';
import {withRouter} from 'react-router-dom'
import { Row, Col, Button } from 'reactstrap';
import Link from "react-router-dom/es/Link";
import AppContext from "../../../context/AppContext";

class ToolBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            text: ""
        };

    }

    _handleChange(e){
        this.setState({
            text: e.target.value
        });
    }

    _searchIfKeyPressIsEnter(e){
        if (e.key === "Enter")
            this._search(e);
    }

    _search(){
        this.props.history.push(`/${this._searchUrlPath}/${this.state.text}${this.props.receivingContext ? `?receiving=true` : ''}`)
    }

    get _searchUrlPath(){
        if(this.props.searchUrlPath) return this.props.searchUrlPath;
        return 'search';
    }

    static _renderNewButton(user){
        if(!user.isMemberOfGroup("IM_PURCHASE_ORDERS_CREATE_UPDATE"))
            return;
        return <Button color="success" className="mr-2" size="lg" tag={Link} to="/po/new">New PO</Button>
    }

    render() {
        const { text } = this.state;
        const { extraComponent, placeholder } = this.props;
        return (
            <AppContext.Consumer>
                {({user}) => (
                <Row className="pb-3">
                    <Col md={8}>
                        <SearchInput
                            placeholder={placeholder || 'Search PO#, Order Number or Tracking Number'}
                            onChange={e => this._handleChange(e)}
                            onKeyPress={e => this._searchIfKeyPressIsEnter(e)}
                            value={text}
                        />
                    </Col>
                    <Col>
                        <Button outline onClick={e => this._search()}  color="secondary" className="mr-2" size="lg">Search</Button>
                        {ToolBar._renderNewButton(user)}
                        {extraComponent ? extraComponent : null}
                    </Col>
                </Row>)}
        </AppContext.Consumer>);
    }
}

ToolBar.propTypes = {
    extraComponent: PropTypes.object,
    placeholder: PropTypes.string,
    searchUrlPath: PropTypes.string,
    receivingContext: PropTypes.bool
};

export default withRouter(ToolBar)