import { UnitPriceHistory } from './service/unitprice.history';
import { PurchaseOrders } from './service/purchase-orders';
import { StandaloneShipments } from './service/standalone.shipments';
import { Trackings } from './service/trackings';
import { Vendors } from './service/vendors';
import { Receiving } from './service/purchase-orders.receving';
import { Reports } from './service/reports';

class PurchasingClient{
    constructor({apiKey, sessionKey}){
        this.unitPriceHistory = new UnitPriceHistory({apiKey, sessionKey});
        this.purchaseOrders = new PurchaseOrders({apiKey, sessionKey});
        this.standaloneShipments = new StandaloneShipments({apiKey, sessionKey});
        this.trackings = new Trackings({apiKey, sessionKey});
        this.receiving =  new Receiving({apiKey, sessionKey});
        this.vendors = new Vendors({apiKey, sessionKey});
        this.reports = new Reports({apiKey, sessionKey});
    }
}

export { PurchasingClient };