//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {SearchContent} from '../organisms/view/SearchContent'
import queryString from 'query-string'

class Search extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {client} = this.props;
        const query = queryString.parse(this.props.location.search);
        return (
            <div className="ml-3 mr-3">
                <h1>Purchase Order Search</h1>
                <ToolBar client={client}/>
                <SearchContent client={client} query={this.props.match.params.query} receivingContext={!!query.receiving}/>
            </div>
            )
    }
}

Search.propTypes = {
    client: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
};

export {Search};