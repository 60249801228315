import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import ProductsHistoryTable from "../../molecules/tables/ProductsHistoryTable";

class ProductsHistoryViewContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            historyRecords: [],
            poItems: []
        };
    }

    componentDidMount() {
        this._getHistory();
    }

    componentDidUpdate(prevProps){
        if(prevProps.productId !== this.props.productId)
            this._getHistory();
    }

    _getHistory(){
        const {productId} = this.props;
        this.props.client.unitPriceHistory.getPurchaseHistoryForProductId(productId)
            .then(historyRecords => {
                historyRecords.forEach(historyRecord => historyRecord.poItem = () => {
                    console.log( this.state.poItems);
                    const result = this.state.poItems.find(poItemResult => poItemResult.historyItem.poId
                        === historyRecord.poId);
                   if(!result)
                       return null;
                    return result.poItem;
                });
                this.setState({
                    isLoaded: true,
                    historyRecords: historyRecords
                });

                if(!historyRecords)
                    return;

                const promises = historyRecords.map(item => {
                    return this.props.client.purchaseOrders.getPurchaseOrderItem({poRef: item.poId, productId: item.productId})
                        .then(poItem => {
                            return {poItem, historyItem: item};
                        })
                        .catch(err =>{
                            return null;
                        })
                });

                Promise.all(promises)
                    .then(results => {
                        const poItems = [];
                        results.forEach(r => {
                            if(r)
                                poItems.push(r);
                        });
                        console.log(results);
                        this.setState({poItems});
                    }).catch(error => {
                        this.setState({isLoaded: true, error: error});
                        console.log(error);
                });
            });
    }

    render() {
        const { error, isLoaded, historyRecords, poItems } = this.state;
        const {productId} = this.props;

        if(!productId) return "";

        return (
            <div>
                <h3 className="text-center">Purchase History</h3>
                <Row>
                    <Col>
                        <ProductsHistoryTable poItems={poItems} historyRecords={historyRecords} isLoaded={isLoaded} posError={error}/>
                    </Col>
                </Row>
            </div>
            );
    }
}

ProductsHistoryViewContent.propTypes = {
  productId: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired
};

export {ProductsHistoryViewContent};