function getReceivingManualCountRules({vendor, product}) {
    if(vendor.isManualCountRequired)
        return {isRequired: true, requiredSource: "Vendor"};
    else if(product.detail.indicators.isManualCountRequired)
        return {isRequired: true, requiredSource: "Product"};

    return {isRequired: false, requiredSource: null};
}

export { getReceivingManualCountRules };

