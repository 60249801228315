import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, Input, FormText, Label} from 'reactstrap';
import {ErrorAlert} from "../atoms/ErrorAlert";
import {LoadingSpinner} from "../atoms/LoadingSpinner";


class VendorsDropdown extends React.Component {
    _handleChange(e){
        const {vendorChanged, vendors} = this.props;
        if(vendorChanged)
            vendorChanged(vendors.find(v => v.id === e.target.value));
    }

    render() {
        const {vendors, isLoaded, error, defaultValue} = this.props;
        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;

        if(vendors[0].id !== null)
            vendors.unshift({id: null, name: ""}); //Empty
        return (
            <FormGroup>
                <Input type="select" name="vendor" id="vendor" bsSize="sm" value={defaultValue ? defaultValue : undefined} onChange={e => this._handleChange(e)} >
                    {vendors.map(v => <option key={v.id} value={v.id}>{v.name}</option>)}
                </Input>
            </FormGroup>
        );
    }
}

VendorsDropdown.propTypes = {
    vendors: PropTypes.array,
    isLoaded: PropTypes.bool,
    error: PropTypes.object,
    defaultValue: PropTypes.string,
    vendorChanged: PropTypes.func
};

export {VendorsDropdown};