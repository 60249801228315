function doesPoItemTransactionNeedToBeUpdated({poItem, transaction, log}) {
    if(!transaction.hasOwnProperty('itemCount'))
        throw new Error("transaction missing itemCount. Cannot be used to determine if transaction needs to be updated");

    if(transaction.hasOwnProperty("voided") && poItem.quantity !== 0)
        return true; //Always update a voided transaction unless PO Item is empty in which case a transaction is not needed

    if(transaction.itemCount !== poItem.quantity)
        return true; //Any time the quantity is different an update is needed

    if(transaction.product.id !== poItem.product.id) {
        log.critical(`Product Id Mismatch for PO Item ${poItem.id} and transaction ${transaction.ref}. Update of this transaction will be triggered`);
        return true;
    }

    return false; //Po Item's Transaction does not need to be updated
}

export { doesPoItemTransactionNeedToBeUpdated };


