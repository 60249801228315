import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalFooter, FormGroup, ModalBody, Label, Input, } from 'reactstrap';
import PropTypes from "prop-types";
import Comment from "../../../vendor/obj/Comment"

class CommentModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comment: null,
            commentText: "",
            checked: false
        };
    }

    commentChange() {
        const comment = new Comment({comment: this.state.commentText, user: {}, showOnReceiving: this.state.checked});
        this.setState({
            comment: comment
        });
    }
    async commentTextChanged(e){
        await this.setState({commentText: e.target.value});
        this.commentChange();
    }

    async checkChange(e){
        await this.setState({checked: !this.state.checked});
        this.commentChange();
    }

    render() {
        const {onComment, onCancel} = this.props;
        return (
            <div>
                <Modal isOpen={true} toggle={e => onCancel()} className={this.props.className}>
                    <ModalHeader toggle={e => onCancel()}>Add Comment</ModalHeader>
                    <ModalBody>
                        <FormGroup>
                            <Label for="exampleText">Comment</Label>
                            <Input type="textarea" value={this.state.commentText} onChange={e => this.commentTextChanged(e)}/>
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <FormGroup check onClick={e => this.checkChange(e)}>
                            <Input type="checkbox" checked={this.state.checked} />
                            {' '}
                            <Label check>
                                Show on Receiving Page
                            </Label>
                        </FormGroup>
                        <Button color="primary" onClick={e => onComment(this.state.comment)}>Add Comment</Button>{' '}
                        <Button color="secondary" onClick={e => onCancel()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

CommentModal.propTypes = {
    onComment: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired
};

export {CommentModal};
