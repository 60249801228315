import {PurchaseOrder} from "../vendor/obj/PurchaseOrder";

class PoPatch{
    static patchPo({po, keys, data}){
        const clonedPo = new PurchaseOrder(po);

        if(typeof keys === "string"){
            if(keys === "addItem")
                clonedPo.items.push(data);
            else if(keys === "addShipment")
                clonedPo.shipments.push(data);
            else if(keys === "addShipments")
                data.forEach(shipment => clonedPo.shipments.push(shipment));
            else if(keys === "setItem") {
                const index = clonedPo.items.findIndex(item => item.product.id === data.product.id);
                if(index === -1)
                    throw new Error(`Cannot patch PO. Product ${data.product.id} not found as an item`);
                clonedPo.items[index] = data;
            }
            else if(keys === "addComment"){
                clonedPo.comments.push(data);
            }
            else if(keys === "autoShippingCostDist")
                clonedPo.autoShippingCostAllocation();
            else if(keys === "shipToOffice"){
                if(data)
                    clonedPo.meta.shipToOffice = true;
                else
                    delete clonedPo.meta.shipToOffice;
            }
            else
                throw new Error("Cannot patch PO. Invalid keys as string command");
        }
        else {
            if (keys.length === 1)
                clonedPo[keys[0]] = data;
            else if (keys.length === 2)
                clonedPo[keys[0]][keys[1]] = data;
            else if (keys.length === 3)
                clonedPo[keys[0]][keys[1]][keys[3]] = data;
            else
                throw new Error("Cannot patch PO. Invalid keys");
        }

        return clonedPo;
    }
}

export default PoPatch;