import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import { PurchaseOrder } from '../../obj/PurchaseOrder';

class Receiving{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    applyReceivingActions({poRef, actions}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + routes.receiving.post.replace(':poRef', poRef),
            body: actions,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }

    applyVoidReceivingActions({poRef, actions}){
        let options = {
            method: 'POST',
            uri: config.baseUrl + routes.receiving.void.replace(':poRef', poRef),
            body: actions,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(po => {
                return new PurchaseOrder(po);
            })
    }
}


export { Receiving };


