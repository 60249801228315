/**
 * Tracking is generic and not necessarily related to a Shipment (Purchase Order Shipment).
 * A tracking is an abstraction of an "aftership tracking".
 * @type {module.Tracking}
 */
import TrackingCheckpoint from './TrackingCheckpoint';

import moment from 'moment';

export default class Tracking{
    constructor({id, _id, trackingNumber, referenceId=null, type, slug, _raw}){
        if(!type || !trackingNumber || !slug)
            throw new Error("Missing required attribute for Tracking. type, trackingNumber, and slug are required");

        this.id = id || (_id ? _id.toString() : null);
        this.referenceId = referenceId; //External reference ID. (Ex: Po Id)
        this.type = type; //Example "PO_SHIPMENT"

        this.trackingNumber = trackingNumber;
        this.slug = slug;

        this._raw = _raw;
    }

    getAftershipPostBodyToCreate(){
        return { tracking: {
                slug: this.slug,
                tracking_number: this.trackingNumber,
                custom_fields: {
                    id: this.id,
                    referenceId: this.referenceId,
                    type: this.type
                }
            }
        }
    }

    /**
     * @param data aftership tracking data. Includes raw aftership tracking object and all checkpoints
     */
    setRawAftershipData(data=null) {
        this._raw = data;
    }

    get aftershipId(){
        if(!this._raw) return null;
        return this._raw.id;
    }

    get checkpoint(){
        if(!this._raw) return null;
        if (this._raw.checkpoints && this._raw.checkpoints.length !== 0)
            return new TrackingCheckpoint(this._raw.checkpoints[this._raw.checkpoints.length - 1]);
        return null;
    }

    get status(){
        if(!this.checkpoint)
            return "No Information Available";
        return this.checkpoint.status;
    }

    get shortStatus(){
        if(!this.checkpoint)
            return "N/A";
        return this.checkpoint.shortStatus;
    }

    get checkpointTime(){
        if(!this.checkpoint)
            return null;
        return this.checkpoint.time;
    }

    get location(){
        if(!this.checkpoint) return null;
        return this.checkpoint.location;
    }

    get signedBy(){
        if(!this._raw) return null;
        return this._raw.signed_by;
    }

    get estimatedDelivery(){
        if(!this._raw) return null;
        if (this._raw.shipment_delivery_date)
            return moment(this._raw.shipment_delivery_date).toDate();
        if (this._raw.expected_delivery)
            return moment(this._raw.expected_delivery).toDate();
        return null;
    }

    get shippedDate(){
        if(!this._raw) return null;
        if(!this._raw.shipment_pickup_date) return null;
        return new Date(this._raw.shipment_pickup_date);
    }

    get active(){
        if(!this._raw) return null;
        return this._raw.active;
    }

    toJSON(){
        let {["_raw"]:omit, ...res} = this;
        res.aftershipId = this.aftershipId;
        res.checkpoint = this.checkpoint;
        res.signedBy = this.signedBy;
        res.estimatedDelivery = this.estimatedDelivery;
        res.shippedDate = this.shippedDate;
        res.status = this.status;
        res.shortStatus = this.shortStatus;
        res.location = this.location;
        res.checkpointTime = this.checkpointTime;
        res.active = this.active;
        return res;
    }

    getForCache(){
        let res = this.toJSON();
        res._raw = this._raw;
        res.checkpoint = this.checkpoint ? this.checkpoint.getForDb() : null;
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        delete res.id;
        res._raw = this._raw;
        res.checkpoint = this.checkpoint ? this.checkpoint.getForDb() : null;
        return res;
    }

    toString(){
        return `${this.slug.toUpperCase()} - ${this.trackingNumber.toUpperCase()}`
    }

    toStatusString(){
        let statusString = `${this.status} as of ${this.checkpointTime} at ${this.location}`;
        if(this.signedBy)
            return statusString + ` and Signed by ${this.signedBy}`;
        return statusString;
    }
};