import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import Tracking from '../../obj/Tracking';

class Trackings{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    getSlugForTrackingNumber(trackingNumber) {
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.trackings.getSlug.replace(':trackingNumber', trackingNumber),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(slugs => {
                return slugs.slugs;
            })
    }

    getTrackings() {
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.trackings.getAll,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(results => {
                //Trackings can only be reconstructed with _raw which is not made public. The output should be fine and object not needed
                //results.trackings = results.trackings.map(tracking => new Tracking(tracking));
                return results;
            })
    }

    getTracking(trackingNumber) {
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.trackings.get.replace(':trackingNumber', trackingNumber),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    forceUpdateTracking(trackingNumber) {
        let options = {
            method: 'PUT',
            body: {},
            uri: config.baseUrl + routes.trackings.put.replace(':trackingNumber', trackingNumber),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(tracking => {
                return tracking;
            })
    }
}

export { Trackings };
