import React, {Component} from 'react';
import {Form, Button, NavLink, NavbarBrand} from 'reactstrap';
import {Link} from 'react-router-dom'
import PropTypes from "prop-types";
import AppContext from "../../context/AppContext";

class PurchasingControlButtons extends Component {
    _renderPrimaryButton(user){
        const {primaryPrefix, primaryOnClick, primaryLinkTo} = this.props;

        const primaryProps = {};
        if(primaryLinkTo) {
            primaryProps.to = primaryLinkTo;
            primaryProps.tag = Link;
        }
        if(primaryOnClick)
            primaryProps.onClick = e => primaryOnClick(e);

        if(!user.isMemberOfGroup("IM_PURCHASE_ORDERS_CREATE_UPDATE"))
            return;
        return <Button color="primary" {... primaryProps}>{primaryPrefix} PO</Button>
    }

    _renderReceivingButton(user){
        const {poRef} = this.props;
        if(!poRef || !user.isMemberOfGroup("IM_PURCHASE_ORDERS_RECEIVING_UPDATE"))
            return;

        return <NavLink tag={Link} to={`/receiving/edit/${poRef}`}>Receiving</NavLink>
    }

    render() {
        const {addCommentOnClick} = this.props;

        return (<AppContext.Consumer>
            {({user}) => (
            <Form inline>
                {this._renderPrimaryButton(user)}
                { addCommentOnClick ? <Button outline color="info" className="ml-2" onClick={e => addCommentOnClick(e)}>Add Comment</Button> : ""}
                {this._renderReceivingButton(user)}
            </Form>
            )}
        </AppContext.Consumer>)
    }
}

PurchasingControlButtons.propTypes = {
    primaryPrefix: PropTypes.string.isRequired,
    primaryOnClick: PropTypes.func,
    addCommentOnClick: PropTypes.func,
    primaryLinkTo: PropTypes.string,
    poRef: PropTypes.number
};

export {PurchasingControlButtons};
