import React from 'react';
import PropTypes from 'prop-types';
import ProductsTable from '../../molecules/tables/ProductsTable';
import { Row, Col } from 'reactstrap';
import {Redirect} from "react-router-dom";

class ProductsSearchContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoaded: false,
            products: [],
        };

    }

    componentDidMount() {
        this._searchProducts();
    }

    componentDidUpdate(prevProps){
        if(prevProps.query !== this.props.query)
            this._productsSearch();
    }

    _searchProducts(){
        const {query} = this.props;
        if(query)
            this._productsSearch();
    }

    _productsSearch(){
        const {query} = this.props;
        this.props.client.searchForProducts(query)
            .then(products => {
                this.setState({
                    isLoaded: true,
                    products: products
                });
            }).catch(error => {
            this.setState({
                isLoaded: true,
                error: error
            });
        });
    }

    render() {
        const { error, isLoaded, products } = this.state;
        const {query} = this.props;

        if(!query) return "";

        if(products.length === 1)
            return <Redirect to={`/history/${products[0].key.id}`}/>;

        return (
            <Row>
                <Col>
                    <h2 className="text-center">Search Results</h2>
                    <ProductsTable products={products} isLoaded={isLoaded} error={error}/>
                </Col>
            </Row>
            );
    }
}

ProductsSearchContent.propTypes = {
  query: PropTypes.string
};

export {ProductsSearchContent};