import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col, } from 'reactstrap';
import {PurchaseOrderItemsTable} from "../../molecules/tables/PurchaseOrderItemsTable";
import {ShipmentsTable} from "../../molecules/tables/ShipmentsTable";
import {PaymentDetails} from "../../molecules/PaymentDetails";
import { Badge } from 'reactstrap';
import {PoCommentsTable} from "../../molecules/tables/PoCommentsTable";
import {PoDetailsEdit} from "../../molecules/PoDetailsEdit";
import {AddPoItemBar} from "./AddPoItemBar";
import {AddShipmentBar} from "./AddShipmentBar";
import {PurchasingControlButtons} from "../../atoms/PurchasingControlButtons";
import {Redirect, withRouter} from "react-router-dom";
import {CommentModal} from "./CommentModal";
import {CommentsContent} from "../../molecules/CommentsContent";


class PoEditContent extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            redirectUrl: false,
            showCommentModal: false
        }
    }

    get paidBadge(){
        return (
            <div className="d-inline-block">
                {this.props.po.payment.paidInFull ? <Badge color="success">PAID</Badge> : <Badge color="danger">NOT PAID</Badge> }
            </div>
        )
    }

    _addCommentClick(){
        this.setState({showCommentModal: true});
    }


    _hideCommentModal(){
        this.setState({showCommentModal: false});
    }

    _saveComment(comment){
        this.setState({showCommentModal: false});
        this.props.patchPo("addComment", comment);
    }

    _createSavePo(){
        const {client, po} = this.props;
        if(po.ref){
            client.purchaseOrders.updatePurchaseOrder(po)
                .then(po => {
                    this.setState({redirectUrl: `/po/view/${po.ref}`})
                })
                .catch(err => {
                    alert("Error saving PO");
                })
        }
        else{
            client.purchaseOrders.createPurchaseOrder(po)
                .then(po => {
                    this.setState({redirectUrl: `/po/view/${po.ref}`})
                })
                .catch(err => {
                    console.log(err);
                    alert("Error saving PO");
                })
        }
    }

    _renderCommentModal(){
        if(!this.state.showCommentModal) return "";
        return <CommentModal onComment={comment => this._saveComment(comment)} onCancel={() => this._hideCommentModal()}/>
    }

    render() {
        const {po, client, patchPo} = this.props;

        if(this.state.redirectUrl)
            return <Redirect to={this.state.redirectUrl}/>;

        return (
            <div>
                {this._renderCommentModal()}
                <Row className="mb-1">
                    <Col md={3}>
                        <Row className="ml-1">
                            <h4 className="mlPurchaseOrderItemsTable-1 mr-2">Details</h4>
                           <PurchasingControlButtons poRef={po.ref}
                               primaryPrefix={po.ref ? "Save" : "Create"} addCommentOnClick={() => this._addCommentClick()} primaryOnClick={() => this._createSavePo()}/>
                        </Row>
                    </Col>
                    <Col>
                        <Row className="ml-1">
                            <h4>Items <span className="d-inline-block">({po.nonZeroPoItems.length})</span></h4>
                            <AddPoItemBar {... this.props} className="ml-2"/>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <PoDetailsEdit client={client} po={po} patchPo={patchPo}/>
                    </Col>
                    <Col>
                        <PurchaseOrderItemsTable po={po} edit patchPo={patchPo}/>
                    </Col>
                </Row>

                <Row className="mb-1">
                    <Col md={3}>
                        <h4 className="ml-1">Payment Details {this.paidBadge}</h4>
                    </Col>
                    <Col>
                        <Row className="ml-1">
                            <h4 className="ml-1">Shipments <span className="d-inline-block">({po.shipments.length})</span></h4>
                            <AddShipmentBar {... this.props} className="ml-2"/>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <PaymentDetails po={po} patchPo={patchPo}/>
                    </Col>
                    <Col>
                       <ShipmentsTable shipments={po.shipments} isLoaded={true}/>
                       <CommentsContent comments={po.comments}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

PoEditContent.propTypes = {
    po: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired,
    patchPo: PropTypes.func.isRequired
};

export default withRouter(PoEditContent);