import React, {Component} from 'react';
import PropTypes from "prop-types";
import {Badge, Button, CustomInput, Input, Form} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faBackspace, faCheck, faTruckLoading, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons'
import {receivingItemBadgeFormatter} from "../../helpers/dataFormatters";
import ReceivingContext from "../../context/ReceivingContext";
import AppContext from "../../context/AppContext";

class ManualCountEditControl extends Component {
    _getCountedString(receivingItem){
        return receivingItem.manualCount.additionalUnitsExpectedInd ? `Additional Units Expected as of ${this._renderDate()}` : `Manually Counted${this._renderDate(true)}`;
    }

    _renderDate(withDash=false){
        const date = this.props.receivingItem.manualCount.countDate;
        if(!date || date.getTime() === 0)
            return "";
        return `${withDash ? ' - ' : ''}${date.toLocaleDateString()}`;
    }

    _renderIcon(){
        const { receivingItem } = this.props;
        if(receivingItem.detail.unitsReceived >= receivingItem.expectedQuantity)
            return  <FontAwesomeIcon className="mr-2" icon={faCheck} color="green" />;
        if(receivingItem.detail.additionalExpectedUnits)
            return  <FontAwesomeIcon className="mr-2" icon={faTruckLoading} color="grey" />;
        return <FontAwesomeIcon className="mr-2" icon={faExclamationTriangle} color="darkred" />;

    }

    _renderCounted({addVoidReceivingAction}){
       const {productId,  receivingItem } = this.props;
       const {voidText, voidColor} = receivingItem.manualCount.additionalUnitsExpectedInd ? {voidText: "Edit", voidColor: "secondary"} : {voidText: "Void", voidColor: "danger"};
       return <Form inline>
            {this._renderIcon()}
            <Badge className="mr-2" color="info">{this._getCountedString(receivingItem)}</Badge>
            {receivingItemBadgeFormatter({receivingItem, prefix: "Count: "})}
            <Button outline color={voidColor} size="sm" className="ml-auto"
                    onClick={() => addVoidReceivingAction({productId: productId, shipmentId: receivingItem.shipmentId})}>{voidText}</Button>
        </Form>
    }

    _getAdditionalUnitsCheckout({value, receivingItem, additionalExpectedChecked, setManualCount, productId, user}){
        if(value < receivingItem.expectedQuantity) {
            if(user.isMemberOfGroup("IM_PURCHASE_ORDERS_CREATE_UPDATE"))
                return <CustomInput className="ml-auto"
                                    type="checkbox"
                                    id={`additionalUnits-cb-${(Math.random() * 1000).toFixed(0)}`}
                                    label="Additional Units Expected"
                                    checked={additionalExpectedChecked}
                                    onChange={e => setManualCount({
                                        productId: productId,
                                        shipmentId: receivingItem.shipmentId,
                                        quantity: value ? value : 0,
                                        additionalUnitsExpectedInd: e.target.checked
                                    })}/>;
        }
        return "";
    }

    _renderEdit({isVoided, receivingActions, setManualCount, user}){
        const {productId, receivingItem } = this.props;
        const action = this.findReceivingAction({receivingActions});
        const value = this.getManualCountValue({action, isVoided});
        const additionalExpectedChecked = this.getAdditionalUnitsExpectedChecked({action});

        return <Form inline>
            {isVoided ?  <FontAwesomeIcon className="mr-2" icon={faBackspace} color="grey" /> : ""}
            <Input
                value={value == null ? "" : value}
                type="number"
                style={{width: "6em"}}
                onChange={e =>
                    setManualCount({productId: productId, shipmentId: receivingItem.shipmentId, quantity: e.target.value,
                        additionalUnitsExpectedInd: action ? action.manualCount.additionalUnitsExpectedInd : false})}
            />
            {this._getAdditionalUnitsCheckout({value, receivingItem, additionalExpectedChecked, setManualCount, productId, user})}
        </Form>
    }

    findReceivingAction({receivingActions}){
        const {productId, receivingItem} = this.props;
        return receivingActions.find(voidAction => voidAction.productId === productId
            && voidAction.shipmentId === receivingItem.shipmentId);
    }

    findVoidAction({voidReceivingActions}){
        const {productId, receivingItem} = this.props;
        return voidReceivingActions.find(voidAction => voidAction.productId === productId
            && voidAction.shipmentId === receivingItem.shipmentId);
    }

    isVoided({voidReceivingActions}){
        const voidAction = this.findVoidAction({voidReceivingActions});
        return !!voidAction;
    }

    getManualCountValue({action, isVoided}){
        const {receivingItem} = this.props;
        if(action){
            if(action.manualCount.countedQuantity !== null)
                return action.manualCount.countedQuantity ;
            return "";
        }
        if(!isVoided)
            return receivingItem.manualCount.countedQuantity;
        if(isVoided && !action)
            return receivingItem.manualCount.countedQuantity;
        return "";
    }

    getAdditionalUnitsExpectedChecked({action}){
        const {receivingItem } = this.props;
        if(action)
            return action.manualCount.additionalUnitsExpectedInd;
        if(receivingItem && receivingItem.manualCount)
            return receivingItem.manualCount.additionalUnitsExpectedInd;
        return false;
    }

    isManuallyCounted(){
        const {receivingItem } = this.props;
        if(!receivingItem.manualCount) return false;
        return receivingItem.manualCount.countedQuantity != null || receivingItem.manualCount.additionalUnitsExpectedInd
    }

    render() {
        return (<AppContext.Consumer>
            {({user}) => (
                <ReceivingContext.Consumer>
                    {({receivingActions, voidReceivingActions, setManualCount,
                          addVoidReceivingAction}) => {

                        let isManuallyCounted = this.isManuallyCounted();
                        const isVoided = this.isVoided({voidReceivingActions});

                        if(!isVoided && isManuallyCounted) return this._renderCounted({addVoidReceivingAction});
                        return this._renderEdit({isVoided, receivingActions, setManualCount, user});
                    }}
                </ReceivingContext.Consumer>
            )}
        </AppContext.Consumer>)
    }
}

ManualCountEditControl.propTypes = {
    receivingItem: PropTypes.object.isRequired,
    productId: PropTypes.number.isRequired
};

export {ManualCountEditControl};
