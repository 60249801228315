import Shipment from './Shipment';

class StandaloneShipment{
    constructor({poId, poRef, purchaseDate, shipment}){

        this.poId = poId ? poId.toString() : null;
        this.poRef = poRef;

        this.shipment = new Shipment(shipment);
    }

    toJSON(){
        return {
            poId: this.poId,
            poRef: this.poRef,
            shipment: this.shipment.toJSON()
        }
    }
}

export { StandaloneShipment };