import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactstrap';
import {PoDetailRow} from '../atoms/PoDetailRow';
import {currencyFormatter, numberFormatter} from "../../helpers/dataFormatters";

class PoDetails extends React.Component {
    render() {
        const { po } = this.props;
        return (
            <Table bordered>
                <tbody>
                    <PoDetailRow label="Vendor" data={po.vendor.name} />
                    <PoDetailRow label="Order Number" data={po.orderNumber} />
                    <PoDetailRow label="Purchase Date" data={po.purchaseDate.toLocaleDateString()} />
                    <PoDetailRow label="Shipping Cost" data={currencyFormatter(po.costs.shipping)} />
                    <PoDetailRow label="Other Costs" data={currencyFormatter(po.costs.other)} />
                    <PoDetailRow label="Grand Total" className="font-weight-bold" data={currencyFormatter(po.totals.grand)}  />
                    <PoDetailRow label="Total Units" data={numberFormatter(po.totals.unitCount)} />
                </tbody>
            </Table>
        );
    }
}

PoDetails.propTypes = {
    po: PropTypes.object.isRequired,
};

export {PoDetails};