import { AuditItem } from './AuditItem';

class Audit{
    constructor({created=null, updated}){
        this.updated = new AuditItem(updated);
        if(created == null) //Same as created
            this.created = new AuditItem(updated);
        else
            this.created = new AuditItem(created);
    }
}

export { Audit };