const DEV = {
    "baseUrl": "http://localhost:3020/v1/"
};

const PROD = {
    "baseUrl": "https://products.kjdelectronics.com/v1/"
};

const config = process.env.NODE_ENV.toLowerCase() === 'production' ? PROD : DEV;

module.exports.config = config;