import { config } from '../config/config';
import routes from '../config/routes';
import rp from 'request-promise';
import { StandaloneShipment } from '../../obj/StandaloneShipment';
import { PurchaseOrder } from '../../obj/PurchaseOrder';


class Reports{
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    get inboundPdfUrl(){
        return config.baseUrl + routes.reports.inboundPdf
    }

    getInboundShipmentData(){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.reports.inbound,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .then(data => {
                data.inboundShipments = data.inboundShipments.map(shipment => new StandaloneShipment(shipment));
                data.notShippedPos = data.notShippedPos.map(po => new PurchaseOrder(po));
                data.inboundPos = data.inboundPos.map(po => new PurchaseOrder(po));
                return data;
            })
    }
}


export { Reports };