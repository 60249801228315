export default class TrackingCheckpoint{
    constructor(_raw){
        this._raw = _raw;
    }

    get shortStatus(){
        if(!this._raw)
            return "N/A";
        if(this._raw.subtag_message)
            return this._raw.subtag_message;
        if(this._raw.message)
            return this._raw.message;
        return "N/A";
    }

    get status(){
        if(!this._raw)
            return "No Information Available";

        let tag = null;
        if(this._raw.tag)
            tag = this._raw.tag.replace(/([A-Z])/g, ' $1').trim(); //Add spaced and then trim


        if(!this._raw.subtag_message && !this._raw.message){
            if(tag) return tag;
            return "No Information Available";
        }
        if(!this._raw.subtag_message && this._raw.message)
            return `${this._raw.subtag_message}: ${this._raw.message}`;
        return this.shortStatus;

    }

    get location(){ //Get status string
        if(!this._raw)
            return null;
        if(this._raw.city && this._raw.state)
            return `${this._raw.city}, ${this._raw.state}`;
        if(this._raw.location)
            return this._raw.location;
        if(this._raw.state)
            return this._raw.location;
        if(this._raw.country_name)
            return this._raw.country_name;
        return null;
    }

    get time(){
        if(!this._raw || !this._raw.checkpoint_time)
            return null;
        return new Date(this._raw.checkpoint_time);
    }

    toJSON(){
        let {["_raw"]:omit, ...res} = this;
        res.status = this.status;
        res.shortStatus = this.shortStatus;
        res.location = this.location;
        res.time = this.time;
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        res._raw = this._raw;
        return res;
    }

};