//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {ReceivingCard} from "../../atoms/ReceivingCard";
import {ReceivingShipmentsTable} from "../../molecules/tables/ReceivingShipmentsTable";

class EditReceivingShipmentsCard extends React.Component {
    render() {
        const {shipments} = this.props;

        return (
            <ReceivingCard badgeText="Step 1" headerText="Shipments">
               <ReceivingShipmentsTable shipments={shipments} />
            </ReceivingCard>
            );
    }
}

EditReceivingShipmentsCard.propTypes = {
    shipments: PropTypes.array.isRequired
};

export {EditReceivingShipmentsCard};