import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {ErrorAlert} from "../../atoms/ErrorAlert";
import {
    poRefFormatter,
    dateFormatter,
    currencyFormatter,
    numberFormatter,
    receivingItemFormatter
} from "../../../helpers/dataFormatters"

class ProductsHistoryTable extends React.Component {
    render() {
        const { error, isLoaded, historyRecords } = this.props;

        let additionalProps = {};
        if(historyRecords.length > 10)
            additionalProps.pagination = true;

        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return (
            <div className="narrow-table">
                <BootstrapTable data={historyRecords} striped hover {...additionalProps}  version='4'>
                    <TableHeaderColumn isKey={true} dataField='poId' dataSort={ true } width={"10%"} dataFormat={(data) => poRefFormatter(data)}>PO ID</TableHeaderColumn>
                    <TableHeaderColumn dataField='purchaseDate' dataFormat={dateFormatter}>Purchase Date</TableHeaderColumn>
                    <TableHeaderColumn dataField='unitPrice' dataFormat={currencyFormatter}>Unit Price</TableHeaderColumn>
                    <TableHeaderColumn dataField='unitCost' dataFormat={currencyFormatter}>Unit Cost</TableHeaderColumn>
                    <TableHeaderColumn dataField='quantity' dataFormat={numberFormatter}>Quantity</TableHeaderColumn>
                    <TableHeaderColumn dataField='poItem' dataFormat={(poItem, row) => receivingItemFormatter(poItem, row.poId)}>
                        Receiving</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }
}

ProductsHistoryTable.propTypes = {
    historyRecords: PropTypes.array.isRequired,
    poItems: PropTypes.array.isRequired,
    error: PropTypes.object,
    isLoaded: PropTypes.bool.isRequired,
    pagination: PropTypes.bool
};

export default ProductsHistoryTable