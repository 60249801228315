import React from 'react';
import PropTypes from 'prop-types';


class VendorsDataInjector extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            vendors: [],
            isLoaded: false,
            error: null
        }
    }

    componentDidMount() {
        this._loadVendors();
    }

    _loadVendors(){
        const {client} = this.props;
        client.vendors.getVendors()
            .then(vendors => {
                this.setState({
                    isLoaded: true,
                    vendors: vendors
                });
            }).catch(error => {
            this.setState({
                isLoaded: true,
                error: error
            });
        });
    }

    render() {
        const {vendors, isLoaded, error } = this.state;
        const {po} = this.props;
        const children = React.Children.map(this.props.children, child => {
            return React.cloneElement(child, {
                vendors: vendors,
                isLoaded: isLoaded,
                error: error,
                defaultValue: po.vendor.id
            });
        });
        return (
            <div>
                { children }
            </div>
        );
    }
}

VendorsDataInjector.propTypes = {
    client: PropTypes.object.isRequired,
    po: PropTypes.object
};

export {VendorsDataInjector};