import React from 'react';
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {EditReceivingShipmentsCard} from "./EditReceivingShipmentsCard";
import {EditReceivingItemsCard} from "./EditReceivingItemsCard";
import ReceivingProvider from "../../../context/ReceivingProvider";
import {Alert} from "reactstrap";
import {EditReserveTransactionsCard} from "./EditReserveTransactionsCard";
import {EditReceivingComments} from "./EditReceivingComments";

//TODO Refactor logic and methods out
const DEFAULT_STATE = {
    receivingActions: [],
    voidReceivingActions: [],
    shipmentIdsToCheckin: [],
    enableReserveAlertSound: false
};

class ReceivingEditContent extends React.Component {
    constructor(props){
        super(props);

        this.state = DEFAULT_STATE
    }

    resetState(){
        this.setState(DEFAULT_STATE);
    }

    setCheckinForShipmentId({shipmentId, isCheckedIn}){
        const {shipmentIdsToCheckin} = this.state;

        const indexOfShipmentId = shipmentIdsToCheckin.indexOf(shipmentId);
        if(indexOfShipmentId !== -1 && isCheckedIn || indexOfShipmentId === -1 && !isCheckedIn)
            return; //Nothing to do

        if(indexOfShipmentId === -1 && isCheckedIn)
            this.setState(state => {
                const shipmentIdsToCheckin = state.shipmentIdsToCheckin.concat(shipmentId);
                return {
                    shipmentIdsToCheckin,
                    value: '',
                };
            });
        else
            this.setState(state => {
                const shipmentIdsToCheckin = state.shipmentIdsToCheckin.filter(item => shipmentId !== item);
                return {
                    shipmentIdsToCheckin,
                };
            });
    }

    addVoidReceivingAction({productId, shipmentId}){
        const voidAction = {
            shipmentId: shipmentId,
            productId: productId
        };

        this.setState(state => {
            const voidReceivingActions = state.voidReceivingActions.concat(voidAction);
            return {
                voidReceivingActions,
                value: '',
            };
        });
    }

    setVisualConfirmation({productId, shipmentId=null, isConfirmed}){
        const {receivingActions} = this.state;

        const indexOfAction = receivingActions.findIndex(action => action.productId === productId && action.shipmentId === shipmentId);
        if(indexOfAction !== -1 && isConfirmed || indexOfAction === -1 && !isConfirmed)
            return; //Nothing to do

        if(indexOfAction === -1 && isConfirmed)
            this.setState(state => {
                const action = {
                    shipmentId: shipmentId,
                    productId: productId
                };
                const receivingActions = state.receivingActions.concat(action);
                console.log(receivingActions);
                return {
                    receivingActions,
                    value: '',
                };
            });
        else
            this.setState(state => {
                const receivingActions = state.receivingActions.filter(action => action.productId !== productId || action.shipmentId !== shipmentId);
                return {
                    receivingActions,
                };
            });
    }

    setManualCount({productId, shipmentId, quantity, additionalUnitsExpectedInd}){
        const {receivingActions} = this.state;
        const {po} = this.props;
        const poItem = po.getPoItemForProductId(productId);
        const receivingItem = shipmentId ? poItem._getReceivingItemForShipmentId(shipmentId) : poItem.baseReceivingItem;

        const indexOfAction = receivingActions.findIndex(action => action.productId === productId && action.shipmentId === shipmentId);
        const action = indexOfAction !== -1 ? receivingActions[indexOfAction] : null;

        if(action && action.manualCount && action.manualCount.quantity === quantity)
            return; //Nothing to do

        if(quantity === "" || quantity === undefined)
            quantity = null;
        if(typeof(quantity) === "string")
            quantity = Number(quantity);

        additionalUnitsExpectedInd = !!additionalUnitsExpectedInd; //Force bool
        if(quantity === null)
            additionalUnitsExpectedInd = false; //Not allowed when the receiving item will not have a count

        if(quantity >= receivingItem.expectedQuantity)
            additionalUnitsExpectedInd = false;

        if(!action) //Add
            this.setState(state => {
                const action = {
                    shipmentId: shipmentId,
                    productId: productId,
                    manualCount: {
                        countedQuantity: quantity,
                        additionalUnitsExpectedInd: additionalUnitsExpectedInd
                    }
                };
                const receivingActions = state.receivingActions.concat(action);

                console.log(receivingActions);
                return {
                    receivingActions,
                    value: '',
                };
            });
        else{//Update
            this.setState(state => {
                const receivingActions = state.receivingActions.map((action, index) => {
                    if (index === indexOfAction) { //Modify the item
                        return {
                            shipmentId: shipmentId,
                            productId: productId,
                            manualCount: {
                                countedQuantity: quantity,
                                additionalUnitsExpectedInd: additionalUnitsExpectedInd
                            }
                        };
                    } else {
                        return action;
                    }
                });

                console.log(receivingActions);
                return {
                    receivingActions,
                };
            });
        }
    }

    markEligibleItemsVisuallyConfirmed(){
        const {po} = this.props;
        const {receivingActions, voidReceivingActions} = this.state;

        for (let i = 0; i < po.items.length; i++) {
            const poItem = po.items[i];
            this._markReceivingItemVisuallyConfirmedIfEligible({poItem, shipmentId: null, receivingActions, voidReceivingActions});
            poItem.receivingItems.forEach(receivingItem => this._markReceivingItemVisuallyConfirmedIfEligible({poItem, shipmentId: receivingItem.shipmentId,
                receivingActions, voidReceivingActions}));
        }
    }

    _markReceivingItemVisuallyConfirmedIfEligible({poItem, shipmentId, receivingActions, voidReceivingActions}){
        const voidAction = voidReceivingActions.find(action => action.productId === poItem.product.id && action.shipmentId === shipmentId);
        const receivingItem = shipmentId ? poItem._getReceivingItemForShipmentId(shipmentId) : poItem.baseReceivingItem;
        if(!voidAction && (!receivingItem.isOpen || receivingItem.manualCount))
            return;

        const action = receivingActions.find(action => action.productId === poItem.product.id && action.shipmentId === shipmentId);
        if((!action || voidAction) && !poItem.baseReceivingItem.manualCount)
            this.setVisualConfirmation({productId:  poItem.product.id, shipmentId: shipmentId, isConfirmed: true});
    }

    async saveShipmentsOnly({independent=true}={}){
        const {client, po, setPo} = this.props;
        const {shipmentIdsToCheckin} = this.state;

        if(shipmentIdsToCheckin.length === 0)
            return po;
        return client.standaloneShipments.checkinShipments({poRef: po.ref, shipmentIds: shipmentIdsToCheckin})
            .then(newPo => {
                if(independent) {
                    this.resetState();
                    setPo(newPo);
                }
                return newPo;
            })
            .catch(err => {
                if(independent)
                    alert("Error saving shipments");
                return Promise.reject(err);
            })
    }

    saveReceiving(){
        const {client, po, setPo} = this.props;
        const {receivingActions, voidReceivingActions} = this.state;

        let voidPromiseFunc = (savedPo) => voidReceivingActions.length > 0 ?  client.receiving.applyVoidReceivingActions({poRef: po.ref, actions: voidReceivingActions}) : Promise.resolve(savedPo);

        this.saveShipmentsOnly({independent: false})
            .then(savedPo => {
                return voidPromiseFunc(savedPo);
            })
            .then(savedPo => {
                if(receivingActions.length !== 0)
                    return client.receiving.applyReceivingActions({poRef: po.ref, actions: receivingActions});
                return Promise.resolve(savedPo);
            })
            .then(newPo => {
                this.resetState();
                setPo(newPo);
                this.setState({enableReserveAlertSound: true});
            })
            .catch(err => {
                alert("Error saving receiving");
            })
    }

    _disableReserveAlertSound(){
        this.setState({enableReserveAlertSound: false});
    }

    render() {
        const {po, productsClient, transactionsClient } = this.props;
        const {shipmentIdsToCheckin, receivingActions, voidReceivingActions, enableReserveAlertSound } = this.state;

        if(this.state.redirectUrl)
            return <Redirect to={this.state.redirectUrl}/>;

        return (
            <ReceivingProvider setCheckinForShipmentId={req => this.setCheckinForShipmentId(req)}
                               shipmentIdsToCheckin={shipmentIdsToCheckin}
                               productsClient={productsClient}
                               transactionsClient={transactionsClient}
                               receivingActions={receivingActions}
                               addVoidReceivingAction={req => this.addVoidReceivingAction(req)}
                               setVisualConfirmation={req => this.setVisualConfirmation(req)}
                               voidReceivingActions={voidReceivingActions}
                               setManualCount={req => this.setManualCount(req)}
                               saveReceiving={() => this.saveReceiving()}
                               saveShipmentsOnly={() => this.saveShipmentsOnly()}
                               markEligibleItemsVisuallyConfirmed={() => this.markEligibleItemsVisuallyConfirmed()}>
                <EditReceivingComments comments={po.comments} />
                <EditReceivingShipmentsCard shipments={po.shipments}/>
                <EditReceivingItemsCard po={po}/>
                <EditReserveTransactionsCard po={po} enableReserveAlertSound={enableReserveAlertSound} disableReserveAlertSound={() => this._disableReserveAlertSound()}/>
            </ReceivingProvider>
        )
    }
}

ReceivingEditContent.propTypes = {
    po: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    productsClient: PropTypes.object.isRequired,
    transactionsClient: PropTypes.object.isRequired,
    setPo: PropTypes.func.isRequired
};

export default ReceivingEditContent;