import React from 'react';
import PropTypes from 'prop-types';
import {Badge, Table} from 'reactstrap';
import {ReceivingItemRow} from "../../atoms/ReceivingItemRow";

function getReceivingItem({poItem, shipmentId}) {
    if(!shipmentId)
        return poItem.baseReceivingItem;
    return poItem._getReceivingItemForShipmentId(shipmentId);
}

class ReceivingItemsTable extends React.Component {
      render() {
        const {poItems, title, shipmentId} = this.props;

        if(poItems.length === 0)
            return "";

        return(
          <div>
            <h5 className="text-center">{title}</h5>
            <Table striped className="receiving-table">
                <thead>
                <tr>
                    <th>KID</th>
                    <th>MPN</th>
                    <th width="48%">Product Name</th>
                    <th className="text-center">Quantity</th>
                    <th>Receiving</th>
                </tr>
                </thead>
                <tbody>
                { poItems.map(poItem => <ReceivingItemRow key={poItem.id} receivingItem={getReceivingItem({poItem, shipmentId})} poItem={poItem}/>)}
                </tbody>
            </Table>
        </div>)
    }
}

ReceivingItemsTable.propTypes = {
    poItems: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    shipmentId: PropTypes.string
};

export {ReceivingItemsTable};