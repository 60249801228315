import { config } from '../config/config';
import routes from '../config/routes';
import moment from 'moment';
import rp from 'request-promise';

class UnitPriceHistory {
    constructor({apiKey, sessionKey}) {
        this.apiKey = apiKey;
        this.sessionKey = sessionKey;
    }

    get headers() {
        if(this.apiKey)
            return {"x-api-key": this.apiKey};
        else
            return {"kjd_session": this.sessionKey}
    }

    getProductCostOnDate(productId, date) {
        let formattedDate = new moment(date).format("YYYY-MM-DD");
        let urlWithParams = routes.history.effective.replace(':productId', productId);
        urlWithParams += "?date="+formattedDate;

        let options = {
            method: 'GET',
            uri: config.baseUrl + urlWithParams,
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                if(err.statusCode === 404)
                    return null; //Not found
                else {
                    let message = "";
                    if(err.body)
                        message = err.body.message;
                    return Promise.reject({message: "Unit Price History getProductCostOnDate Error", error: err}); //Rethrow
                }
            })
    }

    getPurchaseHistoryForProductId(productId){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.history.get.replace(":productId", productId),
            headers: this.headers,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }
}

export { UnitPriceHistory };
