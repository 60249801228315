import round from '../domain/round';

export default class Costs{
    constructor({shipping=0, other=0}, {poRef}){
        this.shipping = shipping;
        this.other = other;
        //Can add more categories here in the future if desired. Such as customs, bank fees, COD charges, etc

        this._poRef = poRef;
    }

    get total(){
        return round(this.shipping + this.totalExcludingShipping);
    }

    get totalExcludingShipping(){
        return this.other;
    }

    toJSON(){
        let {["_poRef"]:omit, ...res} = this;
        res.total = this.total;
        return res;
    }

    getForDb(){
        return this.toJSON();
    }
};