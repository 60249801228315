import React from 'react';
import {Link} from 'react-router-dom'
import flatten from "flat";
import {Badge, Tooltip } from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";

function genericFormatter(obj, key, secondaryFormatter=null) {
    try {
        const flat = flatten(obj, {maxDepth: 3});
        let attribute = null;
        if (!flat.hasOwnProperty(key)) {
            const flatJSON = typeof obj.toJSON === "function" ? flatten(obj.toJSON(), {maxDepth: 3}) : {};
            if(!flatJSON.hasOwnProperty(key))
                return "Data Not Found";
            attribute = flatJSON[key]
        }
        if (secondaryFormatter)
            return secondaryFormatter(attribute || flat[key]);
        return attribute || flat[key];
    }
    catch (e) {
        console.log(e);
        return "Error Formatting Data"
    }
}

function poRefFormatter(cell, receivingContext=false) {
    const link = receivingContext ? `/receiving/edit/${cell}` : `/po/view/${cell}`;
    return <Link to={link}>{cell}</Link>
}

function kidFormatter(cell, row) {
    const id = cell.id ? cell.id : cell;
    return <Link to={`/history/${id}`}>K{id}</Link>
}

function dateFormatter(cell, row) {
    const date = new Date(cell);
    if(date.getTime() === 0) return "";
    return new Date(cell).toLocaleDateString();
}

function productNameFormatter(cell, row){
    return <div className="overflow-ellipsis" title={cell.name}>{cell.name}</div>;
}

function currencyFormatter(cell, row) {
    if(isNaN(cell))
        return "$0.00";
    return new Intl.NumberFormat('us-US', { style: 'currency', currency: 'USD' }).format(cell)
}

function preciseCurrencyFormatter(amount, decimalCount=5, decimal = ".", thousands = ",") {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const parsedAmount = Number(amount);
    if(isNaN(parsedAmount)) return "NaN";

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    let value = negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
    value = value.replace(/0+$/, ''); //Remove trailing zeros
    const splitArray = value.split('.');
    if(splitArray.length <= 1)
        value = parsedAmount.toFixed(2);
    if(splitArray[1].length <= 2)
        value = parsedAmount.toFixed(2);
    return "$ " + value;
}

function numberFormatter(cell, row) {
    if(isNaN(cell))
        return 0;
    return new Intl.NumberFormat('us-US', { style: 'decimal'}).format(cell)
}

function percentFormatter(cell, row) {
    return new Intl.NumberFormat('us-US', { style: 'percent'}).format(cell)
}

function checkinFormatter(cell, showDate=false) {
    const shipment = cell.shipment ? cell.shipment : cell;

    if(shipment.checkIn.isCheckedIn) {
        return <div>
            {showDate ? <FontAwesomeIcon className="mr-2" icon={faCheck} color="green" /> : "" }
            <Badge
                color="primary">Yes {showDate ? ` - ${shipment.checkIn.checkInDate.toLocaleDateString()}` : ""}</Badge>
        </div>;
    }
    return <Badge color="secondary">No</Badge>
}

function receivingTotalsFormatter(po) {
    if(po.receivingTotals.fullyReceived) {
        const message = `Yes - ${po.shipments.length} Shipment${po.shipments.length > 1 ? 's' : ""}`;
        return <Badge  title={message} color="primary">{message}</Badge>;
    }
    if(po.receivingTotals.partiallyReceived) {
        const message = `Partial - ${po.receivingTotals.receivedUnits} / ${po.totals.unitCount}`;
        return <Badge color="info" title={message}>{message}</Badge>;
    }
    return <Badge color="secondary">No</Badge>
}

function receivingItemFormatter(poItem, poId) {
    if(typeof poItem === "function")
        poItem = poItem();
    if(!poItem)
        return "";

    const string = `${poItem.receivingTotals.unitsReceived} / ${poItem.quantity}`;
    let color = "secondary";
    if(!poItem.isOpen)
        color = "primary";
    else if(poItem.receivingTotals.unitsReceived > 0)
        color = "info";

    if(poItem.receivingTotals.missingUnits > 0)
        color = "danger";
    else if(poItem.receivingTotals.extraUnits > 0)
        color = "success";

    if(poId)
        return <div><Badge color={color}>{string}</Badge> <div className="float-right"><Link  to={`/receiving/edit/${poId}`}>Receiving</Link></div></div>
    return  <Badge color={color}>{string}</Badge>;
}

function receivingItemBadgeFormatter({receivingItem, prefix=""}) {
    const string = `${receivingItem.detail.unitsReceived} / ${receivingItem.expectedQuantity}`;
    let color = "secondary";
    if(!receivingItem.isOpen)
        color = "primary";
    else if(receivingItem.detail.unitsReceived > 0)
        color = "info";

    if(receivingItem.detail.missingUnits > 0)
        color = "danger";
    else if(receivingItem.detail.extraUnits > 0)
        color = "success";

    return  <Badge color={color}>{prefix}{string}</Badge>;
}



export {genericFormatter, poRefFormatter, dateFormatter,
    productNameFormatter, kidFormatter, currencyFormatter, numberFormatter, percentFormatter, checkinFormatter,
    receivingTotalsFormatter, receivingItemFormatter, receivingItemBadgeFormatter, preciseCurrencyFormatter}