import React from 'react';
import AppContext from './AppContext';
import PropTypes from 'prop-types';

class AppProvider extends React.Component {
    render() {
        return (
            <AppContext.Provider
                value={{...this.props}}
            >
                {this.props.children}
            </AppContext.Provider>
        );
    }
}

AppProvider.propTypes = {
    user: PropTypes.object.isRequired,
    marketplaces: PropTypes.object.isRequired,
    userSettings: PropTypes.object.isRequired,
    updateUserSettings: PropTypes.func.isRequired
};

export default AppProvider;