//Shows Recent POs with Unshipped, Inbound, Unpaid Filters
//Shows Inbound Shipments sorted by Expected Delivery.
//Ability To Search for POs by PO #, or Tracking Number
//Next to search bar a button to create a new PO
import React from 'react';
import PropTypes from 'prop-types';
import {ReceivingCard} from "../../atoms/ReceivingCard";
import {Badge, Button, Col, Row} from "reactstrap";
import {ReceivingItemsTable} from "../../molecules/tables/ReceivingItemsTable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import ReceivingContext from "../../../context/ReceivingContext";

class EditReceivingItemsCard extends React.Component {

    _renderBaseReceivingItemsTable(){
        const {po} = this.props;

        const poItemsWithBaseReceivingItems = po.items.filter(item => item.baseReceivingItem.expectedQuantity !== 0);
        return <ReceivingItemsTable poItems={poItemsWithBaseReceivingItems} title="Not Allocated to Shipment"/>;
    }

    _renderReceivingItemsForShipment(shipmentId){
        const {po} = this.props;
        const shipment = po.getShipment({shipmentId: shipmentId});

        const poItemsReceivingItemsForShipment = po.items.filter(item => item.receivingItems.find(receivingItem => receivingItem.shipmentId === shipmentId));
        return <ReceivingItemsTable poItems={poItemsReceivingItemsForShipment} shipmentId={shipmentId} title={`Allocated to Shipment - ${shipment.trackingNumber}`}/>;
    }

    _renderButtonsRow({markEligibleItemsVisuallyConfirmed, saveReceiving, voidReceivingActions}){
        const {po} = this.props;

        if(po.receivingTotals.openReceivingItems === 0 && voidReceivingActions.length === 0)
            return <h4 className="text-center pt-3"><Badge color="light"><FontAwesomeIcon className="mr-2" icon={faCheck} color="green" /> All Items Checked In</Badge></h4>;

        return <Row className="pt-3">
            <Col className="text-center">
                <Button outline className="mr-2" onClick={e => markEligibleItemsVisuallyConfirmed()}>Mark All Received</Button>
                <Button color="primary" style={{minWidth: '10em'}} onClick={e => saveReceiving()}>Save All</Button>
            </Col>
        </Row>;
    }

    render() {
        const {po} = this.props;
        return <ReceivingContext.Consumer>
            {({markEligibleItemsVisuallyConfirmed, saveReceiving, voidReceivingActions}) => {
                return (
                    <ReceivingCard badgeText="Step 2" headerText="Items">
                        <h5 className="text-center pb-1">Order Number: {po.orderNumber}</h5>
                        {this._renderBaseReceivingItemsTable()}
                        {po.shipments.map(shipment => this._renderReceivingItemsForShipment(shipment.id))}
                        {this._renderButtonsRow({markEligibleItemsVisuallyConfirmed, saveReceiving, voidReceivingActions})}
                    </ReceivingCard>
                    );
            }}
        </ReceivingContext.Consumer>
    }
}

EditReceivingItemsCard.propTypes = {
    po: PropTypes.object.isRequired
};

export {EditReceivingItemsCard};