function parseTrackings(rawText) {
    let text = rawText.replace(/ +/g, ''); //Remove all spaces
    text = text.replace(/%20/gm, ','); //Replace %20 with comma for parsing from URLs
    let trackings = [];
    if(text.includes(',')){
        //Remove all line breaks as commas with be used
        text = text.replace(/(\r\n|\n|\r)/gm,"");
        trackings = text.split(',');
    }
    else{ //Try by new lines
        trackings = text.split(/[\r\n]+/);
    }
    return trackings;
}

module.exports.parseTrackings= parseTrackings;