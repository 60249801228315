import { PoUserRef } from './ref/PoUserRef';
import uuidv4 from 'uuid/v4';

export default class Comment{
    constructor({id, comment, createdDate=new Date(), updatedDate=new Date(), user, showOnReceiving = false}){
        this.id = id || uuidv4(); //Use provided id or generate a new GUID
        this.comment = comment;
        this.createdDate = new Date(createdDate);
        this.updatedDate = new Date(updatedDate);
        this.user = new PoUserRef(user);
        this.showOnReceiving = showOnReceiving;
    }

    equals(source){
        return this.id === source.id
            && this.comment === source.comment
            && this.createdDate.getTime() === source.createdDate.getTime()
            && this.updatedDate.getTime() === source.updatedDate.getTime()
            && this.user.equals(source.user)
            && this.showOnReceiving == source.showOnReceiving
    }
};