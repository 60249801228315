import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {productNameFormatter, kidFormatter, currencyFormatter, numberFormatter, genericFormatter, percentFormatter, receivingItemFormatter, preciseCurrencyFormatter} from "../../../helpers/dataFormatters";
import {PurchaseOrderItem} from "../../../vendor/obj/PurchaseOrderItem";

const options = {
    sizePerPage: 8,  // Showing 8 for the size per page as default
    hideSizePerPage: false,
    sizePerPageList: [ 8, 10, 20 ],
};



class PurchaseOrderItemsTable extends React.Component {

    _validateCellEdit(row, cellName, cellValue){
        const parsedValue = Number(cellValue);
        if(parsedValue < 0 || isNaN(parsedValue)) {
            return false; //TODO Play error sound
        }

        if(cellName === "shippingCostAllocationPercentage" && parsedValue/100 > 1){
            return false; //TODO Play error sound
        }

        return true; //Valid edit
    }

    _handleCellEdit(row, cellName, cellValue) {
        const {po, patchPo} = this.props;
        const item = po.getPoItemForProductId(row.product.id);

        if(!this._validateCellEdit(row, cellName, cellValue))
            return false;

        let newItem =  new PurchaseOrderItem(item, {poRef: po});
        if(cellName === "quantity")
           newItem.quantity = Number(cellValue);
        else if(cellName === "unitPrice")
            newItem.unitPrice = Number(cellValue);
        else if(cellName === "shippingCostAllocationPercentage") {
            let value = Number(cellValue);
            if(value > 1)
                value = value/100;
            newItem.shippingCostAllocationPercentage = value;
        }
        else
            throw new Error(`Editing unknown col ${cellName}`);
        patchPo("setItem", newItem);

        return false; //The table component will try to mutate the value which we don't want. Always fail the edit
    }

    get cellEditOptions() {
        return {
            mode: 'click',
                blurToSave: true,
            beforeSaveCell: (row, cellName, cellValue) => this._handleCellEdit(row, cellName, cellValue),}
    };

    dynamicColumns(po){
        let cols = [];
        if(po.costs.shipping !== 0)
            cols.push(<TableHeaderColumn thStyle={ { whiteSpace: 'normal', fontSize: '75%' } }
                                         dataField='shippingCostAllocationPercentage' headerAlign='center'
                                         dataFormat={percentFormatter}  >Shipping Cost %</TableHeaderColumn>);
        if(po.costs.other !== 0 || po.costs.shipping !== 0){
            cols.push(<TableHeaderColumn dataField='' editable={false} dataFormat={(cell, obj) => genericFormatter(obj, "costs.unit", currencyFormatter)}>Unit Cost</TableHeaderColumn>);
            cols.push(<TableHeaderColumn dataField='' editable={false} dataFormat={(cell, obj) => genericFormatter(obj, "totals.sub", currencyFormatter)}>Sub Total</TableHeaderColumn>);
        }
        return cols;
    }

    receivingCol(po){
        return <TableHeaderColumn dataField='' thStyle={ { whiteSpace: 'normal', fontSize: '75%' } }
                                  editable={false} dataFormat={(cell, obj) => receivingItemFormatter(obj)}>Receiving</TableHeaderColumn>
    }

    render() {
        const { po, edit } = this.props;

        let additionalProps = {};
        if(po.items.length > options.sizePerPage)
            additionalProps.pagination = true;
        if(edit)
            additionalProps.cellEdit = this.cellEditOptions;

        return (
            <BootstrapTable data={po.nonZeroPoItems} striped hover {...additionalProps} options={options} version='4'>
                <TableHeaderColumn isKey dataField='product' dataSort={ true } width={"5%"} dataFormat={kidFormatter}>KID</TableHeaderColumn>
                <TableHeaderColumn dataField='product' editable={false} dataFormat={productNameFormatter} width={"40%"} >Product Name</TableHeaderColumn>
                <TableHeaderColumn dataField='quantity' dataFormat={numberFormatter}>Quantity</TableHeaderColumn>
                <TableHeaderColumn dataField='unitPrice' dataFormat={(cell) => preciseCurrencyFormatter(cell)}>Unit Price</TableHeaderColumn>
                {this.dynamicColumns(po)}
                <TableHeaderColumn dataField='' editable={false} dataFormat={(cell, obj) => genericFormatter(obj, "totals.grand", currencyFormatter)}>Total</TableHeaderColumn>
                {this.receivingCol()}
            </BootstrapTable>
        );
    }
}

PurchaseOrderItemsTable.propTypes = {
    po: PropTypes.object.isRequired,
    edit: PropTypes.bool,
    patchPo: PropTypes.func
};

export {PurchaseOrderItemsTable};