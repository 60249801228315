import React from 'react';
import PropTypes from 'prop-types';
import {Input, Table, Row, Col, Button, InputGroup, InputGroupAddon} from 'reactstrap';
import {PoDetailRow} from '../atoms/PoDetailRow';
import {currencyFormatter, numberFormatter} from "../../helpers/dataFormatters";
import {VendorsDropdown} from "./VendorsDropdown";
import {VendorsDataInjector} from "../organisms/functional/VendorsDataInjector";
import { SingleDatePicker } from 'react-dates';
import moment from 'moment';
import {CurrencyInput} from "../atoms/CurrencyInput";

//TODO Refactor - Probably want a template for the base of the details
class PoDetailsEdit extends React.Component {
    constructor(props){
        super(props);
        this.state ={
            purchaseDateFocused: false,

            shippingCostString: this.props.po.costs.shipping,
            shippingCostInvalid: false,

            otherCostsString: this.props.po.costs.other,
            otherCostsInValid: false,
        };
    }

    _handleVendorChanged(vendor){
        this.props.patchPo(['vendor'], vendor);
    }

    _handleOrderNumberChanged(e){
        this.props.patchPo(['orderNumber'], e.target.value);
    }

    _handlePurchaseDateChanged(date){
        this.props.patchPo(['purchaseDate'], date ? date.toDate() : null);
    }

    //TODO Refactor parsing currency value to common place. Need for AddPoItemBar Unit Price also
    _handleShippingChanged(e){
        const value = e.target.value;
        this.setState({shippingCostString: value});

        const parsed = Number(value);
        if(isNaN(parsed) || parsed < 0) {
            this.setState({shippingCostInvalid: true});
            return;
        }
        this.props.patchPo(['costs', 'shipping'], parsed);
        this.setState({shippingCostInvalid: false});
    }

    //TODO Refactor parsing currency value to common place. Need for AddPoItemBar Unit Price also
    _handleOtherCostsChanged(e){
        const value = e.target.value;
        this.setState({otherCostsString: value});

        const parsed = Number(value);
        if(isNaN(parsed)) {
            this.setState({otherCostsInValid: true});
            return;
        }
        this.props.patchPo(['costs', 'other'], parsed);
        this.setState({otherCostsInValid: false});
    }

    _renderEditShippingCost() {
        const {shippingCostString, shippingCostInvalid} = this.state;

        return (
            <CurrencyInput invalid={shippingCostInvalid}
                           value={shippingCostString}
                           onChange={e => this._handleShippingChanged(e)}
                           addOn={<Button outline color="success"
                                          onClick={e => this.props.patchPo("autoShippingCostDist")}>Auto</Button>}
            />);
    }

    render() {
        const { po, client } = this.props;
        const {purchaseDateFocused, otherCostsString, otherCostsInValid} = this.state;
        return (
            <Table bordered>
                <tbody>
                    <PoDetailRow label="Vendor" data={<VendorsDataInjector client={client} po={po}>
                        <VendorsDropdown vendorChanged={vendor => this._handleVendorChanged(vendor)}/></VendorsDataInjector>}
                    />
                    <PoDetailRow label="Order Number"
                                 data={ <Input onChange={e => this._handleOrderNumberChanged(e)} value={po.orderNumber} />} />
                    <PoDetailRow label="Purchase Date" data={<SingleDatePicker
                        id="purchaseDate"
                        date={moment(po.purchaseDate)}
                        onDateChange={date => this._handlePurchaseDateChanged(date)}
                        focused={purchaseDateFocused}
                        isOutsideRange={() => false}
                        onFocusChange={({ focused }) => this.setState({ purchaseDateFocused: focused })}
                    />} />
                    <PoDetailRow label="Shipping Cost"
                                 data={this._renderEditShippingCost()} />
                    <PoDetailRow label="Other Costs" data={<CurrencyInput invalid={otherCostsInValid} value={otherCostsString} onChange={e => this._handleOtherCostsChanged(e)}/>}  />
                    <PoDetailRow label="Grand Total" className="font-weight-bold" data={currencyFormatter(po.totals.grand)}  />
                    <PoDetailRow label="Total Units" data={numberFormatter(po.totals.unitCount)} />
                </tbody>
            </Table>
        );
    }
}

PoDetailsEdit.propTypes = {
    po: PropTypes.object.isRequired,
    client: PropTypes.object.isRequired,
    patchPo: PropTypes.func.isRequired
};

export {PoDetailsEdit};