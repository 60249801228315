import {config} from  './config/config';
import routes from './config/routes';
import rp from 'request-promise';

class LiteProductsClient {
    constructor ({sessionKey, apiKey}) {
        if(apiKey == null && sessionKey == null)
            throw 'Cannot create product service without API Key or sessionKey provided';
        if(apiKey && sessionKey)
            throw 'Cannot provide session and api keys';

        if(apiKey)
            this.authHeader = {"x-api-key": apiKey};
        else
            this.authHeader = {"kjd_session": sessionKey};
    }

    static getMarketplaces(){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.marketplaces,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options);
    }

    searchForProducts(search){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.products.get.products + `?search=${search}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                if (err.statusCode === 404)
                    return []; //Not found
                return Promise.reject(err);
            });
    }

    searchForProduct(search){
        return this.searchForProducts(search)
            .then(results => {
                if(results.length === 1)
                    return results[0];
                return Promise.reject({statusCode: 404, message: "Multiple products found"});
            })
    }

    getProductById(id){
        let options = {
            method: 'GET',
            uri: config.baseUrl + routes.products.get.products + `/${id}`,
            headers: this.authHeader,
            json: true // Automatically stringifies the body to JSON
        };

        return rp(options)
            .catch(err => {
                return Promise.reject(err);
            });
    }
}

export default LiteProductsClient;