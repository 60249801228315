//Universal search bar at the top

//Basic information about the PO under the search bar (Vendor, Order Number, Number of Items, Number of Units, Number of Boxes.
//Show receiving details (units received, units outstanding, products outstanding, boxes outstanding)

//Show section to mark what packages have been received. This is step 1 and is saved before going to step 2.
//Provide "Select All" to mark all as checked in

//Section for Base Receiving Items: Show each item (including image), name, expected qty, and receiving action
//For Each shipment with receiving items: Show same as above
//If a section has no receiving items do not show it.

//Save at the bottom to apply receiving actions


import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {ReceivingDahsboardContent} from "../organisms/view/ReceivingDahsboardContent";
import {FilterDropdown} from "../molecules/FilterDropdown";
import {NavLink, NavItem} from "reactstrap";
import {config} from "../../vendor/client/config/config"

class ReceivingDashboard extends React.Component {
    render() {
        const {client} = this.props;
        return (
            <div className="ml-3 mr-3">
                <h1>Receiving Dashboard</h1>
                <ToolBar client={client}
                         receivingContext={true}
                         extraComponent={<NavLink
                            className="d-inline"
                            target="_blank"
                            href={`${config.baseUrl}reports/inbound.pdf`}>Inbound Report</NavLink>}/>
                <ReceivingDahsboardContent client={client}/>
            </div>
        )
    }
}

ReceivingDashboard.propTypes = {
    client: PropTypes.object.isRequired
};

export {ReceivingDashboard};