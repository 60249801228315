import { ManualCountAction } from './ManualCountAction';

const VISUALLY_CONFIRMED = "VISUALLY_CONFIRMED";
const MANUAL_COUNT = "MANUAL_COUNT";

class ReceivingAction{
    constructor({productId, shipmentId=null, userId, date, manualCount=null}){
        this.productId = productId;
        this.shipmentId = shipmentId;
        this.userId = userId;
        this.date = new Date(date);

        if(!productId || !userId)
            throw new Error("Invalid action. Missing productId or userId");

        if(manualCount)
            this.manualCount = new ManualCountAction(manualCount);
    }

    get isManualCounted(){
        return this.manualCount != null;
    }

    get isVisuallyConfirmed(){
        return !this.isManualCounted;
    }

    toString(){
        const shipmentString = this.shipmentId ? ` for Shipment Id ${this.shipmentId}` : '';
        if(this.isVisuallyConfirmed)
            return `Visually Confirmed {QUANTITY} units${shipmentString}`;
        return `Manually Counted ${this.manualCount.countedQuantity} out of {QUANTITY} units${shipmentString}`;
    }
}


export { ReceivingAction };