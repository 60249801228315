import React from 'react';
import PropTypes from 'prop-types';
import ToolBar from '../organisms/functional/ToolBar';
import {ProductsSearchContent} from "../organisms/view/ProductsSearchContent";

class ProductHistorySearch extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {client} = this.props;
        return (
            <div className="ml-3 mr-3">
                <h1>Product Purchasing History</h1>
                <ToolBar placeholder="K1, 1036, etc" searchUrlPath='history/search'/>
                <ProductsSearchContent query={this.props.match.params.query} client={client}/>
            </div>
        )
    }
}

ProductHistorySearch.propTypes = {
    client: PropTypes.object.isRequired
};

export {ProductHistorySearch};