import { ShipmentCheckin } from './receiving/ShipmentCheckin';
import Tracking from './Tracking';
import uuidv4 from 'uuid/v4';

export default class Shipment{
    constructor({id, createdDate=new Date(), updatedDate=new Date(),
                trackingNumber, slug, tracking, checkIn={}}){

        this.id = id || uuidv4(); //Use provided id or generate a new GUID

        this.createdDate = new Date(createdDate);
        this.updatedDate = new Date(updatedDate);

        this.slug = slug;
        this.trackingNumber = trackingNumber;
        this.checkIn = new ShipmentCheckin(checkIn);

        if(tracking && tracking._raw)
            this.tracking = new Tracking(tracking);
        else if(tracking)
            this.tracking = tracking;
        else
            this.tracking = null;
    }

    get status(){
        if(!this.tracking) return null;
        return this.tracking.status;
    }

    get slugTrackingString(){
        return `${this.slug}-${this.trackingNumber}`;
    }

    get isCheckedIn(){
        return this.checkIn.checkInUserId != null;
    }

    get estimatedDelivery(){
        if(!this.tracking) return null;
        return this.tracking.estimatedDelivery;
    }

    get location(){
        if(!this.tracking) return null;
        return this.tracking.location;
    }

    toStatusString(){
        if(!this.tracking) return "No tracking information available";
        return this.tracking.toStatusString();
    }

    async createTrackingViaInjectionOfTrackingsService(trackingsService){
        if(!trackingsService || typeof trackingsService.createTracking !== "function")
            throw new Error("Invalid trackingsService injected");

        return trackingsService.createTracking({
            trackingNumber: this.trackingNumber,
            slug: this.slug,
            type: "PO_SHIPMENT"})
            .then(tracking => {
                this.tracking = tracking;
            })
    }

    async setTrackingViaInjectionOfTrackingsService({trackingsService, log}){
        if(!trackingsService || typeof trackingsService.getTracking !== "function")
            throw new Error("Invalid trackingsService injected");

        return trackingsService.getTracking({trackingNumber: this.trackingNumber, slug: this.slug})
            .then(tracking => {
                this.tracking = tracking;
                return this;
            }).catch(err => {
                if(err.code === 404) { //The tracking does not exist in the trackings system. It may be a legacy tracking
                    log.debug(`Tracking Not Found: Tracking ${this.slugTrackingString} not found. It may be from an old PO without tracking migrated`);
                    return this;
                }
                return Promise.reject(err);
            })
    }

    checkin({date=new Date(), userId}){
        this.checkIn.checkInDate = date;
        this.checkIn.checkInUserId = userId;
        return `Shipment ${this.slug.toUpperCase()}-${this.trackingNumber} Checked In by user ${userId}`;
    }

    toJSON(){
        let res = Object.assign({}, this);
        res.tracking = this.tracking instanceof Tracking ? this.tracking.toJSON() : null;
        res.isCheckedIn = this.isCheckedIn;
        res.status = this.status;
        res.estimatedDelivery = this.estimatedDelivery;
        res.location = this.location;
        return res;
    }

    getForDb(){
        let res = this.toJSON();
        delete res.tracking;
        return res;
    }

};