import hash from 'object-hash';

class User{
    constructor({_id, id, jumpCloudId, email, username, groups, activated, passwordExpired, accountLocked, firstname,
                    lastname, humanAccount, jumpCloud, insertedDate, googleId, photo, displayName }={}){

        this.id = _id || id;
        this.jumpCloudId = jumpCloudId;
        this.email = email;
        this.username = username;

        let uppercaseGroupNames = [];
        if(Array.isArray(groups)) uppercaseGroupNames = groups.map(groupName => User.standardizeGroupName(groupName));
        this.groups = uppercaseGroupNames;

        this.activated = activated;
        this.passwordExpired = passwordExpired;
        this.accountLocked = accountLocked;
        this.firstname = firstname;
        this.lastname = lastname;
        this.humanAccount = humanAccount;
        this.jumpCloud = jumpCloud;
        this.insertedDate = insertedDate ? new Date(insertedDate) : null;
        this.googleId = googleId;
        this.photo = photo;
        this.displayName = displayName;
    }

    /**
     * Returns true if the account is active, password is NOT expired, and account is NOT locked
     * @return {*|boolean}
     */
    isActiveAndValid(){
        return this.activated && !this.passwordExpired && !this.accountLocked
    }

    toJSON(){
        let {["jumpCloud"]:omit, ...res} = this;
        return res;
    }

    isMemberOfGroup(groupName){
        return User.groupNameIsMemberOfGroupsArray({groupName: groupName, groupsArray: this.groups});
    }

    getGroupsHash(){
        let sortedGroups = this.groups.sort();
        return hash.sha1(sortedGroups);
    }

    static standardizeGroupName(groupName){
        groupName = groupName.toUpperCase();
        groupName = groupName.replace(/[\s-]/g, '_'); //Replace whitespace and - with underscore
        groupName = groupName.replace(/_+/g,'_'); //Replace consecutive underscore with single
        return groupName;
    }

    static groupNameIsMemberOfGroupsArray({groupName, groupsArray}){
        return groupsArray.indexOf(User.standardizeGroupName(groupName)) > -1;
    }
}

export { User };