import React, {Component} from 'react';
import {Col, Row} from 'reactstrap';
import PropTypes from "prop-types";
import {PoCommentsTable} from "./tables/PoCommentsTable";

class CommentsContent extends Component {
        render() {
            const {comments} = this.props;
            console.log(comments);
            if(!comments || comments.length === 0) return "";

            return (
                <Row className="mt-2 mb-2">
                    <Col>
                        <h4 className="ml-1">Comments <span className="d-inline-block">({comments.length})</span></h4>
                        <PoCommentsTable comments={comments}/>
                    </Col>
                </Row>)
    }
}

CommentsContent.propTypes = {
    comments: PropTypes.array.isRequired,
};

export {CommentsContent};
