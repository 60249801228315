import React, {Component} from 'react';
import { Button, Modal, ModalHeader, ModalFooter, ModalBody} from 'reactstrap';
import PropTypes from "prop-types";
import ProductsTable from "../../molecules/tables/ProductsTable";

class ProductSearchModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            isLoading: true,
            products: [],
            query: props.query
        };

    }

    componentDidMount() {
        this._searchProducts();
    }

    componentDidUpdate(prevProps){
        if(prevProps.query !== this.props.query){
            this.setState({query: this.props.query});
            this._searchProducts();
        }
    }

    _searchProducts(){
        const {query} = this.state;
        if(!query){
            this.setState({isLoading: false, products: [], error: null});
            return;
        }

        this.setState({isLoading: true});
        this.props.productsClient.searchForProducts(query)
            .then(products => {
                this.setState({
                    isLoading: false,
                    products: products
                });
            }).catch(error => {
                this.setState({
                    isLoading: false,
                    error: error
                });
            });
    }

    kidFormatter(cell){
        const id = cell.id ? cell.id : cell;
        return <Button className="p-0" color="link" onClick={e => this.props.onProductSelected(id)}>K{id}</Button>
    }

    render() {
        const {products, isLoading, error} = this.state;
        const {onCancel} = this.props;
        return (
            <div>
                <Modal isOpen={true} toggle={e => onCancel()} className={this.props.className} size="lg">
                    <ModalHeader toggle={e => onCancel()}>Products Search</ModalHeader>
                    <ModalBody>
                        <ProductsTable products={products} isLoaded={!isLoading} error={error} kidFormatter={(cell) => this.kidFormatter(cell)}/>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="secondary" onClick={e => onCancel()}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

ProductSearchModal.propTypes = {
    onProductSelected: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    productsClient: PropTypes.object.isRequired,
    query: PropTypes.string
};

export {ProductSearchModal};
