import BigNumber from 'bignumber.js';

function round(number, decimals=5) {
   const n = new BigNumber(number);
   return n.decimalPlaces(decimals, BigNumber.ROUND_HALF_DOWN).toNumber();
}

function roundGenerator({defaultDecimalPlaces}){
   return (number, decimals=defaultDecimalPlaces) => round(number, decimals);
}

export default round;
export { roundGenerator };