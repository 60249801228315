import React from 'react';
import PropTypes from 'prop-types';
import {BootstrapTable, TableHeaderColumn} from 'react-bootstrap-table';
import {LoadingSpinner} from "../../atoms/LoadingSpinner";
import {kidFormatter} from "../../../helpers/dataFormatters";
import {ErrorAlert} from "../../atoms/ErrorAlert";

const options = {
    defaultSortName: 'kid',  // default sort column name
    defaultSortOrder: 'asc'  // default sort order
};

function sortProductId(a, b, order) {
    a = parseInt(a.substr(1));
    b = parseInt(b.substr(1));

    if (order === 'asc') {
        return a - b;
    } else {
        return b - a;
    }
}

class ProductsTable extends React.Component {
    render() {
        const { error, isLoaded, products } = this.props;
        let formatKid = this.props.kidFormatter ?  this.props.kidFormatter : kidFormatter;

        const mappedProducts = products.map(p => {
            return {kid: p.key.id, name: p.detail.name, shortName: p.detail.shortName}
        });

        let additionalProps = {};
        if(mappedProducts.length > 10)
            additionalProps.pagination = true;

        if (error)
            return <ErrorAlert error={error}/>;
        else if (!isLoaded)
            return <LoadingSpinner/>;
        return (
            <BootstrapTable data={mappedProducts} striped hover {...additionalProps} options={options}  version='4'>
                <TableHeaderColumn isKey={true} dataField='kid' dataSort={ true } width={"10%"} dataFormat={formatKid} sortFunct={sortProductId}>KID</TableHeaderColumn>
                <TableHeaderColumn dataField='shortName' >Short Name</TableHeaderColumn>
                <TableHeaderColumn dataField='name'>Full Name</TableHeaderColumn>
            </BootstrapTable>
        );
    }
}

ProductsTable.propTypes = {
    products: PropTypes.array.isRequired,
    error: PropTypes.object,
    isLoaded: PropTypes.bool.isRequired,
    pagination: PropTypes.bool,
    kidFormatter: PropTypes.func
};

export default ProductsTable