import React from 'react';
import PropTypes from 'prop-types';
import {PoDetails} from "../../molecules/PoDetails";
import { Row, Col } from 'reactstrap';
import {PurchaseOrderItemsTable} from "../../molecules/tables/PurchaseOrderItemsTable";
import {ShipmentsTable} from "../../molecules/tables/ShipmentsTable";
import {PaymentDetails} from "../../molecules/PaymentDetails";
import { Badge } from 'reactstrap';
import {PurchasingControlButtons} from "../../atoms/PurchasingControlButtons";
import {CommentsContent} from "../../molecules/CommentsContent";


class PoViewContent extends React.Component {
    get paidBadge(){
        return (
            <div className="d-inline-block">
                {this.props.po.payment.paidInFull ? <Badge color="success">PAID</Badge> : <Badge color="danger">NOT PAID</Badge> }
            </div>
        )
    }

    render() {
        const {po} = this.props;
        return (
            <div>

                <Row>
                    <Col md={3} className="mb-1">
                        <Row className="ml-1">
                            <h4 className="mlPurchaseOrderItemsTable-1 mr-3">Details</h4>
                            <PurchasingControlButtons poRef={po.ref} primaryPrefix="Edit" primaryLinkTo={`/po/edit/${po.ref}`} />
                        </Row>
                    </Col>
                    <Col>
                        <h4 className="ml-1">Items <span className="d-inline-block">({po.nonZeroPoItems.length})</span></h4>
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <PoDetails po={po}/>
                    </Col>
                    <Col>
                        <PurchaseOrderItemsTable po={po}/>
                    </Col>
                </Row>

                <Row>
                    <Col md={3}>
                        <h4 className="ml-1">Payment Details {this.paidBadge}</h4>
                    </Col>
                    <Col md={2}>
                        <h4 className="ml-1">Shipments <span className="d-inline-block">({po.shipments.length})</span></h4>
                    </Col>
                    <Col>
                        {po.meta.shipToOffice && <Badge color="info">Shipping Directly To Office</Badge>}
                    </Col>
                </Row>
                <Row>
                    <Col md={3}>
                        <PaymentDetails po={po}/>
                    </Col>
                    <Col>
                       <ShipmentsTable shipments={po.shipments} isLoaded={true}/>
                       <CommentsContent comments={po.comments}/>
                    </Col>
                </Row>
            </div>
        )
    }
}

PoViewContent.propTypes = {
    po: PropTypes.object.isRequired,
    savePo: PropTypes.func.isRequired
};

export {PoViewContent};